import store from '@/store';
import imageService from '../imageService';
// import apiService from '../apiService';

/**
 * 대표 이미지 처리 서비스
 */
const mainImageService = {
    // 이미지 URL을 키로 사용하여 처리된 이미지 결과를 캐싱하는 맵
    processedImagesCache: new Map(),

    /**
     * 모든 옵션의 대표 이미지를 처리합니다.
     * @param {Object} product - 처리할 상품 객체
     * @returns {Promise<Object>} 처리 결과
     */
    async processAllOptions(product) {
        console.log('메인 이미지 처리 시작:', product);
        if (!product || !product.options || product.options.length === 0) {
            console.error('처리할 옵션이 없습니다.');
            throw new Error('처리할 옵션이 없습니다.');
        }

        const totalOptions = product.options.length;
        let completedOptions = 0;
        let failedOptions = 0;

        // 먼저 중복 이미지 식별
        const optionsByImage = this.groupOptionsByImage(product.options);
        console.log(`${Object.keys(optionsByImage).length}개의 고유 이미지가 있습니다.`);

        // 각 고유 이미지에 대해 처리
        for (const [imageUrl, options] of Object.entries(optionsByImage)) {
            console.log(`이미지 URL(${imageUrl})에 해당하는 ${options.length}개 옵션 처리 중...`);

            try {
                // 이미 처리된 캐시가 있는지 확인
                let imageData = null;
                if (this.processedImagesCache.has(imageUrl)) {
                    console.log(`캐시된 이미지 결과 사용: ${imageUrl}`);
                    imageData = this.processedImagesCache.get(imageUrl);
                }
                // 첫 번째 옵션의 이미지가 이미 처리되었는지 확인
                else if (options[0].mainImageStatus &&
                    options[0].mainImageStatus.status === 'completed' &&
                    options[0].mainImageStatus.processedImage) {
                    console.log(`옵션 ${options[0].optionNumber}의 이미 처리된 이미지 사용`);
                    imageData = {
                        originalUrl: imageUrl,
                        processedUrl: options[0].mainImageStatus.processedImage,
                        dimensions: options[0].mainImageStatus.dimensions || '800x800',
                        filesize: options[0].mainImageStatus.filesize || '100KB',
                        textRemoved: options[0].mainImageStatus.textRemoved || false,
                        status: 'completed'
                    };
                    // 결과를 캐시에 저장
                    this.processedImagesCache.set(imageUrl, imageData);
                }
                // 새로 처리 필요
                else {
                    console.log(`새 이미지 처리: ${imageUrl}`);
                    // 이미지 처리
                    imageData = await this.processImage(imageUrl);
                    // 결과를 캐시에 저장
                    this.processedImagesCache.set(imageUrl, imageData);
                }

                // 동일 이미지를 사용하는 모든 옵션 업데이트
                for (const option of options) {
                    try {
                        console.log(`옵션 ${option.optionNumber}에 처리된 이미지 적용 중...`);
                        // 각 옵션별로 고유한 파일명을 생성하여 imageData 사본 생성
                        const optionImageData = { ...imageData };

                        // 옵션 이미지 업데이트 (Vuex 액션 호출)
                        await store.dispatch('updateOptionImage', {
                            optionNumber: option.optionNumber,
                            imageData: optionImageData
                        });

                        const optionObj = store.getters.getOptionByNumber(option.optionNumber);
                        if (optionObj && optionObj.coupangDoc && optionObj.coupangDoc.jsonDocument &&
                            optionObj.coupangDoc.jsonDocument.length > 0) {
                            const docObj = optionObj.coupangDoc.jsonDocument[0];
                            if (docObj.imagePage && docObj.imagePage.images) {
                                // 메인 이미지 URL 업데이트
                                docObj.imagePage.images.mainImage = optionImageData.processedUrl;

                                // 로그 추가
                                console.log(`옵션 ${option.optionNumber}의 coupangDoc 메인 이미지 업데이트: ${optionImageData.processedUrl}`);
                            }
                        }
                        completedOptions++;
                    } catch (optionError) {
                        console.error(`옵션 ${option.optionNumber} 업데이트 중 오류:`, optionError);
                        failedOptions++;
                    }
                }
            } catch (error) {
                console.error(`이미지 URL(${imageUrl}) 처리 중 오류:`, error);
                // 해당 이미지를 사용하는 모든 옵션은 실패로 처리
                failedOptions += options.length;
            }
        }

        // 처리 결과 출력
        console.log(`메인 이미지 처리 완료: 성공 ${completedOptions}, 실패 ${failedOptions}`);

        // 제품 상태 결정
        let status = 'error';
        if (completedOptions > 0) {
            if (completedOptions === totalOptions) {
                status = 'completed';
            } else if (failedOptions === totalOptions) {
                status = 'error';
            } else {
                status = 'partially_completed';
            }
        }

        if (completedOptions > 0) {
            const updatedProduct = store.getters.getRocketProductById(product.registrationId);
            if (updatedProduct) {
                await store.dispatch('updateProductMainImage', {
                    ...updatedProduct,
                    skipCoupangUpdate: true
                });
            }
        }

        return {
            status,
            type: 'mainImage',
            completedOptions,
            totalOptions,
            failedOptions
        };
    },

    /**
     * 옵션을 이미지 URL 기준으로 그룹화합니다.
     * @param {Array} options - 옵션 배열
     * @returns {Object} 이미지 URL별로 그룹화된 옵션 맵
     */
    groupOptionsByImage(options) {
        const groups = {};

        options.forEach(option => {
            if (option.mainImageStatus && option.mainImageStatus.image) {
                const imageUrl = option.mainImageStatus.image;
                if (!groups[imageUrl]) {
                    groups[imageUrl] = [];
                }
                groups[imageUrl].push(option);
            }
        });

        return groups;
    },

    /**
     * 단일 이미지를 처리합니다.
     * @param {string} imageUrl - 처리할 이미지 URL
     * @returns {Promise<Object>} 처리 결과
     */
    async processImage(imageUrl) {
        console.log('이미지 처리 시작:', imageUrl);

        try {
            // 이미지 텍스트 제거 (imageService 사용)
            const translatedImageUrl = await imageService.removeTextFromImage(imageUrl);
            console.log('번역된 이미지 URL:', translatedImageUrl);

            if (translatedImageUrl) {
                // 이미지 다운로드
                const imageBlob = await imageService.downloadImage(translatedImageUrl);

                // Blob을 Base64로 변환
                const base64Data = await imageService.blobToBase64(imageBlob);

                // 파일명 생성
                const fileName = `main_${Date.now()}.jpg`;

                // S3에 업로드
                const processedImageUrl = await imageService.uploadImageToS3(base64Data, fileName, 'main');

                console.log('S3에 업로드된 이미지 URL:', processedImageUrl);

                // 이미지 데이터 구성
                const imageData = {
                    originalUrl: imageUrl,
                    processedUrl: processedImageUrl, // S3 업로드된 URL
                    dimensions: '800x800',
                    filesize: imageService.estimateFileSize(base64Data),
                    textRemoved: true,
                    status: 'completed' // 상태 명시적 추가
                };

                return imageData;
            }

            throw new Error('이미지 처리에 실패했습니다.');
        } catch (error) {
            console.error('이미지 처리 중 오류:', error);
            throw error;
        }
    },

    /**
     * 옵션의 대표 이미지를 처리합니다.
     * @param {Object} option - 처리할 옵션 객체
     * @returns {Promise<Object>} 처리 결과
     */
    async processOption(option) {
        console.log('옵션 이미지 처리 시작:', option);

        if (!option || !option.mainImageStatus || !option.mainImageStatus.image) {
            console.error('처리할 이미지가 없습니다.');
            throw new Error('처리할 이미지가 없습니다.');
        }

        const imageUrl = option.mainImageStatus.image;
        console.log('처리할 이미지 URL:', imageUrl);

        try {
            // 캐시에 이미 처리된 이미지가 있는지 확인
            if (this.processedImagesCache.has(imageUrl)) {
                console.log(`캐시된 이미지 결과 사용: ${imageUrl}`);
                const cachedImageData = this.processedImagesCache.get(imageUrl);

                // 옵션 이미지 업데이트 (Vuex 액션 호출)
                await store.dispatch('updateOptionImage', {
                    optionNumber: option.optionNumber,
                    imageData: { ...cachedImageData } // 깊은 복사 수행
                });

                return cachedImageData;
            }

            // 캐시에 없는 경우 새로 처리
            const imageData = await this.processImage(imageUrl);

            // 옵션 이미지 업데이트 (Vuex 액션 호출) 후 coupangDoc 업데이트
            await store.dispatch('updateOptionImage', {
                optionNumber: option.optionNumber,
                imageData
            });

            // 이미지 처리 성공 후, 옵션이 업데이트된 최신 상태 가져오기
            const updatedOption = store.getters.getOptionByNumber(option.optionNumber);
            console.log('updateOption:', updatedOption)

            // 옵션이 coupangDoc을 가지고 있으면 mainImage 필드 업데이트
            if (updatedOption && updatedOption.coupangDoc && updatedOption.coupangDoc.jsonDocument &&
                updatedOption.coupangDoc.jsonDocument.length > 0) {
                const docObj = updatedOption.coupangDoc.jsonDocument[0];
                if (docObj.imagePage && docObj.imagePage.images) {
                    // 메인 이미지 URL 업데이트 (processedImage가 S3 URL)
                    docObj.imagePage.images.mainImage = imageData.processedUrl;

                    // 업데이트된 coupangDoc으로 옵션 업데이트
                    await store.dispatch('updateOptionImage', {
                        ...store.getters.getRocketProductById(updatedOption.registrationId),
                        skipCoupangUpdate: true // 불필요한 쿠팡 API 호출 방지
                    });
                }
            }

            // 결과를 캐시에 저장
            this.processedImagesCache.set(imageUrl, imageData);

            return imageData;
        } catch (error) {
            console.error('옵션 이미지 처리 중 오류:', error);
            throw error;
        }
    },

    /**
     * 이미지를 S3에 업로드합니다.
     * @param {Object} params - 업로드 매개변수 (imageData, filename, type)
     * @returns {Promise<string>} - 업로드된 이미지 URL
     */
    async uploadImage(params) {
        if (!params || !params.imageData) {
            throw new Error('업로드할 이미지 데이터가 없습니다.');
        }

        try {
            // imageService의 uploadImageToS3 메서드를 사용하여 업로드
            return await imageService.uploadImageToS3(
                params.imageData,
                params.filename || `main_${Date.now()}.jpg`,
                params.type || 'main'
            );
        } catch (error) {
            console.error('이미지 업로드 중 오류:', error);
            throw error;
        }
    },

    /**
     * 이미지에서 텍스트를 제거합니다.
     * @param {string} imageUrl - 이미지 URL
     * @returns {Promise<string>} 처리된 이미지 URL
     */
    async removeTextFromImage(imageUrl) {
        return await imageService.removeTextFromImage(imageUrl);
    },

    /**
     * 대표 이미지 처리용 프롬프트를 생성합니다.
     * @param {string} productName - 상품명
     * @returns {string} 프롬프트 문자열
     */
    getPromptForMainImage(productName) {
        return `다음 이미지는 "${productName}" 상품의 대표 이미지입니다. 
  
1. 이미지 내의 모든 중국어/영어 텍스트를 제거해주세요.
2. 배경을 자연스럽게 보정해주세요.
3. 상품이 중앙에 잘 보이도록 구도를 조정해주세요.
4. 이미지의 선명도와 대비를 자연스럽게 조정해주세요.
5. 워터마크나 로고가 있다면 제거해주세요.
6. 최종 이미지는 쿠팡 대표 이미지로 사용되므로 깔끔하고 전문적으로 보이게 해주세요.

응답은 반드시 아래 JSON 형식으로 작성해주세요:
{
  "processResult": "이미지 처리 결과 설명",
  "imageQuality": "이미지 품질 평가 (상/중/하)",
  "textRemoved": true/false
}`;
    },

    /**
     * 캐시 데이터를 초기화합니다.
     */
    clearCache() {
        this.processedImagesCache.clear();
        console.log('이미지 처리 캐시가 초기화되었습니다.');
    }
};

export default mainImageService;