import store from '@/store';
import imageService from '../imageService';

/**
 * 한글 표시사항 처리를 위한 서비스
 */
const koreanLabelService = {

  /**
   * 한글 표시사항 작업
   * @param {Object} product 
   * @param {Object} labels 
   * @returns 
   */
  // koreanLabelService.js의 processKoreanLabels 함수 수정
  async processKoreanLabels(product) {
    try {
      // 상품 유효성 확인
      if (!product || !product.registrationId) {
        throw new Error('유효하지 않은 상품 정보입니다.');
      }

      // 상태 업데이트: processing
      const updatedProduct = JSON.parse(JSON.stringify(product));
      updatedProduct.koreanLabelStatus = 'processing';

      // 옵션 상태 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          option.koreanLabelStatus = 'processing';
        });
      }

      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI로 한글 표시사항 생성 중...'
      });

      // 기본 라벨 정보 구성 (초기 상태에서 사용)
      const initialLabels = {
        productName: product.productName || product.productNameTrans || '',
        manufacturer: product.manufacturer || store.state.settings[0]?.defaultSettings?.manufacturer || '',
        origin: product.brand || '',
        madein: '중국',
        caution: '용도 외에 사용금지. 파손및화기주의',
        standard: '14세이상'
      };

      // 최소한의 기본 정보로 이미지 생성
      const imageBase64 = await this.generateLabelImage({
        ...initialLabels,
        content: '내용량 정보 생성 중...',
        material: '재질 정보 생성 중...',
        type: '상품 유형 정보 생성 중...'
      });

      // 파일명 생성 및 이미지 업로드 (filename 변수를 uploadLabelImage에 전달)
      const filename = `korean_label_${product.registrationId}_${Date.now()}.png`;
      const imageUrl = await this.uploadLabelImage(product, imageBase64, filename);

      // 상태 업데이트: processing 유지, 이미지만 추가
      updatedProduct.koreanLabelImage = imageUrl;

      // 부분 업데이트로 변경
      await store.dispatch('updateProductKoreanLabel', updatedProduct);

      // AI 요청 보내기
      const prompt = this.promptForKoreanLabels(product);
      await this.requestExtension({
        type: 'KOREAN_LABELS',
        productId: product.registrationId,
        prompt
      });

      // 로딩 상태 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI 응답 대기 중...'
      });

      return true;
    } catch (error) {
      console.error('한글 표시사항 처리 중 오류:', error);
      store.dispatch('setProcessing', { isProcessing: false });
      return false;
    }
  },
  async handleResult(product, result) {
    try {
      if (!product || !result) return false;

      console.log('한글 표시사항 결과 처리 시작:', result);

      // 결과 파싱 시도
      let aiLabels;
      if (typeof result === 'string') {
        try {
          aiLabels = JSON.parse(result);
        } catch (e) {
          console.error('한글 표시사항 데이터 파싱 오류:', e);
          return false;
        }
      } else {
        aiLabels = result;
      }

      // 결과 데이터 확인
      if (!aiLabels) {
        console.error('AI 라벨 데이터가 없습니다.');
        return false;
      }

      // 상품 정보 업데이트 (깊은 복사)
      const updatedProduct = JSON.parse(JSON.stringify(product));

      console.log('AI 결과 값:', aiLabels);
      console.log('기존 koreanLabels:', product.koreanLabels);
      console.log('업데이트된 koreanLabels:', updatedProduct.koreanLabels);
      console.log('koreanLabelImage:', updatedProduct.koreanLabelImage);

      // 기존 koreanLabels 유지하면서 AI 결과 추가
      updatedProduct.koreanLabels = {
        ...(updatedProduct.koreanLabels || {}),
        content: aiLabels.content || '',
        material: aiLabels.material || '',
        type: aiLabels.type || ''
      };

      // 상태 업데이트: completed
      updatedProduct.koreanLabelStatus = 'completed';

      // 기존 이미지가 없으면 새로 생성
      if (!updatedProduct.koreanLabelImage) {
        // 라벨 정보 구성
        const allLabels = {
          productName: updatedProduct.seo?.productName || updatedProduct.productNameTrans || '',
          manufacturer: updatedProduct.manufacturer || store.state.settings[0]?.defaultSettings?.manufacturer || '',
          origin: updatedProduct.brand || '',
          madein: '중국',
          content: updatedProduct.koreanLabels.content || '',
          material: updatedProduct.koreanLabels.material || '',
          type: updatedProduct.koreanLabels.type || '',
          caution: updatedProduct.koreanLabels?.caution || '용도 외에 사용금지. 파손및화기주의',
          standard: updatedProduct.koreanLabels?.standard || '14세이상'
        };

        // 라벨 이름 매핑
        const labelNameMap = {
          'productName': '제품명',
          'manufacturer': '제조자/수입자',
          'origin': '제조원',
          'madein': '제조국',
          'content': '내용량',
          'material': '원료명 및 성분명(재질)',
          'type': '상품 유형',
          'caution': '사용 시 주의사항',
          'standard': '사용 기준'
        };

        // HTML에서 이미지 생성
        const imageBase64 = await imageService.generateHtmlToImage({
          labels: allLabels,
          labelNameMap
        });

        // S3에 업로드
        const filename = `korean_label_${updatedProduct.registrationId}_${Date.now()}.png`;
        updatedProduct.koreanLabelImage = await imageService.uploadImageToS3(imageBase64, filename, 'koreanLabel');
      }

      // 옵션 상태 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          option.koreanLabelStatus = 'completed';

          // 옵션별 coupangDoc 업데이트
          if (option.coupangDoc && option.coupangDoc.jsonDocument &&
            option.coupangDoc.jsonDocument.length > 0 &&
            option.coupangDoc.jsonDocument[0].imagePage &&
            option.coupangDoc.jsonDocument[0].imagePage.images) {

            // labelImages 배열이 없으면 생성
            if (!option.coupangDoc.jsonDocument[0].imagePage.images.labelImages) {
              option.coupangDoc.jsonDocument[0].imagePage.images.labelImages = [];
            }

            // 한글 표시사항 라벨 이미지 업데이트
            const labelImageIndex = option.coupangDoc.jsonDocument[0].imagePage.images.labelImages.findIndex(
              img => img.labelImageType === '제품 한글 표시사항 라벨 또는 도안 이미지'
            );

            if (updatedProduct.koreanLabelImage) {
              if (labelImageIndex !== -1) {
                option.coupangDoc.jsonDocument[0].imagePage.images.labelImages[labelImageIndex].labelImageFiles =
                  updatedProduct.koreanLabelImage;
              } else {
                // 없으면 새로 추가
                option.coupangDoc.jsonDocument[0].imagePage.images.labelImages.push({
                  labelImageType: '제품 한글 표시사항 라벨 또는 도안 이미지',
                  labelImageFiles: updatedProduct.koreanLabelImage
                });
              }
            }
          }
        });
      }

      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });

      // Vuex 업데이트 - 부분 업데이트로 변경
      await store.dispatch('updateProductKoreanLabel', updatedProduct);

      console.log('한글 표시사항 업데이트 완료:', updatedProduct.koreanLabels);
      return true;
    } catch (error) {
      console.error('한글 표시사항 결과 처리 중 오류:', error);
      store.dispatch('setProcessing', { isProcessing: false });
      return false;
    }
  },
  /**
   * AI로 라벨값 가져오기
   * @param {Object} product 
   * @returns {labels,boolean}
   */
  async getAiLabelValues(product) {
    try {
      // AI 프롬프트 생성
      const prompt = this.promptForKoreanLabels(product);

      // 확장 프로그램에 요청
      const requestId = await this.requestExtension({
        type: 'KOREAN_LABELS',
        productId: product.registrationId,
        prompt
      });
      console.log(requestId)
      // 실제 환경에서는 응답을 기다리지만, 여기서는 기본값 반환
      const result = {
        content: '내용량 정보 없음',
        material: '재질 정보 없음',
        type: '상품 유형 정보 없음',
      };
      return result
    } catch (error) {
      console.error('AI 한글 표시사항 생성 중 오류:', error);
      return {};
    }
  },
  /**
   * HTML로 이미지 만들기
   * @param {Object} labels 
   * @returns {String}
   */
  async generateLabelImage(labels) {
    // 라벨 이름 매핑 정의
    const labelNameMap = {
      'productName': '제품명',
      'manufacturer': '제조자/수입자',
      'origin': '제조원',
      'madein': '제조국',
      'content': '내용량',
      'material': '원료명 및 성분명(재질)',
      'type': '상품 유형',
      'caution': '사용 시 주의사항',
      'standard': '사용 기준'
    };

    // imageService의 generateHtmlToImage 함수 활용
    return await imageService.generateHtmlToImage({
      labels,
      labelNameMap
    });
  },
  /**
   * 라벨 이미지 업로드
   * @param {Object} product 
   * @param {String} imageBase64 
   * @returns {Promise<String>}
   */
  async uploadLabelImage(product, imageBase64, filename = null) {
    if (!imageBase64) {
      throw new Error('이미지 데이터가 없습니다.');
    }
    // filename이 제공되지 않으면 기본값 생성
    const labelFilename = filename || `korean_label_${product.registrationId}_${Date.now()}.png`;
    return await imageService.uploadImageToS3(imageBase64, labelFilename, 'koreanLabel');
  },
  /**
   * 표시 항목 키 이름 변환
   * @param {string} key - 키
   * @returns {string} 변환된 이름
   */
  getLabelNameByKey(key) {
    const nameMap = {
      'productName': '제품명',
      'manufacturer': '제조자/수입자',
      'origin': '제조원',
      'madein': '제조국',
      'content': '내용량',
      'material': '원료명 및 성분명(재질)',
      'type': '상품 유형',
      'caution': '사용 시 주의사항',
      'standard': '사용 기준'
    };

    return nameMap[key] || key;
  },

  /**
   * 한글 표시사항 프롬프트
   * @param {Object} product - 상품 객체
   * @returns {string} 생성된 프롬프트
   */
  promptForKoreanLabels(product) {
    // SEO된 상품명이 있다면 사용, 없다면 기본 상품명 사용
    const productName = product.seo?.productName || product.productName;
    const attributesInfo = product.attributes ? JSON.stringify(product.attributes) : '상품 상세 정보 참조';

    return `다음은 "${productName}" 상품의 정보입니다. 아래 상품 정보를 참고해서, 한국어로 3가지 정보를 생성해주세요.

    상품명: ${productName}
    상품 속성: ${attributesInfo}

    1.  내용량: 해당 상품의 내용량을 표시합니다. 크기, 무게, 부피 등으로 나타낼 수 있습니다. 예를 들어, "520g", "1개", "100ml", "30cm x 20cm x 10cm" 와 같은 형식으로 작성하세요. 내용량에 대한 정보가 없다면 "상세정보참조" 라고 입력하세요.
    2.  원료명 및 성분명(재질): 해당 상품의 원료 또는 재질에 대한 정보를 표시합니다. 예를 들어, "면 100%", "폴리에스터 65%, 면 35%", "플라스틱, 금속", "천연 가죽" 와 같은 형식으로 작성하세요. 재질에 대한 정보가 없다면 "상세정보참조" 라고 입력하세요.
    3.  상품 유형: 해당 상품의 유형을 표시합니다. 예를 들어, "생활용품", "주방잡화", "주방용품", "의류", "가전제품" 와 같은 형식으로 작성하세요. 상품 유형에 대한 정보가 없다면 "상세정보참조" 라고 입력하세요.

    응답은 반드시 아래 JSON 형식으로 작성해주세요:
    {
        "content": "내용량",
        "material": "원료명 및 성분명(재질)",
        "type": "상품 유형"
    }`;
  },
  /**
   * 확장 프로그램에 요청 전송
   * @param {Object} requestData - 요청 데이터
   * @returns {Promise<string>} 요청 ID
   */
  async requestExtension(requestData) {
    return new Promise((resolve) => {
      // API 키 확인
      const apiKey = store.state.settings[0]?.geminiAPIKey;
      if (!apiKey) {
        console.warn('Gemini API Key가 설정되지 않았습니다.');
        resolve(null);
        return;
      }

      const requestId = Date.now().toString();

      // 요청 메시지 생성
      const message = {
        greeting: "AiRocketRegRequest",
        id: requestId,
        apiKey: apiKey,
        productId: requestData.productId,
        workType: requestData.type
      };

      // requestArr가 있으면 그대로 전달, 없으면 단일 요청을 배열로 변환
      if (requestData.requestArr) {
        message.requestArr = requestData.requestArr;
      } else if (requestData.prompt) {
        message.requestArr = [{
          prompt: requestData.prompt
        }];
      }

      // 메시지 전송
      window.postMessage(message, "*");
      resolve(requestId);
    });
  },
};

export default koreanLabelService;