// src/services/apiService.js

import axios from 'axios';
import store from '@/store';

/**
 * API 통신 관련 공통 유틸리티를 제공하는 서비스
 */
const apiService = {
  /**
   * API 요청을 위한 기본 세팅 가져오기
   * @returns {Object} 헤더 등 기본 세팅
   */
  getRequestConfig() {
    // 사용자 정보 가져오기
    const user = store.state.user;
    
    // 비-ASCII 문자 제거 또는 인코딩하는 함수
    const sanitizeHeaderValue = (value) => {
      if (!value) return '';
      
      // 방법 1: encodeURIComponent 사용 (URL 인코딩)
      return encodeURIComponent(value.toString());
      
      // 방법 2: ASCII가 아닌 문자 제거
      // return value.toString().replace(/[^\x00-\x7F]/g, '');
      
      // 방법 3: Base64 인코딩 (필요시)
      // return btoa(unescape(encodeURIComponent(value.toString())));
    };
    
    // 기본 설정 반환 (헤더 값 정화)
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Company-ID': sanitizeHeaderValue(user.companyid || ''),
        'X-User-ID': sanitizeHeaderValue(user._id || '')
      }
    };
  },

  /**
   * GET 요청 수행
   * @param {string} url - 요청 URL
   * @param {Object} params - 쿼리 파라미터
   * @param {Object} config - 추가 설정
   * @returns {Promise<Object>} - 응답 데이터
   */
  async get(url, params = {}, config = {}) {
    try {
      const requestConfig = {
        ...this.getRequestConfig(),
        ...config,
        params
      };
      
      const response = await axios.get(url, requestConfig);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  },

  /**
   * POST 요청 수행
   * @param {string} url - 요청 URL
   * @param {Object} data - 요청 데이터
   * @param {Object} config - 추가 설정
   * @returns {Promise<Object>} - 응답 데이터
   */
  async post(url, data = {}, config = {}) {
    try {
      const requestConfig = {
        ...this.getRequestConfig(),
        ...config
      };
      
      const response = await axios.post(url, data, requestConfig);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  },

  /**
   * PUT 요청 수행
   * @param {string} url - 요청 URL
   * @param {Object} data - 요청 데이터
   * @param {Object} config - 추가 설정
   * @returns {Promise<Object>} - 응답 데이터
   */
  async put(url, data = {}, config = {}) {
    try {
      const requestConfig = {
        ...this.getRequestConfig(),
        ...config
      };
      
      const response = await axios.put(url, data, requestConfig);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  },

  /**
   * DELETE 요청 수행
   * @param {string} url - 요청 URL
   * @param {Object} config - 추가 설정
   * @returns {Promise<Object>} - 응답 데이터
   */
  async delete(url, config = {}) {
    try {
      const requestConfig = {
        ...this.getRequestConfig(),
        ...config
      };
      
      const response = await axios.delete(url, requestConfig);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  },

  /**
   * 성공 응답 처리
   * @param {Object} response - Axios 응답 객체
   * @returns {Object} - 처리된 응답 데이터
   */
  handleResponse(response) {
    // 특정 형식의 응답인 경우 처리
    if (response.data && (response.data.res === 'success' || response.data.success || response.data.요청결과 === 'success')) {
      return response.data;
    }
    
    // 일반 응답인 경우
    return response.data;
  },

  /**
   * 오류 응답 처리
   * @param {Error} error - 오류 객체
   * @throws {Error} - 처리된 오류
   */
  handleError(error) {
    // 요청 취소된 경우
    if (axios.isCancel(error)) {
      console.log('요청이 취소되었습니다:', error.message);
      throw new Error('요청이 취소되었습니다');
    }

    // 응답이 있는 경우
    if (error.response) {
      const { status, data } = error.response;
      
      // 인증 오류
      if (status === 401) {
        console.error('인증 오류:', data);
        // 로그인 페이지로 리다이렉트 또는 토큰 갱신 처리
        this.handleAuthError();
        throw new Error('인증이 필요합니다');
      }
      
      // 서버 오류
      if (status >= 500) {
        console.error('서버 오류:', data);
        this.showErrorToast('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        throw new Error('서버 오류');
      }
      
      // 기타 오류
      console.error(`API 오류 (${status}):`, data);
      this.showErrorToast(data.message || '요청 처리 중 오류가 발생했습니다.');
      throw new Error(data.message || '요청 처리 중 오류가 발생했습니다');
    }
    
    // 요청 설정 오류
    if (error.request) {
      console.error('요청 오류:', error.request);
      this.showErrorToast('서버에 연결할 수 없습니다. 네트워크 연결을 확인해주세요.');
      throw new Error('서버에 연결할 수 없습니다');
    }
    
    // 기타 오류
    console.error('알 수 없는 오류:', error.message);
    this.showErrorToast('알 수 없는 오류가 발생했습니다.');
    throw error;
  },

  /**
   * 인증 오류 처리
   */
  handleAuthError() {
    // 로그인 페이지로 리다이렉트 또는 토큰 갱신 처리
    // 실제 구현은 프로젝트의 인증 방식에 따라 달라짐
    console.warn('인증 오류 발생, 인증 처리 필요');
  },

  /**
   * 오류 토스트 메시지 표시
   * @param {string} message - 표시할 메시지
   */
  showErrorToast(message) {
    if (window.$bvToast) {
      window.$bvToast.toast(message, {
        title: '오류',
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-bottom-right'
      });
    } else {
      console.error('토스트 메시지:', message);
    }
  },

  /**
   * 1688 상품 정보 가져오기
   * @param {string} productLink - 1688 상품 링크
   * @returns {Promise<Object>} - 상품 정보
   */
  async get1688ProductInfo(productLink) {
    try {
      // 상품 ID 추출
      let productNo = '';
      
      // offer/ 패턴 검사
      if (productLink.includes('offer/')) {
        productNo = productLink.slice(
          productLink.search('offer/') + 6, 
          productLink.search('.html')
        );
      } 
      // 상품 ID가 직접 제공된 경우
      else if (!isNaN(parseInt(productLink))) {
        productNo = productLink;
      }
      // 다른 패턴의 URL
      else {
        const match = productLink.match(/\d+\.html/);
        if (match) {
          productNo = match[0].replace('.html', '');
        }
      }
      
      if (!productNo) {
        throw new Error('상품 ID를 추출할 수 없습니다');
      }
      
      // API 호출
      const response = await this.post('/api/aiPage/getProductInfo', [
        { productId: parseInt(productNo) }
      ]);
      
      if (response && response.result && response.result.length > 0) {
        return response.result[0].result.result;
      }
      
      throw new Error('상품 정보를 가져올 수 없습니다');
    } catch (error) {
      console.error('1688 상품 정보 가져오기 오류:', error);
      throw error;
    }
  },

  /**
   * 로켓배송 상품 목록 조회
   * @param {Object} params - 검색 조건
   * @returns {Promise<Array>} - 상품 목록
   */
  async getRocketProducts(params) {
    try {
      const response = await this.post('/api/aiRocketReg/getlist', params);
      
      if (response && response.products) {
        return response.products;
      }
      
      return [];
    } catch (error) {
      console.error('로켓배송 상품 목록 조회 오류:', error);
      return [];
    }
  },

  /**
   * 로켓배송 상품 저장
   * @param {Object} product - 저장할 상품 정보
   * @returns {Promise<Object>} - 저장 결과
   */
  async saveRocketProduct(product) {
    try {
      const response = await this.post('/api/rocketReg/saveItems', [product]);
      
      if (response.res !== 'success') {
        throw new Error(response.message || '상품 정보 저장 실패');
      }
      
      return response;
    } catch (error) {
      console.error('상품 정보 저장 오류:', error);
      throw error;
    }
  },

  /**
   * 로켓배송 상품 삭제
   * @param {Object} product - 삭제할 상품 정보
   * @returns {Promise<Object>} - 삭제 결과
   */
  async deleteRocketProduct(product) {
    try {
      const response = await this.post('/api/rocketReg/deleteItem', product);
      
      if (response.res !== 'success') {
        throw new Error(response.message || '상품 삭제 실패');
      }
      
      return response;
    } catch (error) {
      console.error('상품 삭제 오류:', error);
      throw error;
    }
  },

  /**
   * 이미지 번역 (텍스트 제거/변환)
   * @param {string} imageUrl - 원본 이미지 URL
   * @returns {Promise<Object>} - 번역 결과
   */
  async translateImage(imageUrl) {
    try {
      const response = await this.post('/api/aiPage/getImageTranslate', [
        { imgUrl: imageUrl }
      ]);
      
      if (response.요청결과 === "성공" && response.result && response.result.length > 0) {
        return response.result[0];
      }
      
      throw new Error('이미지 번역 실패');
    } catch (error) {
      console.error('이미지 번역 오류:', error);
      throw error;
    }
  },

  /**
   * 기본 설정 저장
   * @param {Object} settings - 저장할 설정 정보
   * @returns {Promise<Object>} - 저장 결과
   */
  async saveSettings(settings) {
    try {
      const response = await this.post('/api/savesetting', settings);
      
      if (!response.success) {
        throw new Error('설정 저장 실패');
      }
      
      return response;
    } catch (error) {
      console.error('설정 저장 오류:', error);
      throw error;
    }
  }
};

export default apiService;