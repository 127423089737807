// src/services/imageService.js

import axios from 'axios';
import store from '@/store';
import html2canvas from 'html2canvas';

/**
 * 이미지 처리와 관련된 공통 유틸리티 함수들을 포함하는 서비스
 */
const imageService = {
  /**
   * 이미지 URL에서 Blob 객체로 다운로드
   * @param {string} url - 이미지 URL
   * @returns {Promise<Blob>} - 이미지 Blob 객체
   */
  async downloadImage(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`이미지 다운로드 실패: ${response.status} ${response.statusText}`);
      }
      return await response.blob();
    } catch (error) {
      console.error('이미지 다운로드 중 오류:', error);
      throw error;
    }
  },
  /**
 * HTML 요소를 이미지로 변환
 * @param {Object} options - HTML 생성 옵션
 * @param {Object} options.labels - 라벨 데이터
 * @param {Object} options.labelNameMap - 라벨 이름 매핑 (선택 사항)
 * @returns {Promise<string>} - 생성된 이미지의 Base64 데이터 URL
 */
  async generateHtmlToImage(options) {
    const { labels, labelNameMap = {} } = options;

    // HTML 컨테이너 생성
    const container = document.createElement('div');
    container.style.fontFamily = 'Arial, sans-serif';
    container.style.fontSize = '14px';
    container.style.padding = '20px';
    container.style.border = '1px solid #ccc';
    container.style.width = '500px';
    container.style.background = 'white';

    // 라벨 추가
    for (const key in labels) {
      if (labels[key]) {
        const labelDiv = document.createElement('div');
        labelDiv.style.marginBottom = '10px';

        const labelName = document.createElement('strong');
        const displayName = labelNameMap[key] || key;
        labelName.innerText = displayName + ': ';
        labelDiv.appendChild(labelName);

        const labelValue = document.createElement('span');
        labelValue.innerText = labels[key];
        labelDiv.appendChild(labelValue);

        container.appendChild(labelDiv);
      }
    }

    // body에 추가
    document.body.appendChild(container);

    try {
      // html2canvas로 캡처
      const canvas = await html2canvas(container);
      return canvas.toDataURL('image/png');
    } finally {
      // 항상 DOM에서 제거
      document.body.removeChild(container);
    }
  },

  /**
   * Blob 객체를 Base64 문자열로 변환
   * @param {Blob} blob - 이미지 Blob 객체
   * @returns {Promise<string>} - Base64 인코딩된 이미지 문자열
   */
  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  },

  /**
   * 파일 객체를 Base64 문자열로 변환
   * @param {File} file - 파일 객체
   * @returns {Promise<string>} - Base64 인코딩된 문자열
   */
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  },

  /**
   * Base64 이미지를 S3에 업로드
   * @param {string} imageData - Base64 인코딩된 이미지 데이터
   * @param {string} filename - 저장할 파일 이름
   * @param {string} type - 이미지 타입 (main, additional, detail 등)
   * @returns {Promise<string>} - 업로드된 이미지 URL
   */
  async uploadImageToS3(imageData, filename, type) {
    try {
      // 이미지 업로드 중 상태 표시를 위한 로딩 메시지 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: `${type} 이미지 업로드 중...`
      });

      // 파일명에 항상 확장자가 있는지 확인
      if (!filename.match(/\.(jpg|jpeg|png|gif)$/i)) {
        filename += '.jpg'; // 확장자 추가
      }

      // 공급자 코드 추가 (filename에 이미 포함되어 있지 않다면)
      const supplierCode = store.state.user.suppliercode || store.state.user.companyid;
      if (!filename.startsWith(supplierCode)) {
        filename = `${supplierCode}_${filename}`;
      }

      // API 호출로 S3 업로드
      const response = await axios.post('/api/rocket-registration/upload-image', {
        imageData,
        filename,
        type,
        companyId: store.state.user.companyid
      });

      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });

      if (response.data.요청결과 === "success" && response.data.imageUrl) {
        console.log(`S3 업로드 성공: ${response.data.imageUrl}`);
        return response.data.imageUrl;
      } else {
        console.error('S3 업로드 실패:', response.data.message || '알 수 없는 오류');
        throw new Error(response.data.message || 'S3 업로드 실패');
      }
    } catch (error) {
      console.error('S3 업로드 예외:', error);
      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });
      throw error;
    }
  },

  /**
   * 이미지에서 텍스트 제거 (1688 이미지 번역 API 사용)
   * @param {string} imageUrl - 원본 이미지 URL
   * @returns {Promise<string>} - 텍스트가 제거된 이미지 URL
   */
  async removeTextFromImage(imageUrl) {
    try {
      // API 요청 데이터 준비
      const requestData = [{ imgUrl: imageUrl }];

      // 로딩 상태 표시
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '이미지에서 텍스트 제거 중...'
      });

      // API 호출
      const response = await axios.post('/api/aiPage/getImageTranslate', requestData);
      console.log(response.data.result)

      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });

      console.log("이미지 번역 API 호출 결과:",
        response.data.요청결과 === "성공" ? "성공" : "실패",
        response.data.result ? "결과 있음" : "결과 없음");

      // 응답 처리
      if (response.data.요청결과 === "성공" && response.data.result && response.data.result.length > 0) {
        const result = response.data.result[0];
        if (result.translatedImagePixelData && result.translatedImagePixelData.repairedUrl) {
          return result.translatedImagePixelData.repairedUrl;
        }
      }

      // 이미지 처리 실패 시 원본 URL 반환
      console.warn("이미지 텍스트 제거 결과가 없습니다. 원본 이미지를 사용합니다.");
      return imageUrl;
    } catch (error) {
      console.error('텍스트 제거 오류:', error);
      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });
      // 오류 발생 시 원본 URL 반환
      return imageUrl;
    }
  },

  /**
   * 이미지 크기 가져오기 (width, height)
   * @param {string} imageUrl - 이미지 URL
   * @returns {Promise<{width: number, height: number}>} - 이미지 크기
   */
  getImageDimensions(imageUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight
        });
      };
      img.onerror = () => {
        reject(new Error('이미지 로드 실패'));
      };
      img.src = imageUrl;
    });
  },

  /**
   * 이미지 파일 크기 추정 (Base64 문자열 기반)
   * @param {string} dataUrl - Base64 이미지 URL
   * @returns {string} - 포맷팅된 파일 크기 (KB, MB 등)
   */
  estimateFileSize(dataUrl) {
    if (dataUrl.startsWith('data:')) {
      const base64Length = dataUrl.split(',')[1].length;
      const fileSizeBytes = Math.round((base64Length * 0.75));

      if (fileSizeBytes < 1024) {
        return `${fileSizeBytes} B`;
      } else if (fileSizeBytes < 1024 * 1024) {
        return `${Math.round(fileSizeBytes / 1024)} KB`;
      } else {
        return `${(fileSizeBytes / (1024 * 1024)).toFixed(1)} MB`;
      }
    }
    return '알 수 없음';
  },

  /**
   * 이미지 URL이 유효한지 확인
   * @param {string} url - 확인할 이미지 URL
   * @returns {Promise<boolean>} - 이미지 URL 유효 여부
   */
  async checkImageUrl(url) {
    if (!url) return false;

    try {
      // HEAD 요청으로 이미지가 존재하는지 확인
      const response = await axios.head(url, { timeout: 3000 });
      return response.status === 200;
    } catch (error) {
      console.warn(`이미지 URL 확인 실패: ${url}`, error.message);
      return false;
    }
  },

  /**
   * 이미지 크기 조정
   * @param {string} imageUrl - 원본 이미지 URL
   * @param {number} width - 너비
   * @param {number} height - 높이
   * @returns {Promise<string>} - 크기 조정된 이미지의 Data URL
   */
  async resizeImage(imageUrl, width, height) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const resizedImageUrl = canvas.toDataURL('image/jpeg', 0.9);
        resolve(resizedImageUrl);
      };
      img.onerror = (e) => {
        console.log(e)
        reject(new Error('이미지 리사이즈 실패'));
      };
      img.src = imageUrl;
    });
  },

  /**
   * HTML에서 이미지 URL 추출
   * @param {string} html - HTML 문자열
   * @returns {string[]} - 추출된 이미지 URL 배열
   */
  extractImagesFromHtml(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    return Array.from(imgElements)
      .map(img => img.src)
      .filter(src => src && src.length > 10);
  }
};

export default imageService;