// import apiService from './apiService';
// import imageService from './imageService';
import store from '@/store';

// 작업별 서비스 임포트
import mainImageService from './works/mainImageService';
import additionalImageService from './works/additionalImageService';
import detailImageService from './works/detailImageService';
import koreanLabelService from './works/koreanLabelService';
import pricingService from './works/pricingService';
import seoService from './works/seoService';
import quotationService from './works/quotationService';
import sizeChartService from './works/sizeChartService';

// 확장 프로그램 요청 관리를 위한 맵
const extensionRequests = new Map();

/**
 * 작업 흐름 제어 서비스
 * 로켓배송 상품 등록 작업의 흐름을 관리하고 제어합니다.
 */
const workflowService = {
    /**
     * 선택된 상품들에 대한 작업 처리를 시작합니다.
     * @param {Array} selectedProducts - 처리할 선택된 상품 배열
     * @param {Object} options - 작업 옵션 (선택적)
     * @returns {Promise<Object>} 작업 결과
     */
    async startProcessing(selectedProducts, options = {}) {
        if (!selectedProducts || selectedProducts.length === 0) {
            throw new Error('처리할 상품이 선택되지 않았습니다.');
        }

        // Gemini API 키 확인
        if (!store.state.settings[0]?.geminiAPIKey) {
            throw new Error('Gemini API Key가 설정되지 않았습니다. 설정에서 API Key를 먼저 설정해주세요.');
        }

        // 작업 상태 업데이트
        store.dispatch('setProcessing', { isProcessing: true, message: '작업 초기화 중...' });

        try {
            // 상품별 순차 처리
            for (const product of selectedProducts) {
                await this.processProduct(product, options);
            }

            store.dispatch('setProcessing', { isProcessing: false });
            return { success: true, message: '선택한 상품의 작업이 완료되었습니다.' };
        } catch (error) {
            console.error('작업 처리 중 오류:', error);
            store.dispatch('setProcessing', { isProcessing: false });
            throw error;
        }
    },
    async processProduct(product, tasksToProcess = null) {
        // 작업 목록이 지정되지 않은 경우 모든 작업 실행
        const tasks = tasksToProcess || [
            'seo',
            'pricing',
            'mainImage',
            'additionalImage',
            'detailImage',
            'koreanLabel',
            'sizeChart',  // 사이즈표 추가
            'quotation'
        ];

        // quotation만 따로 실행하는 경우를 구분
        const isQuotationOnly = tasks.length === 1 && tasks[0] === 'quotation';

        // quotation이 포함되어 있지만 단독 실행이 아닌 경우, 마지막으로 이동
        if (!isQuotationOnly && tasks.includes('quotation')) {
            // quotation을 배열에서 제거하고
            const quotationIndex = tasks.indexOf('quotation');
            tasks.splice(quotationIndex, 1);
            // 작업 배열 맨 뒤에 추가
            tasks.push('quotation');
        }

        // 작업 유형과 상태 필드 간의 매핑
        const taskToStatusField = {
            'seo': 'seoStatus',
            'pricing': 'pricingStatus',
            'mainImage': 'mainImageStatus',
            'additionalImage': 'additionalImageStatus',
            'detailImage': 'detailImageStatus',
            'koreanLabel': 'koreanLabelStatus',
            'sizeChart': 'sizeChartStatus',  // 사이즈표 추가
            'quotation': 'quotationStatus'
        };

        try {
            // 선택된 작업들을 순차적으로 처리
            for (const task of tasks) {
                try {
                    const statusField = taskToStatusField[task];
                    if (!statusField) {
                        console.warn(`알 수 없는 작업 유형: ${task}`);
                        continue;
                    }

                    // 최신 상품 정보 가져오기
                    var currentProduct = store.state.rocketProducts.find(p =>
                        p.registrationId === product.registrationId
                    );

                    if (!currentProduct) {
                        console.error('제품을 찾을 수 없습니다:', product.registrationId);
                        continue;
                    }

                    // quotation 작업 시작 전 다른 작업들의 상태 확인 (전체 작업 실행 시에만)
                    if (task === 'quotation' && !isQuotationOnly) {
                        // 다른 필수 작업들의 완료 상태 확인
                        const requiredTasks = ['koreanLabel', 'mainImage', 'detailImage', 'sizeChart'];
                        let allRequired = true;

                        for (const reqTask of requiredTasks) {
                            if (reqTask === 'sizeChart' && !this.isSizeChartRequired(currentProduct)) {
                                continue;
                            }
                            const reqStatus = currentProduct[taskToStatusField[reqTask]];
                            const isCompleted = typeof reqStatus === 'object'
                                ? reqStatus.status === 'completed'
                                : reqStatus === 'completed' || reqStatus === 'disabled';

                            if (!isCompleted) {
                                console.log(`quotation 작업을 위한 필수 작업(${reqTask})이 완료되지 않았습니다. 완료까지 대기합니다.`);
                                allRequired = false;
                                break;
                            }
                        }

                        if (!allRequired) {
                            // 필수 작업이 모두 완료될 때까지 대기 (최대 60초)
                            let waitTime = 0;
                            const checkInterval = 5000; // 5초마다 확인
                            const maxWaitTime = 60000; // 최대 60초 대기

                            store.dispatch('setProcessing', {
                                isProcessing: true,
                                message: '다른 작업들이 완료될 때까지 대기 중...'
                            });

                            while (waitTime < maxWaitTime) {
                                await new Promise(resolve => setTimeout(resolve, checkInterval));
                                waitTime += checkInterval;

                                // 최신 상품 정보 다시 가져오기
                                const updatedProduct = store.state.rocketProducts.find(p =>
                                    p.registrationId === product.registrationId
                                );

                                if (!updatedProduct) break;

                                // 다시 필수 작업들의 완료 상태 확인
                                allRequired = true;
                                for (const reqTask of requiredTasks) {
                                    const reqStatus = updatedProduct[taskToStatusField[reqTask]];
                                    const isCompleted = typeof reqStatus === 'object'
                                        ? reqStatus.status === 'completed'
                                        : reqStatus === 'completed';

                                    if (!isCompleted) {
                                        allRequired = false;
                                        break;
                                    }
                                }

                                if (allRequired) {
                                    console.log('모든 필수 작업이 완료되었습니다. quotation 작업을 시작합니다.');
                                    // 최신 상품 정보로 업데이트
                                    currentProduct = updatedProduct;
                                    break;
                                }
                            }

                            if (!allRequired) {
                                console.warn('대기 시간이 초과되었습니다. 가용한 최신 정보로 quotation 작업을 진행합니다.');
                            }
                        }
                    }

                    // 'processing' 상태로 업데이트
                    await store.dispatch('updateFeatureStatus', {
                        product: currentProduct,
                        feature: task,
                        status: 'processing'
                    });

                    // 작업 진행 표시 (UI 메시지만 표시)
                    store.dispatch('setProcessing', {
                        isProcessing: true,
                        message: `${this.getWorkTypeName(task)} 처리 중...`
                    });

                    // 작업 처리 (각 서비스 내부에서 상태 관리)
                    switch (task) {
                        case 'seo':
                            await seoService.process(currentProduct);
                            break;
                        case 'pricing':
                            await pricingService.process(currentProduct);
                            break;
                        case 'mainImage':
                            await mainImageService.processAllOptions(currentProduct);
                            break;
                        case 'additionalImage':
                            await additionalImageService.process(currentProduct);
                            break;
                        case 'detailImage':
                            await detailImageService.process(currentProduct);
                            break;
                        case 'sizeChart':
                            // 사이즈표가 필요한 경우에만 처리
                            if (this.isSizeChartRequired(currentProduct)) {
                                await sizeChartService.process(currentProduct);
                            } else {
                                console.log('사이즈표가 필요하지 않은 카테고리입니다. 건너뜁니다.');
                                // 상태 업데이트: 필요 없음으로 표시
                                await store.dispatch('updateFeatureStatus', {
                                    product: currentProduct,
                                    feature: task,
                                    status: 'disabled'
                                });
                            }
                            break;
                        case 'koreanLabel':
                            await store.dispatch('requestKoreanLabelData', {
                                product: currentProduct
                            });

                            // koreanLabel 작업 완료 대기 추가
                            if (!isQuotationOnly && tasks.includes('quotation')) {
                                store.dispatch('setProcessing', {
                                    isProcessing: true,
                                    message: '한글 표시사항 처리 완료 대기 중...'
                                });

                                // 최대 30초 대기
                                let waitTime = 0;
                                const checkInterval = 3000; // 3초마다 확인
                                const maxWaitTime = 30000; // 최대 30초 대기

                                while (waitTime < maxWaitTime) {
                                    await new Promise(resolve => setTimeout(resolve, checkInterval));
                                    waitTime += checkInterval;

                                    // 최신 상품 정보 확인
                                    const updatedProduct = store.state.rocketProducts.find(p =>
                                        p.registrationId === product.registrationId
                                    );

                                    if (updatedProduct && updatedProduct.koreanLabelStatus === 'completed') {
                                        console.log('한글 표시사항 처리가 완료되었습니다.');
                                        break;
                                    }
                                }
                            }
                            break;
                        case 'quotation':
                            // 견적서 작업 시작 전 최신 상품 정보 다시 가져오기
                            var latestProduct = store.state.rocketProducts.find(p =>
                                p.registrationId === product.registrationId
                            );

                            await quotationService.process(latestProduct || currentProduct);
                            break;
                        default:
                            throw new Error(`알 수 없는 작업 유형: ${statusField}`);
                    }

                    console.log(`작업 ${statusField} 처리 완료`);

                    // 작업 사이에 지연 추가
                    await new Promise(resolve => setTimeout(resolve, 2000));

                } catch (error) {
                    console.error(`작업 '${task}' 처리 중 오류 발생:`, error);
                    // 오류 후 회복을 위한 지연
                    await new Promise(resolve => setTimeout(resolve, 500));
                }
            }

            // 모든 작업 완료 후 처리 중 상태 해제
            store.dispatch('setProcessing', { isProcessing: false });

            return true;
        } catch (error) {
            console.error('작업 처리 중 일반 오류 발생:', error);
            store.dispatch('setProcessing', { isProcessing: false });
            throw error;
        }
    },
    isSizeChartRequired(product) {
        try {
            if (product.rocketRegInfo && product.rocketRegInfo.schemaString) {
                const schema = JSON.parse(product.rocketRegInfo.schemaString);

                if (schema.properties &&
                    schema.properties.imagePage &&
                    schema.properties.imagePage.properties &&
                    schema.properties.imagePage.properties.images &&
                    schema.properties.imagePage.properties.images.properties &&
                    schema.properties.imagePage.properties.images.required) {

                    return schema.properties.imagePage.properties.images.required.includes('sizeChartImage');
                }
            }
        } catch (error) {
            console.error('스키마 파싱 오류:', error);
        }
        return false;
    },
    // workflowService.js - processWorkType 메서드 수정
    async processWorkType(product, type) {
        // 작업 시작 전 상태를 'processing'으로 업데이트
        await this.updateWorkStatus(product, type, 'processing');

        store.dispatch('setProcessing', {
            isProcessing: true,
            message: `${this.getWorkTypeName(type)} 처리 중...`
        });

        try {
            let result = null;

            // 각 작업 타입에 맞는 서비스 호출
            switch (type) {
                case 'seoStatus':
                    result = await seoService.process(product);
                    break;
                case 'pricingStatus':
                    result = await pricingService.process(product);
                    break;
                case 'mainImageStatus':
                    result = await mainImageService.processAllOptions(product);
                    break;
                case 'additionalImageStatus':
                    result = await additionalImageService.process(product);
                    break;
                case 'detailImageStatus':
                    result = await detailImageService.process(product);
                    break;
                case 'sizeChartStatus':
                    if (this.isSizeChartRequired(product)) {
                        result = await sizeChartService.process(product);
                    } else {
                        // 필요 없음으로 처리
                        await this.updateWorkStatus(product, type, 'disabled');
                    }
                    break;
                case 'koreanLabel':
                    // koreanLabelService.processKoreanLabels(updatedProduct) 대신
                    result = await store.dispatch('requestKoreanLabelData', {
                        product: updatedProduct
                    });
                    break;
                case 'quotationStatus':
                    result = await quotationService.process(product);
                    break;
                default:
                    console.error(`알 수 없는 작업 유형: ${type}`);
                    throw new Error(`알 수 없는 작업 유형: ${type}`);
            }

            // 여기가 수정된 부분: 작업 완료 후 최신 제품 정보를 가져옴
            const updatedProduct = store.state.rocketProducts.find(p => p.registrationId === product.registrationId);

            // 작업 완료 후 상태를 결과에 따라 업데이트 (최신 제품 정보 사용)
            const status = result && result.status ? result.status : 'completed';
            await this.updateWorkStatus(updatedProduct || product, type, status);

            // 작업 완료 상태로 업데이트
            store.dispatch('setProcessing', { isProcessing: false });

            console.log(`작업 ${type} 최종 결과:`, result);
            console.log('업데이트된 제품 상태:', (updatedProduct || product)[type]);

            return result;
        } catch (error) {
            console.error(`${type} 처리 중 오류 발생:`, error);
            // 오류 발생 시 상태를 'error'로 업데이트
            await this.updateWorkStatus(product, type, 'error');
            store.dispatch('setProcessing', { isProcessing: false });
            throw error;
        }
    },
    // workflowService.js - updateWorkStatus 메서드 수정
    async updateWorkStatus(product, type, status) {
        console.log('product update:', product);
        if (!product || !product.registrationId) return;

        console.log(`상품 ${product.registrationId} 상태 업데이트 시작: ${type} => ${status}`);

        // 상품 깊은 복사 (JSON.stringify와 parse 사용)
        const updatedProduct = JSON.parse(JSON.stringify(product));
        // 이전 상태 기록
        const previousStatus = updatedProduct[type];

        // 상태 필드 직접 업데이트
        updatedProduct[type] = status;

        console.log(`상품 상태 변경: ${previousStatus} -> ${status}`);

        // 각 옵션의 해당 상태도 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
            console.log(`${updatedProduct.options.length}개 옵션 상태 업데이트 시작`);

            updatedProduct.options.forEach((option, index) => {
                // 옵션의 상태 필드가 객체인 경우 (mainImageStatus 등)
                if (option[type] && typeof option[type] === 'object') {
                    option[type].status = status;
                    console.log(`옵션 ${index + 1} mainImageStatus.status 업데이트: ${status}`);
                } else {
                    // 옵션의 상태 필드가 문자열인 경우
                    option[type] = status;
                    console.log(`옵션 ${index + 1} ${type} 업데이트: ${status}`);
                }
            });
        }

        // Vuex를 통해 상태 업데이트 - 저장 시작
        console.log('Vuex store에 상태 업데이트 시작');
        try {
            await store.dispatch('updateRocketProduct', updatedProduct);
            console.log('Vuex store 상태 업데이트 완료');
            return updatedProduct;
        } catch (error) {
            console.error('Vuex store 상태 업데이트 실패:', error);
            throw error;
        }
    },

    /**
     * 작업 유형에 따른 이름을 반환합니다.
     * @param {string} type - 작업 유형
     * @returns {string} 작업 유형 이름
     */
    getWorkTypeName(type) {
        const typeNames = {
            'mainImageStatus': '대표 이미지',
            'additionalImageStatus': '추가 이미지',
            'detailImageStatus': '상세 이미지',
            'koreanLabelStatus': '한글 표시사항',
            'pricingStatus': '가격 설정',
            'seoStatus': 'SEO 설정',
            'quotationStatus': '견적서',
            'sizeChartStatus': '사이즈표',
        };

        return typeNames[type] || type;
    },

    /**
     * 상품 상태를 업데이트합니다.
     * @param {Object} product - 업데이트할 상품 객체
     * @returns {Promise<boolean>} 업데이트 성공 여부
     */
    async updateProductState(product) {
        try {
            await store.dispatch('updateRocketProduct', product);
            return true;
        } catch (error) {
            console.error('상품 상태 업데이트 중 오류:', error);
            return false;
        }
    },
    // workflowService.js의 requestDetailHtmlGenerate 메서드
    requestDetailHtmlGenerate(requestData) {
        // API 키 확인
        if (!store.state.settings[0]?.geminiAPIKey) {
            throw new Error('Gemini API Key가 설정되지 않았습니다.');
        }

        const requestId = Date.now().toString();

        // 요청 정보 저장
        extensionRequests.set(requestId, {
            timestamp: Date.now(),
            type: 'DETAIL_HTML_GENERATE',
            status: 'processing',
            progress: 0,
            imageUrl: requestData.imageUrl,
            uniqueId: requestData.additionalOptions?.uniqueId
        });

        // 요청 메시지 생성 - 새로운 greeting 사용
        let message = {
            greeting: "AiRocketRegDetailRequest", // 새로운 메시지 타입
            id: requestId,
            apiKey: store.state.settings[0].geminiAPIKey,
            productId: requestData.productId || (store.state.selectedRocketProduct ? store.state.selectedRocketProduct.registrationId : null),
            workType: "DETAIL_HTML_GENERATE",
            imageUrl: requestData.imageUrl,
            textAreas: requestData.textAreas,
            productName: requestData.productName,
            additionalOptions: requestData.additionalOptions || {}
        };

        // 확장 프로그램에 메시지 전송
        window.postMessage(message, "*");

        // 진행 상태 업데이트
        this.updateProgress({
            text: '상세 페이지 HTML 생성 중...',
            progress: 10
        });

        return requestId;
    },

    /**
     * 확장 프로그램에 요청을 보냅니다.
     * @param {Object} requestData - 요청 데이터
     * @returns {string} 요청 ID
     */
    requestExtension(requestData) {
        // API 키 확인
        if (!store.state.settings[0]?.geminiAPIKey) {
            throw new Error('Gemini API Key가 설정되지 않았습니다.');
        }

        const requestId = Date.now().toString();

        // 요청 정보 저장
        extensionRequests.set(requestId, {
            timestamp: Date.now(),
            type: requestData.type || 'unknown',
            status: 'processing',
            progress: 0
        });

        // 요청 메시지 생성
        let message = {
            greeting: "AiRocketRegRequest",
            id: requestId,
            apiKey: store.state.settings[0].geminiAPIKey,
            productId: requestData.productId || (store.state.selectedRocketProduct ? store.state.selectedRocketProduct.registrationId : null),
            workType: requestData.type || "unknown"
        };

        // requestArr가 있으면 그대로 전달, 없으면 단일 요청을 배열로 변환
        if (requestData.requestArr) {
            message.requestArr = requestData.requestArr;
        } else if (requestData.images || requestData.prompt) {
            message.requestArr = [{
                images: requestData.images || [],
                prompt: requestData.prompt || ""
            }];
        }

        // 확장 프로그램에 메시지 전송
        window.postMessage(message, "*");
        return requestId;
    },
    async handleExtensionResult(data) {
        if (!data || !data.result) return false;

        try {
            // 해당 제품 찾기
            let product = null;
            if (data.productId) {
                const productIndex = store.state.rocketProducts.findIndex(p => p.registrationId === data.productId);
                if (productIndex === -1) {
                    console.warn(`결과를 저장할 제품을 찾을 수 없습니다: ${data.productId}`);
                    return false;
                }
                product = store.state.rocketProducts[productIndex];
            } else {
                product = store.state.selectedRocketProduct;
            }

            if (!product) {
                console.warn('현재 선택된 제품이 없습니다.');
                return false;
            }

            // 작업 유형에 따라 결과 처리
            let success = false;
            let result;  // 여기로 변수 선언을 이동

            switch (data.workType) {
                case 'SEO':
                    // 결과의 형식 확인 (batchResults 배열이 있는 경우)
                    result = data.result;
                    if (result.batchResults && result.batchResults.length > 0) {
                        // batchResults[0].result 에서 결과 가져오기
                        result = result.batchResults[0].result;
                    }

                    success = await seoService.handleResult(product, result);

                    // 결과 처리 후 로딩 상태 해제 및 완료 메시지 설정
                    if (success) {
                        store.dispatch('setProcessing', {
                            isProcessing: false,
                            message: 'SEO 정보가 성공적으로 생성되었습니다.'
                        });

                        // 작업 상태 업데이트
                        store.commit('ADD_COMPLETED_TASK', 'seoStatus');
                        store.commit('SET_CURRENT_TASK', null);
                    } else {
                        store.dispatch('setProcessing', {
                            isProcessing: false,
                            message: 'SEO 정보 생성 중 문제가 발생했습니다.'
                        });
                    }
                    break;
                // KOREAN_LABELS 케이스 수정
                case 'KOREAN_LABELS':
                    console.log('KOREAN_LABELS 결과 수신:', data.result);

                    // 결과가 있는지 확인
                    result = data.result.batchResults ? data.result.batchResults[0].result : data.result;

                    if (result) {
                        // koreanLabelService의 handleResult 메서드 호출
                        success = await koreanLabelService.handleResult(product, result);
                    } else {
                        console.warn('KOREAN_LABELS 결과가 유효하지 않습니다');
                        success = false;
                    }
                    break;
                case 'QUOTATION':
                    console.log('QUOTATION 결과 수신:', data.result);
                    // quotationService 모듈 import 필요
                    return quotationService.handleResult(product, data.result);
                case 'DETAIL_HTML_GENERATE':
                    console.log('상세 페이지 HTML 생성 결과 수신:', data.result);

                    // 결과가 있는지 확인
                    result = data.result;
                    if (result.batchResults && result.batchResults.length > 0) {
                        result = result.batchResults[0].result;
                    }

                    // detailImageService의 결과 처리 메서드 호출
                    if (result && result.htmlContent) {
                        // detailImageService의 처리 메서드 호출
                        // 서비스 직접 import는 순환 참조 가능성이 있으므로 주의
                        const detailImageService = await import('./works/detailImageService').then(module => module.default);
                        success = detailImageService.handleHtmlGenerateResult(result);

                        // 진행 상태 업데이트
                        this.updateProgress({
                            text: '상세 페이지 HTML 생성 완료',
                            progress: 100
                        });
                    } else {
                        console.warn('상세 페이지 HTML 생성 결과가 유효하지 않습니다');
                        success = false;
                    }
                    break;
                case 'SIZE_CHART':
                    console.log('===== 사이즈표 결과 수신 =====');
                    console.log('원본 결과 데이터:', data.result);

                    // 결과가 있는지 확인
                    result = data.result.batchResults ? data.result.batchResults[0].result : data.result;

                    if (result) {
                        console.log('처리할 결과 데이터:', result);
                        console.log('사이즈표 포함 여부:', result.hasSizeChart);
                        if (result.sizeChart) {
                            console.log('사이즈표 데이터:', result.sizeChart);
                        }

                        // sizeChartService의 handleResult 메서드 호출
                        const sizeChartService = await import('./works/sizeChartService').then(module => module.default);
                        success = await sizeChartService.handleResult(product, result);
                        console.log('사이즈표 처리 결과:', success ? '성공' : '실패');

                        // 진행 상태 업데이트
                        this.updateProgress({
                            text: '사이즈표 생성 완료',
                            progress: 100
                        });
                    } else {
                        console.warn('SIZE_CHART 결과가 유효하지 않습니다');
                        console.log('결과 데이터:', result);
                        success = false;
                    }
                    break;

                default:
                    console.log(`처리되지 않은 작업 유형: ${data.workType}`, data.result);
            }

            return success;
        } catch (error) {
            console.error('확장 프로그램 결과 처리 중 오류:', error);

        } finally {
            // 로딩 상태 해제
            store.dispatch('setProcessing', {
                isProcessing: false,
                message: ''  //메시지도 초기화
            });
        }
        // 에러가 발생해도 success를 true로 설정하여 다음단계로 진행되도록 수정
        return true;
    },

    /**
     * 확장 프로그램으로부터 받은 진행 상태를 업데이트합니다.
     * @param {Object} data - 진행 상태 데이터
     */
    updateProgress(data) {
        if (!data || !data.text) return;

        store.dispatch('setProcessing', {
            isProcessing: true,
            message: data.text
        });
    },

    /**
     * 확장 프로그램 요청에 대한 타임아웃을 설정합니다.
     */
    setupRequestTimeoutChecker() {
        setInterval(() => {
            const now = Date.now();

            // 5분(300,000ms) 이상 진행 중인 요청 확인
            extensionRequests.forEach((request, id) => {
                if (request.status === 'processing' && now - request.timestamp > 300000) {
                    console.warn(`요청 ${id} (${request.type}) 시간 초과`);
                    request.status = 'timeout';
                    extensionRequests.set(id, request);

                    // 사용자에게 알림
                    store.dispatch('setProcessing', {
                        isProcessing: false,
                        message: `${request.type} 작업 요청이 시간 초과되었습니다.`
                    });
                }
            });

            // 완료된 요청 정리 (10분 이상 지난 요청)
            extensionRequests.forEach((request, id) => {
                if ((request.status === 'completed' || request.status === 'error' || request.status === 'timeout') &&
                    now - (request.completedAt || request.timestamp) > 600000) {
                    extensionRequests.delete(id);
                }
            });
        }, 60000); // 1분마다 체크
    },

    setupExtensionMessageHandler() {
        const messageHandler = (event) => {
            if (!event.data) return;

            // 확장 프로그램 응답 처리 (AiRocketRegResult 타입)
            if (event.data.type === 'AiRocketRegResult') {
                console.log('AI 결과 수신:', event.data);

                // 해당 요청 ID 찾기
                const requestId = event.data.id;
                if (requestId && extensionRequests.has(requestId)) {
                    const request = extensionRequests.get(requestId);
                    request.status = 'completed';
                    request.completedAt = Date.now();
                    extensionRequests.set(requestId, request);
                }

                // SIZE_CHART 워크타입 추가
                if (event.data.workType === 'SIZE_CHART') {
                    console.log('사이즈표 결과 수신:', event.data);
                    this.handleExtensionResult(event.data);
                    return;
                }

                // 결과 처리
                this.handleExtensionResult(event.data);
                return;
            }

            // 기존 ID 기반 응답 처리
            if (event.data.id && extensionRequests.has(event.data.id)) {
                const request = extensionRequests.get(event.data.id);

                if (event.data.type === 'AiRocketRegResult') {
                    console.log(`받은 응답 (${request.type}):`, event.data);
                    request.status = 'completed';
                    request.completedAt = Date.now();
                    request.result = event.data.result;
                    this.handleExtensionResult(event.data);
                } else if (event.data.type === 'AiRocketRegError') {
                    console.error(`오류 응답 (${request.type}):`, event.data);
                    request.status = 'error';
                    request.error = event.data.message || '알 수 없는 오류';
                } else if (event.data.type === 'AiRocketRegProgress') {
                    request.status = 'processing';
                    request.progress = event.data.progress || 0;
                    request.message = event.data.message || '';
                    this.updateProgress(event.data);
                }

                extensionRequests.set(event.data.id, request);
            }

            // 연관 키워드 수집 결과 처리 추가
            if (event.data.res === 'adkeywords') {
                // 요청 ID 기반 처리를 위해 연관 키워드 수집 요청 찾기
                const keywordRequests = Array.from(extensionRequests.entries())
                    .filter(([req]) => req.type === 'COLLECT_KEYWORDS' && req.status === 'processing');

                if (keywordRequests.length > 0) {
                    // 가장 최근 요청 사용
                    const [latestId, latestRequest] = keywordRequests
                        .sort((a, b) => b[1].timestamp - a[1].timestamp)[0];

                    // 결과 데이터 정리
                    const allKeywords = event.data.relatedkeywords || [];
                    const uniqueKeywords = [...new Set(allKeywords)]
                        .filter(keyword => keyword && keyword.trim().length > 0);

                    // 요청 상태 업데이트
                    latestRequest.status = 'completed';
                    latestRequest.completedAt = Date.now();
                    extensionRequests.set(latestId, latestRequest);

                    // Promise 해결
                    if (latestRequest.resolver) {
                        latestRequest.resolver(uniqueKeywords);
                    }

                    // 진행 상태 업데이트
                    this.updateProgress({
                        text: '연관 키워드 수집 완료',
                        progress: 100
                    });
                }
            }

            // 기존 키워드 분석 상태 처리
            if (event.data.res === 'relkeywordsrate') {
                this.updateProgress({
                    text: '연관 키워드 수집 중',
                    progress: event.data.rate * 100
                });
            }
        };

        window.addEventListener('message', messageHandler);
        return messageHandler;
    },

    // workflowService.js에 메서드 추가
    collectCoupangKeywords(targetKeyword, options = {}) {
        return new Promise((resolve, reject) => {
            try {
                // 요청 ID 생성
                const requestId = Date.now().toString();

                // 연관 키워드 요청 정보 저장
                extensionRequests.set(requestId, {
                    timestamp: Date.now(),
                    type: 'COLLECT_KEYWORDS',
                    status: 'processing',
                    progress: 0,
                    resolver: resolve,
                    rejecter: reject
                });

                // 확장 프로그램에 키워드 수집 요청
                window.postMessage({
                    greeting: "getadkeyword",
                    id: requestId,         // 요청 ID 추가
                    data: [targetKeyword],
                    cnt: options.cycle || 1,
                    relKeywordChecked: options.relatedKeywords !== false,
                    autoCompleteChecked: options.autoComplete !== false
                }, "*");

                // 타임아웃 설정 (30초)
                setTimeout(() => {
                    const request = extensionRequests.get(requestId);
                    if (request && request.status === 'processing') {
                        request.status = 'timeout';
                        extensionRequests.set(requestId, request);

                        // 타임아웃 처리 및 오류 반환
                        if (request.rejecter) {
                            request.rejecter(new Error('키워드 수집 시간 초과'));
                        }
                    }
                }, 30000);
            } catch (error) {
                reject(error);
            }
        });
    },

    /**
     * 서비스를 초기화합니다.
     */
    init() {
        this.setupExtensionMessageHandler();
        this.setupRequestTimeoutChecker();
        console.log('워크플로우 서비스 초기화 완료');
    }
};

// 서비스 초기화
workflowService.init();

export default workflowService;