// src/services/works/additionalImageService.js

import store from '@/store';
import imageService from '../imageService';

/**
 * 추가 이미지 처리 서비스
 */
const additionalImageService = {
  /**
   * 추가 이미지 처리 메인 함수
   * @param {Object} product - 상품 객체
   * @returns {Promise<Object>} 처리 결과
   */
  async process(product) {
    console.log('추가 이미지 처리 시작:', product);

    try {
      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '추가 이미지 처리 중...'
      });

      // 메인 이미지 가져오기
      const mainImages = product.mainImages || [];

      // 첫 번째 옵션의 대표 이미지 가져오기 (이미 처리된 이미지)
      let usedMainImage = null;
      if (product.options && product.options.length > 0 &&
        product.options[0].mainImageStatus &&
        product.options[0].mainImageStatus.image) {
        usedMainImage = product.options[0].mainImageStatus.image;
        console.log('대표 이미지로 사용된 이미지 URL:', usedMainImage);
      }

      // 대표 이미지를 제외한 메인 이미지들 필터링
      let additionalImages = mainImages.filter(img => img !== usedMainImage);
      console.log(`처리할 추가 이미지 개수: ${additionalImages.length}`);

      // 최대 9개까지만 처리 (쿠팡 추가 이미지 제한)
      additionalImages = additionalImages.slice(0, 9);

      // 결과를 저장할 배열
      const processedImages = [];

      // 각 이미지 처리
      for (let i = 0; i < additionalImages.length; i++) {
        const imageUrl = additionalImages[i];

        // 진행 상황 업데이트
        store.dispatch('setProcessing', {
          isProcessing: true,
          message: `추가 이미지 처리 중... (${i + 1}/${additionalImages.length})`
        });

        try {
          // 이미지 텍스트 제거
          const processedImageUrl = await imageService.removeTextFromImage(imageUrl);

          if (processedImageUrl) {
            // 이미지 다운로드
            const imageBlob = await imageService.downloadImage(processedImageUrl);

            // Blob을 Base64로 변환
            const base64Data = await imageService.blobToBase64(imageBlob);

            // 파일명 생성
            const fileName = `${product.registrationId}_additional_${i + 1}_${Date.now()}.jpg`;

            // S3에 업로드
            const uploadedUrl = await imageService.uploadImageToS3(base64Data, fileName, 'additional');

            if (uploadedUrl) {
              // 처리된 이미지 정보 저장
              processedImages.push({
                originalUrl: imageUrl,
                url: uploadedUrl,
                dimensions: '800x800',
                filesize: imageService.estimateFileSize(base64Data),
                textRemoved: true
              });

              console.log(`추가 이미지 ${i + 1} 처리 완료:`, uploadedUrl);
            }
          }
        } catch (error) {
          console.error(`이미지 ${i + 1} 처리 중 오류:`, error);
          // 오류가 발생해도 다음 이미지 처리 계속
        }
      }

      // 처리된 이미지 URL 추출
      const processedImageUrls = processedImages.map(img => img.url).filter(Boolean);

      // 상품 객체 복사
      const updatedProduct = { ...product };
      updatedProduct.additionalImageStatus = 'completed';
      updatedProduct.additionalImages = processedImages;

      // coupangDoc 업데이트 - 단일 상품 또는 옵션이 없는 경우
      if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.jsonDocument &&
        updatedProduct.coupangDoc.jsonDocument.length > 0) {
        const docObj = updatedProduct.coupangDoc.jsonDocument[0];
        if (docObj.imagePage && docObj.imagePage.images) {
          // 쉼표로 구분된 URL 문자열로 변환하여 저장
          docObj.imagePage.images.additionalImage = processedImageUrls.join(',');
        }
      }

      // 옵션이 있는 경우 각 옵션의 coupangDoc 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          if (option.coupangDoc && option.coupangDoc.jsonDocument &&
            option.coupangDoc.jsonDocument.length > 0) {
            const optionDocObj = option.coupangDoc.jsonDocument[0];
            if (optionDocObj.imagePage && optionDocObj.imagePage.images) {
              // 모든 옵션에 동일한 추가 이미지 적용
              optionDocObj.imagePage.images.additionalImage = processedImageUrls.join(',');
            }
          }

          option.additionalImageStatus.status = 'completed';
        });
      }

      await store.dispatch('updateProductAdditionalImage', {
        ...updatedProduct,
        skipCoupangUpdate: true
      });

      // 결과 반환
      return {
        status: processedImages.length > 0 ? 'completed' : 'error',
        type: 'additionalImage',
        additionalImages: processedImages,
        count: processedImages.length
      };
    } catch (error) {
      console.error('추가 이미지 처리 중 오류:', error);
      return {
        status: 'error',
        type: 'additionalImage',
        error: error.message
      };
    } finally {
      // 로딩 상태 해제
      store.dispatch('setProcessing', { isProcessing: false });
    }
  },

  /**
   * 단일 이미지 처리
   * @param {string} imageUrl - 처리할 이미지 URL
   * @param {string} productId - 상품 등록번호
   * @param {number} index - 이미지 인덱스
   * @returns {Promise<Object>} 처리된 이미지 정보
   */
  async processImage(imageUrl, productId, index) {
    try {
      // 이미지 텍스트 제거
      const processedImageUrl = await imageService.removeTextFromImage(imageUrl);

      if (processedImageUrl) {
        // 이미지 다운로드
        const imageBlob = await imageService.downloadImage(processedImageUrl);

        // Blob을 Base64로 변환
        const base64Data = await imageService.blobToBase64(imageBlob);

        // 파일명 생성
        const fileName = `${productId}_additional_${index}_${Date.now()}.jpg`;

        // S3에 업로드
        const uploadedUrl = await imageService.uploadImageToS3(base64Data, fileName, 'additional');

        if (uploadedUrl) {
          // 처리된 이미지 정보 반환
          return {
            originalUrl: imageUrl,
            url: uploadedUrl,
            dimensions: '800x800',
            filesize: imageService.estimateFileSize(base64Data),
            textRemoved: true
          };
        }
      }

      return null;
    } catch (error) {
      console.error('이미지 처리 중 오류:', error);
      return null;
    }
  },

  /**
   * 파일 업로드 처리
   * @param {File[]} files - 업로드할 파일 배열
   * @param {string} productId - 상품 등록번호
   * @returns {Promise<Array>} 처리된 이미지 정보 배열
   */
  async uploadFiles(files, productId) {
    const processedImages = [];

    for (let i = 0; i < files.length; i++) {
      try {
        const file = files[i];

        // 파일을 Base64로 변환
        const base64Data = await imageService.fileToBase64(file);

        // 파일명 생성
        const fileName = `${productId}_additional_upload_${i}_${Date.now()}.jpg`;

        // S3에 업로드
        const uploadedUrl = await imageService.uploadImageToS3(base64Data, fileName, 'additional');

        if (uploadedUrl) {
          // 처리된 이미지 정보 저장
          processedImages.push({
            originalUrl: null,
            url: uploadedUrl,
            dimensions: '800x800',
            filesize: imageService.estimateFileSize(base64Data),
            textRemoved: false
          });
        }
      } catch (error) {
        console.error(`파일 ${i + 1} 업로드 중 오류:`, error);
      }
    }

    return processedImages;
  }
};

export default additionalImageService;