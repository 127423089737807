// src/services/works/quotationService.js

import store from '@/store';
// import workflowService from '../workflowService';

/**
 * 등록 세부정보(견적서) 작업을 위한 서비스
 */
const quotationService = {
  /**
 * 등록 세부정보 처리 메인 함수
 * @param {Object} product - 상품 객체
 * @param {Object} options - 옵션 데이터 (선택적)
 * @returns {Promise<Object>} 처리 결과
 */
  async process(product) {
    try {
      // 상품 유효성 확인
      if (!product || !product.registrationId) {
        throw new Error('유효하지 않은 상품 정보입니다.');
      }

      // rocketRegInfo 확인
      if (!product.rocketRegInfo) {
        throw new Error('상품에 등록 정보(rocketRegInfo)가 없습니다.');
      }

      // 로딩 상태 시작
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '등록 세부정보 생성 중...'
      });

      // 기본 견적서 데이터 생성
      const baseQuotationData = this.createBaseQuotationData(product);

      // 현재 상태 업데이트 (처리 중)
      const updatedProduct = JSON.parse(JSON.stringify(product));
      updatedProduct.quotation = baseQuotationData;
      updatedProduct.quotationStatus = 'processing';
      await store.dispatch('updateRocketProduct', updatedProduct);

      // coupangDoc에서 정보 추출
      let attributeRequest = null;
      attributeRequest = this.prepareAttributeRequest(product);

      // GET_ATTRIBUTE_INFO 메시지를 확장 프로그램에 전송
      window.postMessage({
        greeting: 'GET_ATTRIBUTE_INFO',
        productId: product.registrationId,
        coupangSchema: attributeRequest.schema,
        alibabaProductInfo: attributeRequest.alibaba,
        defaultSettings: store.state.settings[0]?.defaultSettings || {},
        geminiApiKey: store.state.settings[0]?.geminiAPIKey
      }, '*');

      // 진행 상태 메시지 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '확장 프로그램에 등록 세부정보 요청 중...'
      });

      // 메시지 리스너 설정 - 확장 프로그램 응답 처리
      const messageListener = (event) => {
        if (event.data && event.data.type === 'getAttributeInfoResult') {
          // 리스너 제거
          window.removeEventListener('message', messageListener);

          // 결과 처리
          this.handleAttributeResponse(product, event.data, baseQuotationData)
            .catch(err => console.error('등록 세부정보 응답 처리 중 오류:', err));
        }
      };

      // 메시지 리스너 등록
      window.addEventListener('message', messageListener);

      // 타임아웃 설정 (1분)
      setTimeout(() => {
        window.removeEventListener('message', messageListener);
      }, 60000);

      return {
        status: 'processing',
        message: '등록 세부정보를 생성하는 중입니다. 결과가 도착하면 자동으로 적용됩니다.'
      };
    } catch (error) {
      console.error('등록 세부정보 처리 중 오류:', error);

      // 로딩 상태 종료
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: '등록 세부정보 처리 중 오류가 발생했습니다: ' + error.message
      });

      return {
        status: 'error',
        error: error.message || '알 수 없는 오류'
      };
    }
  },

  /**
 * 확장 프로그램에 전송할 속성 요청 데이터 준비
 * @param {Object} coupangDoc - 쿠팡 문서 객체
 * @param {Object} product - 상품 정보
 * @returns {Object} 속성 요청 데이터
 */
  prepareAttributeRequest(product) {
    try {
      // 스키마 정보 추출
      let schema = null;
      if (product.rocketRegInfo && product.rocketRegInfo.schemaString) {
        schema = JSON.parse(product.rocketRegInfo.schemaString);
      } else {
        throw new Error('쿠팡 스키마 정보를 찾을 수 없습니다.');
      }

      // SEO 정보 가져오기
      const seoInfo = product.seo || {};

      // DefaultSettings 정보
      const defaultSettings = store.state.settings[0]?.defaultSettings || {};

      // 선택된 옵션
      const selectedOption = store.state.selectedOption ||
        (product.options && product.options.length > 0 ? product.options[0] : null);

      // 1688 상품 정보 구성
      const alibabaInfo = {
        // 기본 상품 정보
        productName: product.productName,
        productNameTrans: product.productNameTrans,
        subject: product.productName,
        subjectTrans: product.productNameTrans,
        brand: product.brand || defaultSettings.brand || '',

        // SEO 정보
        seo: {
          productName: seoInfo.productName || product.productNameTrans || '',
          metaDescription: seoInfo.metaDescription || '',
          keywords: seoInfo.keywords || [],
          searchTags: seoInfo.keywords ? seoInfo.keywords.join(', ') : ''
        },

        // 상품 속성 정보
        productAttribute: product.attributes || [],

        // 배송 정보 (추정)
        productShippingInfo: product.productShippingInfo || {
          skuShippingInfoList: [
            {
              weightKg: 1, // 기본값 (1kg)
              lengthCm: 30,
              widthCm: 20,
              heightCm: 10
            }
          ]
        },

        // 옵션 정보
        selectedOption: selectedOption ? {
          optionNumber: selectedOption.optionNumber,
          korName: selectedOption.korName,
          option1: selectedOption.option1,
          option2: selectedOption.option2
        } : null,

        // 상품 이미지
        productImage: {
          images: product.mainImages || []
        },

        // 상품 설명
        description: product.description || '',

        // 카테고리 정보
        categoryPath: product.rocketRegInfo.categoryPath || '',
        categoryId: product.rocketRegInfo.categoryId || 0,

        // 설정 정보
        importStatus: product.importStatus || defaultSettings.importStatus || '수입대상아님'
      };

      return {
        schema: schema,
        alibaba: alibabaInfo
      };
    } catch (error) {
      console.error('속성 요청 데이터 준비 중 오류:', error);
      throw error;
    }
  },

  /**
 * 확장 프로그램으로부터 받은 속성 응답 처리
 * @param {Object} product - 상품 객체
 * @param {Object} response - 속성 응답 데이터
 * @param {Object} baseData - 기본 견적서 데이터
 * @returns {Promise<boolean>} 성공 여부
 */
  async handleAttributeResponse(product, response, baseData) {
    try {
      if (response.error) {
        console.error('확장 프로그램 응답 오류:', response.error);
        throw new Error(response.error);
      }

      console.log('확장 프로그램 원본 응답:', response);

      // 응답 데이터 검증 및 정제
      const validatedResponse = this.validateResponseData(response, product.rocketRegInfo);
      console.log('검증된 응답 데이터:', validatedResponse);

      // 깊은 복사 - 현재 제품 상태
      const updatedProduct = JSON.parse(JSON.stringify(product));

      // SEO 관련 정보 (이미 설정된 정보 유지) - 검색 태그 길이 제한 추가
      let searchTags = '';
      if (product.seo && product.seo.keywords) {
        searchTags = product.seo.keywords.join(', ');
      } else {
        searchTags = baseData.searchTags || validatedResponse.searchTags || '';
      }

      // 검색 태그 길이가 150자를 초과하는 경우 처리
      if (searchTags.length > 150) {
        console.log(`검색 태그 길이(${searchTags.length})가 150자를 초과합니다. 태그를 줄입니다.`);

        // 검색 태그를 배열로 분리
        const tagsArray = searchTags.split(',').map(tag => tag.trim());

        // 150자 이하로 줄이기 위해 뒤에서부터 태그 제거
        let trimmedTags = '';
        for (let i = 0; i < tagsArray.length; i++) {
          const potentialTags = tagsArray.slice(0, tagsArray.length - i).join(', ');
          if (potentialTags.length <= 150) {
            trimmedTags = potentialTags;
            console.log(`원래 태그 수: ${tagsArray.length}, 줄인 태그 수: ${tagsArray.length - i}`);
            break;
          }
        }

        searchTags = trimmedTags;
        console.log(`최종 검색 태그 길이: ${searchTags.length}`);
      }

      // 이미 존재하는 정보 유지 (기존 값 우선)
      const completeQuotation = {
        ...baseData,

        // 기존 정보 유지 (기본 필드)
        brand: product.brand || baseData.brand || validatedResponse.brand || '',
        modelNumber: product.modelNumber || baseData.modelNumber || validatedResponse.modelNumber || '',
        manufacturer: product.manufacturer || baseData.manufacturer || validatedResponse.manufacturer || '',
        businessType: product.businessType || baseData.businessType || validatedResponse.businessType || '제조사',
        taxationType: product.taxationType || baseData.taxationType || validatedResponse.taxationType || '과세',
        importType: product.importStatus || baseData.importType || validatedResponse.importType || '수입대상아님',

        // 가격 정보 (이미 설정된 정보 유지)
        purchasePrice: (product.pricing && product.pricing.purchasePrice) || baseData.purchasePrice || validatedResponse.purchasePrice || 0,
        salePrice: (product.pricing && product.pricing.sellingPrice) || baseData.salePrice || validatedResponse.salePrice || 0,
        msrp: (product.pricing && product.pricing.msrp) || baseData.msrp || validatedResponse.msrp || 0,

        // SEO 관련 정보 (이미 설정된 정보 유지)
        searchTags: searchTags,

        // 속성 정보 (검증된 정보 사용)
        exposedAttributes: validatedResponse.exposedAttributes || baseData.exposedAttributes || [],
        unexposedAttributes: validatedResponse.unexposedAttributes || baseData.unexposedAttributes || [],

        // 물류 정보 (검증된 정보 사용)
        skuUnitBoxDimension: validatedResponse.skuUnitBoxDimension || baseData.skuUnitBoxDimension || '300*300*50',
        skuUnitBoxWeight: validatedResponse.skuUnitBoxWeight || baseData.skuUnitBoxWeight || '1000',

        // 상품 고시 정보 (검증된 정보 사용)
        productNotices: this.convertNoticesToProductNotices(validatedResponse.notices) || baseData.productNotices || [],

        // 물류 정보 기본값
        totalSkusInBox: baseData.totalSkusInBox || 24,
        daysToExpiration: baseData.daysToExpiration || 0,
        specialHandlingReason: baseData.specialHandlingReason || '해당사항없음',
        fashionSeason: baseData.fashionSeason || '사계절',

        // 메타 정보
        generatedAt: new Date().toISOString(),
        validUntil: this.getExpiryDate(30)
      };

      // 디버깅
      console.log('완성된 견적서 데이터:', completeQuotation);
      console.log('notices 데이터 구조:', completeQuotation.productNotices);

      // 견적서 정보 업데이트
      updatedProduct.quotation = completeQuotation;
      updatedProduct.quotationStatus = 'completed';

      // 모든 옵션의 coupangDoc 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          option.quotationStatus = 'completed';

          // 각 옵션별 coupangDoc 업데이트
          if (option.coupangDoc) {
            this.updateCoupangDoc(option.coupangDoc, completeQuotation);
          }
        });
      } else if (updatedProduct.coupangDoc) {
        // 상품의 coupangDoc 업데이트
        this.updateCoupangDoc(updatedProduct.coupangDoc, completeQuotation);
      }

      // Vuex 액션 호출
      await store.dispatch('updateRocketProduct', updatedProduct);

      // 로딩 상태 종료
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: '등록 세부정보가 성공적으로 생성되었습니다.'
      });

      return true;
    } catch (error) {
      console.error('등록 세부정보 응답 처리 중 오류:', error);

      // 로딩 상태 종료
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: '등록 세부정보 처리 중 오류가 발생했습니다: ' + error.message
      });

      return false;
    }
  },
  /**
 * workflowService에서 호출하는 견적서 결과 처리 메서드
 * @param {Object} product - 상품 객체
 * @param {Object} result - 견적서 결과 데이터
 * @returns {Promise<boolean>} 성공 여부
 */
  async handleResult(product, result) {
    try {
      if (!product || !result) {
        console.error('견적서 처리 실패: 제품 또는 결과 데이터가 없습니다.');
        return false;
      }

      console.log('견적서 결과 처리 시작:', result);

      // 결과 파싱 시도
      let quotationData;
      if (typeof result === 'string') {
        try {
          quotationData = JSON.parse(result);
        } catch (e) {
          console.error('견적서 데이터 파싱 오류:', e);
          return false;
        }
      } else {
        quotationData = result.quotation || result;
      }

      // 필수 필드 확인
      if (!quotationData) {
        console.error('견적서 데이터가 없습니다.');
        return false;
      }

      // 상품 정보 업데이트 (깊은 복사)
      const updatedProduct = JSON.parse(JSON.stringify(product));

      // 견적서 데이터 설정
      updatedProduct.quotation = quotationData;
      updatedProduct.quotationStatus = 'completed';

      // 옵션 정보 업데이트 (옵션별로 견적서 정보 적용)
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          option.quotationStatus = 'completed';

          // 옵션별 coupangDoc 업데이트 (존재하는 경우)
          if (option.coupangDoc && option.coupangDoc.docId) {
            this.updateCoupangDoc(option.coupangDoc, quotationData);
          }
        });
      }

      // 상품 전체 coupangDoc 업데이트 (존재하는 경우)
      if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.docId) {
        this.updateCoupangDoc(updatedProduct.coupangDoc, quotationData);
      }

      // 서버에 저장 - 여기를 변경 (updateRocketProduct에서 updateProductQuotation으로)
      try {
        await store.dispatch('updateProductQuotation', {
          ...updatedProduct,
          skipCoupangUpdate: true
        });
        console.log('견적서 정보 저장 성공');
        return true;
      } catch (error) {
        console.error('견적서 정보 저장 실패:', error);
        return false;
      }
    } catch (error) {
      console.error('견적서 결과 처리 중 오류:', error);
      return false;
    }
  },
  /**
  * 응답 데이터 검증 및 정제
  * @param {Object} response - 원본 응답 데이터
  * @param {Object} rocketRegInfo - 상품 등록 정보 (스키마 포함)
  * @returns {Object} 검증 및 정제된 데이터
  */
  validateResponseData(response, rocketRegInfo) {
    // 원본 응답 데이터 구조 확인
    const originalData = response.result || response;
    if (!originalData) return {};

    // 깊은 복사로 데이터 복제
    const result = JSON.parse(JSON.stringify(originalData));

    try {
      // 스키마 정보 추출 (있는 경우)
      let schema = null;
      if (rocketRegInfo && rocketRegInfo.schemaString) {
        try {
          schema = JSON.parse(rocketRegInfo.schemaString);
        } catch (e) {
          console.warn('스키마 파싱 오류:', e);
        }
      }

      // 1. 노출 속성 검증
      if (result.exposedAttributes && Array.isArray(result.exposedAttributes)) {
        result.exposedAttributes = result.exposedAttributes.map(attr => {
          // dropdown 옵션 확인
          const dropdownValues = this.getDropdownValues(schema, 'exposedAttributes', attr.attributeName);

          if (dropdownValues && dropdownValues.length > 0 &&
            !dropdownValues.includes(attr.attributeValue)) {
            console.warn(`노출 속성 "${attr.attributeName}"의 값이 dropdown 옵션과 일치하지 않습니다: ${attr.attributeValue}`);
            return {
              attributeName: attr.attributeName,
              attributeValue: "상세설명 참조" // dropdown 불일치 시 기본값
            };
          }

          // 값이 없거나 빈 문자열인 경우
          if (!attr.attributeValue || attr.attributeValue.trim() === "") {
            return {
              attributeName: attr.attributeName,
              attributeValue: "상세설명 참조" // 값 없음 시 기본값
            };
          }

          return attr;
        });
      }

      // 2. 비노출 속성 검증
      if (result.unexposedAttributes && Array.isArray(result.unexposedAttributes)) {
        result.unexposedAttributes = result.unexposedAttributes.map(attr => {
          const dropdownValues = this.getDropdownValues(schema, 'unexposedAttributes', attr.attributeName);

          if (dropdownValues && dropdownValues.length > 0 &&
            !dropdownValues.includes(attr.attributeValue)) {
            console.warn(`비노출 속성 "${attr.attributeName}"의 값이 dropdown 옵션과 일치하지 않습니다: ${attr.attributeValue}`);
            return {
              attributeName: attr.attributeName,
              attributeValue: null // dropdown 불일치 시 null 설정
            };
          }

          // 값이 없거나 빈 문자열인 경우
          if (!attr.attributeValue || attr.attributeValue.trim() === "") {
            return {
              attributeName: attr.attributeName,
              attributeValue: null // 값 없음 시 null 설정
            };
          }

          return attr;
        });
      }

      // 3. 상품 고시 정보 검증
      if (result.notices && Array.isArray(result.notices)) {
        // 기본 고시 정보 설정 (오버라이드용)
        const defaultNotices = {};

        // store에서 defaultSettings 가져오기
        const defaultSettings = store.state.settings[0]?.defaultSettings;

        // defaultSettings가 있으면 기본값 설정
        if (defaultSettings) {
          defaultNotices["제조자(수입자)"] = defaultSettings.manufacturer || '';
          defaultNotices["A/S 책임자와 전화번호"] = defaultSettings.asInfo || '';
          defaultNotices["제조국"] = defaultSettings.importStatus === '수입대상아님' ? '한국' : '중국';
          defaultNotices["품질보증기준"] = "제품 이상 시 공정거래위원회 고시 소비자분쟁해결기준에 의거 보상합니다.";
        }

        result.notices = result.notices.map(notice => {
          // 1. 기본값이 있으면 기본값 우선 적용
          if (defaultNotices[notice.noticeItemName] !== undefined) {
            return {
              noticeItemName: notice.noticeItemName,
              noticeItemValue: defaultNotices[notice.noticeItemName]
            };
          }

          // 2. 드롭다운 검증
          const dropdownValues = this.getDropdownValues(schema, 'notices', notice.noticeItemName);

          if (dropdownValues && dropdownValues.length > 0 &&
            !dropdownValues.includes(notice.noticeItemValue)) {
            console.warn(`고시 정보 "${notice.noticeItemName}"의 값이 dropdown 옵션과 일치하지 않습니다: ${notice.noticeItemValue}`);
            return {
              noticeItemName: notice.noticeItemName,
              noticeItemValue: "상세설명 참조" // dropdown 불일치 시 기본값
            };
          }

          // 3. 값이 없거나 빈 문자열인 경우
          if (!notice.noticeItemValue || notice.noticeItemValue.trim() === "") {
            return {
              noticeItemName: notice.noticeItemName,
              noticeItemValue: "상세설명 참조" // 값 없음 시 기본값
            };
          }

          return notice;
        });

        // 4. 중복 검사 및 고유한 값만 유지
        const uniqueNotices = [];
        const noticeNames = new Set();

        result.notices.forEach(notice => {
          if (!noticeNames.has(notice.noticeItemName)) {
            noticeNames.add(notice.noticeItemName);
            uniqueNotices.push(notice);
          }
        });

        result.notices = uniqueNotices;
      }

      // 4. 물류 정보 검증
      if (!result.skuUnitBoxDimension || !this.isValidBoxDimension(result.skuUnitBoxDimension)) {
        result.skuUnitBoxDimension = "300*300*50"; // 기본값
      }

      if (!result.skuUnitBoxWeight || !this.isValidBoxWeight(result.skuUnitBoxWeight)) {
        result.skuUnitBoxWeight = "1000"; // 기본값
      }

    } catch (error) {
      console.error('응답 데이터 검증 중 오류:', error);
    }

    return result;
  },

  /**
  * 스키마에서 dropdown 값 가져오기
  * @param {Object} schema - 쿠팡 스키마
  * @param {string} section - 섹션 이름 (exposedAttributes, unexposedAttributes, notices)
  * @param {string} itemName - 속성/항목 이름
  * @returns {Array|null} dropdown 값 배열 또는 null
  */
  getDropdownValues(schema, section, itemName) {
    if (!schema) return null;

    try {
      let sectionSchema = null;
      let valueProperty = 'attributeValue';
      let nameProperty = 'attributeName';

      // 섹션별 스키마 및 속성명 결정
      if (section === 'exposedAttributes') {
        sectionSchema = schema.properties.productPage.properties.commonAttributes.properties.exposedAttributes;
      } else if (section === 'unexposedAttributes') {
        sectionSchema = schema.properties.productPage.properties.unexposedAttributes;
      } else if (section === 'notices') {
        sectionSchema = schema.properties.legalPage.properties.notices;
        valueProperty = 'noticeItemValue';
        nameProperty = 'noticeItemName';
      }

      if (!sectionSchema || !sectionSchema.allOf) return null;

      // 해당 항목의 설정 찾기
      const itemConfig = sectionSchema.allOf.find(config =>
        config.contains.properties[nameProperty] &&
        config.contains.properties[nameProperty].enum &&
        config.contains.properties[nameProperty].enum[0] === itemName
      );

      if (!itemConfig) return null;

      // dropdown 값 반환
      return itemConfig.contains.properties[valueProperty].dropdown || null;

    } catch (error) {
      console.warn(`dropdown 값 조회 중 오류 (${section}, ${itemName}):`, error);
      return null;
    }
  },

  /**
  * notices를 productNotices 형식으로 변환
  * @param {Array} notices - notices 배열 (noticeItemName/noticeItemValue 형식)
  * @returns {Array} productNotices 배열 (name/value 형식)
  */
  convertNoticesToProductNotices(notices) {
    if (!notices || !Array.isArray(notices)) return [];

    return notices.map(notice => ({
      name: notice.noticeItemName,
      value: notice.noticeItemValue || "상세설명 참조"
    }));
  },

  /**
  * 상자 크기 형식 검증
  * @param {string} dimension - 상자 크기 문자열
  * @returns {boolean} 유효성 여부
  */
  isValidBoxDimension(dimension) {
    return typeof dimension === 'string' && /^\d+\*\d+\*\d+$/.test(dimension);
  },

  /**
  * 상자 무게 형식 검증
  * @param {string} weight - 상자 무게 문자열
  * @returns {boolean} 유효성 여부
  */
  isValidBoxWeight(weight) {
    return typeof weight === 'string' && /^\d+$/.test(weight);
  },

  /**
   * coupangDoc 업데이트
   * @param {Object} coupangDoc - 쿠팡 문서 객체
   * @param {Object} quotation - 견적서 데이터
   */
  updateCoupangDoc(coupangDoc, quotation) {
    if (!coupangDoc || !coupangDoc.jsonDocument || !coupangDoc.jsonDocument[0]) {
      console.warn('유효한 coupangDoc이 없습니다.');
      return;
    }

    try {
      const doc = coupangDoc.jsonDocument[0];

      // 1. productPage 업데이트
      if (!doc.productPage) doc.productPage = {};
      const productPage = doc.productPage;

      // 모델 번호
      productPage.modelNumber = quotation.modelNumber || '';

      // 브랜드
      productPage.brand = quotation.brand || '';

      // commonAttributes 초기화
      if (!productPage.commonAttributes) productPage.commonAttributes = {};

      // 가격 정보 업데이트
      productPage.commonAttributes.purchasePrice = Number(Math.round(quotation.purchasePrice));
      productPage.commonAttributes.coupangSalePrice = Number(Math.round(quotation.salePrice));
      productPage.commonAttributes.msrp = Number(Math.round(quotation.msrp));

      // 바코드 정보
      productPage.commonAttributes.productBarcode = quotation.barcode || '바코드 없음(쿠팡 바코드 생성 요청)';

      // 노출 속성
      productPage.commonAttributes.exposedAttributes = quotation.exposedAttributes;

      // 비노출 속성
      productPage.unexposedAttributes = quotation.unexposedAttributes;

      // 제조사 정보
      productPage.manufacturer = quotation.manufacturer || '';

      // 거래 타입
      productPage.businessType = quotation.businessType || '기타 도소매업자';

      // 과세 여부
      productPage.taxationSchema = quotation.taxationType || '과세';

      // 수입 여부
      productPage.importType = quotation.importType || '수입대상아님';

      // 검색 태그 - 150자 제한 처리 추가
      let searchTags = quotation.searchTags || '';

      // 태그 길이 제한 처리
      if (searchTags.length > 150) {
        console.log(`검색 태그 길이(${searchTags.length})가 150자를 초과합니다. 태그를 줄입니다.`);

        // 검색 태그를 배열로 분리 (쉼표로 구분)
        const tagsArray = searchTags.split(',').map(tag => tag.trim());

        // 150자 이하로 줄이기 위해 뒤에서부터 태그 제거
        let trimmedTags = '';
        for (let i = 0; i < tagsArray.length; i++) {
          const potentialTags = tagsArray.slice(0, tagsArray.length - i).join(', ');
          if (potentialTags.length <= 150) {
            trimmedTags = potentialTags;
            console.log(`원래 태그 수: ${tagsArray.length}, 줄인 태그 수: ${tagsArray.length - i}`);
            break;
          }
        }

        searchTags = trimmedTags;
        console.log(`최종 검색 태그 길이: ${searchTags.length}`);
      }

      // 검색 태그
      productPage.searchTags = searchTags || '';

      // 2. legalPage 업데이트
      if (!doc.legalPage) doc.legalPage = {};
      const legalPage = doc.legalPage;

      // KC 마크 타입
      legalPage.kcMarkType = quotation.kcMarkType || '해당사항없음';

      // 인증 정보
      if (quotation.certificates && quotation.certificates.length > 0) {
        legalPage.certificates = quotation.certificates.map(cert => ({
          certificateName: cert.name,
          certificateValue: cert.value
        }));
      }

      // 고시 정보
      if (quotation.productNotices && quotation.productNotices.length > 0) {
        // legalPage 확인 및 생성
        if (!doc.legalPage) doc.legalPage = {};

        // notices 배열 확인 및 생성
        if (!doc.legalPage.notices) doc.legalPage.notices = [];

        // 기존 배열 비우기
        doc.legalPage.notices = [];

        // 새 notices 데이터 추가
        quotation.productNotices.forEach(notice => {
          // 형식 확인하여 변환
          const newNotice = {
            noticeItemName: notice.name,
            noticeItemValue: notice.value
          };

          // 디버깅 로그 추가
          console.log(`coupangDoc에 notice 추가: ${newNotice.noticeItemName} = ${newNotice.noticeItemValue}`);

          doc.legalPage.notices.push(newNotice);
        });
      }

      // 상품 고시명
      legalPage.productNoticeName = quotation.productNoticeName || '기타 재화';

      // 3. logisticsPage 업데이트
      if (!doc.logisticsPage) doc.logisticsPage = {};
      const logisticsPage = doc.logisticsPage;

      // 물류 정보
      logisticsPage.totalSKUsInBox = quotation.totalSkusInBox || 1;
      logisticsPage.daysToExpiration = quotation.daysToExpiration || 0;
      logisticsPage.specialHandlingReason = quotation.specialHandlingReason || '해당사항없음';
      logisticsPage.skuUnitBoxWeight = quotation.skuUnitBoxWeight || '1000';
      logisticsPage.skuUnitBoxDimension = quotation.skuUnitBoxDimension || '300*300*50';
      logisticsPage.fashionYear = quotation.fashionYear || new Date().getFullYear().toString();
      logisticsPage.fashionSeason = quotation.fashionSeason || '사계절';

      if (doc.imagePage && doc.imagePage.images) {
        // product 객체를 직접 찾아서 사용
        const targetProduct = store.state.selectedRocketProduct ||
                             store.state.rocketProducts.find(p => 
                                p.options?.some(o => o.coupangDoc?.docId === coupangDoc.docId));
        
        // 사이즈표 이미지가 있는 경우 반영
        if (targetProduct && targetProduct.sizeChartImage) {
          doc.imagePage.images.sizeChartImage = targetProduct.sizeChartImage;
        }
      }

    } catch (error) {
      console.error('coupangDoc 업데이트 중 오류:', error);
    }
  },

  /**
   * 기본 견적서 데이터 생성
   * @param {Object} product - 상품 정보
   * @returns {Object} 기본 견적서 데이터
   */
  createBaseQuotationData(product) {
    // rocketRegInfo에서 데이터 추출
    const regInfo = product.rocketRegInfo || {};

    // 카테고리 경로 처리 (ROOT> 접두사 제거)
    let categoryPath = regInfo.categoryPath || '';
    if (categoryPath.startsWith('ROOT>')) {
      categoryPath = categoryPath.substring(5);
    }

    // 카테고리 ID와 코드 추출
    let categoryId = 0;
    let displayCategoryCode = 0;

    if (regInfo.categoryId) {
      categoryId = regInfo.categoryId;
    }

    if (regInfo.displayCategoryCode) {
      displayCategoryCode = regInfo.displayCategoryCode;
    } else {
      // 카테고리 경로에서 추출 시도
      const categoryPathMatch = categoryPath.match(/\((\d+)\)$/);
      if (categoryPathMatch && categoryPathMatch[1]) {
        displayCategoryCode = parseInt(categoryPathMatch[1]);
        // 카테고리 ID 추정 (실제로는 정확한 매핑이 필요)
        categoryId = displayCategoryCode - 74000;
      }
    }

    // 선택된 옵션 정보 추출
    const selectedOption = store.state.selectedOption ||
      (product.options && product.options.length > 0 ? product.options[0] : null);

    // SEO된 상품명 또는 기본 상품명 사용
    let productName = product.seo?.productName || product.productName || '';

    // 옵션이 있는 경우 옵션명 추가
    if (selectedOption && selectedOption.korName) {
      productName = `${productName}, ${selectedOption.korName}`;
    }

    // DefaultSettings 정보 가져오기
    const defaultSettings = store.state.settings[0]?.defaultSettings || {};

    // 기본 견적서 데이터 구성
    return {
      // 상품 기본 정보
      productName: productName,
      brand: product.brand || '',
      modelNumber: productName,
      manufacturer: defaultSettings.manufacturer || product.brand || '',
      businessType: defaultSettings.transactionType || '기타 도소매업자',
      taxationType: '과세',
      importType: defaultSettings.importStatus || '수입대상아님',

      // 카테고리 정보
      categoryPath: categoryPath,
      categoryId: categoryId,
      displayCategoryCode: displayCategoryCode,

      // 가격 정보
      barcode: '바코드 없음(쿠팡 바코드 생성 요청)',
      purchasePrice: this.getDefaultPurchasePrice(product),
      salePrice: this.getDefaultSalePrice(product),
      msrp: this.getDefaultMSRP(product),

      // 인증 정보
      kcMarkType: '해당사항없음',
      certificates: this.getDefaultCertificates(),

      // 물류 정보 기본값
      totalSkusInBox: defaultSettings.totalSKUsInBox || 24,
      daysToExpiration: defaultSettings.daysToExpiration || 0,

      // 메타 정보
      generatedAt: new Date().toISOString(),
      validUntil: this.getExpiryDate(30) // 30일 기본 유효기간
    };
  },

  /**
   * 공급가 기본값 가져오기
   * @param {Object} product - 상품 정보
   * @returns {number} 공급가
   */
  getDefaultPurchasePrice(product) {
    // 가격 정보가 있는 경우
    if (product.pricing && product.pricing.purchasePrice) {
      return product.pricing.purchasePrice;
    }

    // 옵션에 가격 정보가 있는 경우
    if (product.options && product.options.length > 0) {
      const option = store.state.selectedOption || product.options[0];
      if (option.purchasePrice) {
        return option.purchasePrice;
      }
    }

    return 0;
  },

  /**
   * 판매가 기본값 가져오기
   * @param {Object} product - 상품 정보
   * @returns {number} 판매가
   */
  getDefaultSalePrice(product) {
    // 가격 정보가 있는 경우
    if (product.pricing && product.pricing.salePrice) {
      return product.pricing.salePrice;
    }

    // 옵션에 가격 정보가 있는 경우
    if (product.options && product.options.length > 0) {
      const option = store.state.selectedOption || product.options[0];
      if (option.sellingPrice) {
        return option.sellingPrice;
      } else if (option.supplyPrice) {
        return option.supplyPrice;
      }
    }

    return 0;
  },

  /**
   * 권장소비자가 기본값 가져오기
   * @param {Object} product - 상품 정보
   * @returns {number} 권장소비자가
   */
  getDefaultMSRP(product) {
    // 가격 정보가 있는 경우
    if (product.pricing && product.pricing.msrp) {
      return product.pricing.msrp;
    }

    // 옵션에 가격 정보가 있는 경우
    if (product.options && product.options.length > 0) {
      const option = store.state.selectedOption || product.options[0];
      if (option.msrp) {
        return option.msrp;
      } else if (option.sellingPrice) {
        return option.sellingPrice;
      } else if (option.supplyPrice) {
        return option.supplyPrice;
      }
    }

    // 판매가로 대체
    return this.getDefaultSalePrice(product);
  },

  /**
   * 기본 인증 정보 가져오기
   * @returns {Array} 인증 정보 배열
   */
  getDefaultCertificates() {
    return [
      { name: '전기용품 및 생활용품, 어린이 (KC) 인증번호', value: '해당사항없음' },
      { name: '방송통신 기자재 (EMC) 인증 번호', value: '해당사항없음' },
      { name: '안전기준적합확인 신고번호', value: '해당사항없음' }
    ];
  },

  /**
   * 견적서 유효기간 계산
   * @param {number} days - 유효 기간(일)
   * @returns {string} ISO 형식의 날짜 문자열
   */
  getExpiryDate(days = 30) {
    const today = new Date();
    const expiryDate = new Date();
    expiryDate.setDate(today.getDate() + parseInt(days));
    return expiryDate.toISOString();
  },

  /**
   * 수동으로 작성된 견적서 데이터 처리
   * @param {Object} product - 상품 정보
   * @param {Object} manualData - 수동 입력 데이터
   * @returns {Promise<Object>} 처리된 견적서 데이터
   */
  async processManualQuotation(product, manualData) {
    try {
      if (!product || !manualData) {
        throw new Error('유효하지 않은 데이터입니다.');
      }

      // 바코드 처리
      let barcode = '바코드 없음(쿠팡 바코드 생성 요청)';
      if (manualData.barcodeType === 'custom' && manualData.barcode) {
        barcode = manualData.barcode;
      }

      // 인증 정보 처리
      const certificates = manualData.certificates.map(cert => {
        return {
          name: cert.name,
          value: cert.value === 'custom' ? cert.customValue : cert.value
        };
      });

      // 기본 견적서 정보 생성
      const baseData = this.createBaseQuotationData(product);

      // 수동 데이터 병합
      const quotationData = {
        ...baseData,
        productName: manualData.productName || baseData.productName,
        modelNumber: manualData.modelNumber || baseData.modelNumber,
        barcode: barcode,
        manufacturer: manualData.manufacturer || baseData.manufacturer,
        businessType: manualData.businessType || baseData.businessType,
        taxationType: manualData.taxationType || '과세',
        importType: manualData.importType || '수입대상아님',
        purchasePrice: manualData.purchasePrice || baseData.purchasePrice,
        salePrice: manualData.salePrice || baseData.salePrice,
        msrp: manualData.msrp || baseData.msrp,
        productNotices: manualData.productNotices || [],
        kcMarkType: manualData.kcMarkType || '해당사항없음',
        certificates: certificates,
        skuUnitBoxWeight: manualData.skuUnitBoxWeight || '1000',
        skuUnitBoxDimension: manualData.skuUnitBoxDimension || '300*300*50',
        totalSkusInBox: manualData.totalSkusInBox || 24,
        daysToExpiration: manualData.daysToExpiration || 0,
        specialHandlingReason: manualData.specialHandlingReason || '해당사항없음',
        fashionSeason: manualData.fashionSeason || '사계절',
        exposedAttributes: manualData.exposedAttributes || [],
        unexposedAttributes: manualData.unexposedAttributes || [],
        generatedAt: new Date().toISOString(),
        validUntil: this.getExpiryDate(30)
      };

      // 상품 업데이트
      const updatedProduct = JSON.parse(JSON.stringify(product));
      updatedProduct.quotation = quotationData;
      updatedProduct.quotationStatus = 'completed';

      // coupangDoc 업데이트
      const targetOption = store.state.selectedOption ||
        (updatedProduct.options && updatedProduct.options.length > 0
          ? updatedProduct.options[0] : null);

      if (targetOption && targetOption.coupangDoc) {
        // 옵션의 coupangDoc 업데이트
        this.updateCoupangDoc(targetOption.coupangDoc, quotationData);

        // 모든 옵션의 quotationStatus 업데이트
        if (updatedProduct.options) {
          updatedProduct.options.forEach(opt => {
            opt.quotationStatus = 'completed';
          });
        }
      } else if (updatedProduct.coupangDoc) {
        // 상품의 coupangDoc 업데이트
        this.updateCoupangDoc(updatedProduct.coupangDoc, quotationData);
      }

      // Vuex 액션 호출
      await store.dispatch('updateRocketProduct', updatedProduct);

      return quotationData;
    } catch (error) {
      console.error('수동 견적서 처리 중 오류:', error);
      throw error;
    }
  }
};

export default quotationService;