import store from '@/store';
import workflowService from '../workflowService';
// import apiService from '../apiService';
// const updateTracker = new Map();

/**
 * SEO 설정을 위한 서비스
 */
const seoService = {
  /**
 * SEO 설정 처리 메인 함수
 * @param {Object} product - 상품 객체
 * @param {Object} data - 요청 데이터
 * @returns {Promise<Object>} 처리 결과
 */
  async processSeo(product, data = {}) {
    try {
      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'SEO 설정 작업 중...'
      });

      let seoResult = null;

      // SEO 설정 모드에 따라 처리
      if (data.mode === 'manual' && data.manualData) {
        // 수동 SEO 설정
        seoResult = this.processManualSeo(data.manualData);
      } else {
        // AI 자동 SEO 설정 (기본값)
        seoResult = await this.processAiSeo(product, data.options || {});
      }

      // 옵션 정보 추가
      if (product.options && product.options.length > 0) {
        seoResult.options = product.options.map(option => ({
          id: option.id,
          optionNumber: option.optionNumber,
          korName: option.korName || ''
        }));
      }

      return {
        status: 'completed',
        type: 'seoStatus',
        seoInfo: seoResult
      };
    } catch (error) {
      console.error('SEO 설정 처리 중 오류:', error);
      return {
        status: 'error',
        type: 'seoStatus',
        error: error.message
      };
    } finally {
      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false
      });
    }
  },

  /**
 * 수동 SEO 설정 처리
 * @param {Object} manualData - 수동 SEO 설정 데이터
 * @returns {Object} 처리 결과
 */
  processManualSeo(manualData) {
    // 유효성 검사
    if (!manualData.productName) {
      throw new Error('상품명은 필수 항목입니다.');
    }

    // 키워드 처리 (문자열에서 배열로 변환)
    const keywords = Array.isArray(manualData.keywords)
      ? manualData.keywords
      : (manualData.keywordsText || '').split(',').map(k => k.trim()).filter(k => k);

    // 특징 처리 (빈 항목 제거)
    const features = Array.isArray(manualData.features)
      ? manualData.features.filter(f => f.trim().length > 0)
      : [];

    // 옵션 정보 처리
    const options = Array.isArray(manualData.options)
      ? manualData.options
      : [];

    // SEO 점수 계산
    const scoreData = this.calculateSeoScore(manualData.productName, keywords, manualData.metaDescription, features);

    // 최종 SEO 데이터 구성
    return {
      productName: manualData.productName,
      keywords: keywords,
      metaDescription: manualData.metaDescription || '',
      features: features,
      options: options,
      score: scoreData.totalScore,
      scoreBreakdown: scoreData.breakdown
    };
  },
  // seoService.js의 processAiSeo 메서드 수정
  async processAiSeo(product, options) {
    try {
      // 로딩 메시지 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI를 활용한 SEO 분석 준비 중...'
      });

      // 연관 키워드 수집 (아직 없는 경우)
      let relatedKeywords = options.relatedKeywords || [];
      if (!relatedKeywords.length && options.targetKeyword) {
        try {
          store.dispatch('setProcessing', {
            isProcessing: true,
            message: '쿠팡에서 연관 키워드 수집 중...'
          });

          // workflowService를 통해 연관 키워드 수집
          relatedKeywords = await workflowService.collectCoupangKeywords(
            options.targetKeyword,
            { cycle: 1, relatedKeywords: true, autoComplete: true }
          );

          store.dispatch('setProcessing', {
            isProcessing: true,
            message: `${relatedKeywords.length}개 연관 키워드 수집 완료`
          });
        } catch (e) {
          console.warn('연관 키워드 수집 실패:', e);
          // 실패해도 계속 진행 (필수 기능은 아니므로)
        }
      }

      // 로딩 메시지 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI로 SEO 정보 생성 요청 중...'
      });

      // 프롬프트에 연관 키워드 포함
      const promptOptions = {
        ...options,
        relatedKeywords
      };
      const prompt = this.promptForSeo(product, promptOptions);

      // 확장 프로그램에 요청만 보내고 결과는 기다리지 않음
      workflowService.requestExtension({
        type: 'SEO',
        productId: product.registrationId,
        prompt
      });

      // 처리 중 메시지 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'SEO 정보 최적화 요청 완료. 결과를 기다리는 중...'
      });

      // 기본 SEO 데이터 생성 (확장 프로그램 응답이 도착할 때까지 임시로 사용)
      return {
        productName: this.optimizeProductName(product.productName || product.productNameTrans || ''),
        keywords: this.generateDefaultKeywords(product),
        metaDescription: this.generateDefaultDescription(product),
        features: this.generateDefaultFeatures(product),
        options: this.generateDefaultOptions(product),
        score: 0,  // 점수는 확장 프로그램 응답으로 업데이트 예정
        scoreBreakdown: [],
        requestPending: true  // 요청 중임을 표시
      };
    } catch (error) {
      console.error('AI SEO 설정 처리 중 오류:', error);
      throw error;
    }
  },

  /**
   * 기본 옵션명 생성
   * @param {Object} product - 상품 객체
   * @returns {Array} 생성된 옵션 배열
   */
  generateDefaultOptions(product) {
    if (!product.options || product.options.length === 0) return [];

    return product.options.map(option => {
      let korName = '';

      // 옵션 정보에서 색상 키워드 추출
      const optionName = option.option1 ? (option.option1.name || '') : '';
      const optionNameTrans = option.option1 ? (option.option1.nameTrans || '') : '';

      // 색상 키워드 매칭
      const colorMap = {
        'black': '블랙',
        'white': '화이트',
        'red': '레드',
        'blue': '블루',
        'green': '그린',
        'yellow': '옐로우',
        'purple': '퍼플',
        'pink': '핑크',
        'gray': '그레이',
        'brown': '브라운',
        'beige': '베이지',
        'silver': '실버',
        'gold': '골드'
      };

      // 영문 색상 찾기
      let foundColor = '';
      Object.keys(colorMap).forEach(color => {
        if (optionName.toLowerCase().includes(color) ||
          optionNameTrans.toLowerCase().includes(color)) {
          foundColor = colorMap[color];
        }
      });

      // 타겟 키워드
      const targetKeyword = product.targetKeyword || '상품';

      // 한글 옵션명 구성
      if (foundColor) {
        korName = `${foundColor} ${targetKeyword}`;
      } else {
        korName = `${targetKeyword} ${optionNameTrans || '옵션'}`;
      }

      return {
        id: option.id,
        optionNumber: option.optionNumber,
        korName: korName
      };
    });
  },

  /**
   * 확장 프로그램에 요청 전송
   * @param {Object} requestData - 요청 데이터
   * @returns {Promise<string>} 요청 ID
   */
  async requestExtension(requestData) {
    return new Promise((resolve) => {
      // API 키 확인
      const apiKey = store.state.settings[0]?.geminiAPIKey;
      if (!apiKey) {
        console.warn('Gemini API Key가 설정되지 않았습니다.');
        resolve(null);
        return;
      }

      const requestId = Date.now().toString();

      // 요청 메시지 생성
      const message = {
        greeting: "AiRocketRegRequest",
        id: requestId,
        apiKey: apiKey,
        productId: requestData.productId,
        workType: requestData.type
      };

      // requestArr가 있으면 그대로 전달, 없으면 단일 요청을 배열로 변환
      if (requestData.requestArr) {
        message.requestArr = requestData.requestArr;
      } else if (requestData.prompt) {
        message.requestArr = [{
          prompt: requestData.prompt
        }];
      }

      // 메시지 전송
      window.postMessage(message, "*");
      resolve(requestId);
    });
  },

  promptForSeo(product, options = {}) {
    const targetKeyword = options.targetKeyword || product.targetKeyword || '';
    const brandName = options.brandName || product.brand || '';
    const productFeature = options.productFeature || '';
    const relatedKeywords = options.relatedKeywords || [];

    console.log("SEO 프롬프트 - 입력 데이터:", {
      productName: product.productName,
      productNameTrans: product.productNameTrans,
      targetKeyword: options.targetKeyword || product.targetKeyword,
      brand: options.brandName || product.brand,
      optionsCount: product.options ? product.options.length : 0
    });
    // 옵션 정보 수집 개선
    let optionsInfo = '';
    if (product.options && product.options.length > 0) {
      optionsInfo = '\n옵션 정보:\n';
      product.options.forEach((option, index) => {
        // option1과 option2 정보 추출
        const option1Info = option.option1 ? `${option.option1.name || ''} (${option.option1.nameTrans || ''})` : '';
        const option2Info = option.option2 ? `${option.option2.name || ''} (${option.option2.nameTrans || ''})` : '';

        // 옵션 정보 구성
        optionsInfo += `${index + 1}. 옵션번호: ${option.optionNumber}\n`;
        if (option1Info) optionsInfo += `   옵션1: ${option1Info}\n`;
        if (option2Info) optionsInfo += `   옵션2: ${option2Info}\n`;
      });
    }

    // 연관 키워드 정보 추가
    let relatedKeywordsSection = '';
    if (relatedKeywords && relatedKeywords.length > 0) {
      relatedKeywordsSection = `
  쿠팡에서 수집된 연관 키워드:
  ${relatedKeywords.join(', ')}
  
  위 연관 키워드 중에서 검색량과 관련성이 높은 키워드를 우선적으로 활용하여 SEO 최적화를 진행하세요.
  `;
    }

    return `다음은 "${product.productName || product.productNameTrans || ''}" 상품의 정보입니다.
   
  중국어 상품명: ${product.productName || product.productNameTrans || ''}
  브랜드명 : ${brandName}
  타겟 키워드: ${targetKeyword}
  상품 특징: ${productFeature}
  옵션 정보 : ${optionsInfo}
  연관키워드: ${relatedKeywordsSection}
   
  위 상품의 쿠팡 판매를 위한 SEO 최적화 정보를 생성해주세요:
   
  1. 검색 최적화된 상품명 (70자 이내): 
  - 핵심 키워드를 포함하되 자연스러운 한국어로 작성하세요.
  - 상품명에는 특수문자나 외국어가 포함되지 않게 해주세요.
  - 형용사를 넣지 말고 키워드의 조합 형태로 작성해주세요. 
  (예시. 북유럽 스타일의 음식과 어울리는 예쁜 오븐 그릇 (x) -> 북유럽 스타일 예쁜 오븐 그릇 (o))
  - 위의 브랜드명,상품특징,타겟키워드에 해당하는 단어를 넣어 주세요.
  (예시. 브랜드명:쿠플러스,상품특징:손잡이,타겟키워드:오븐그릇인 경우, 쿠플러스 북유럽 스타일 예쁜 손잡이 오븐 그릇 (o))

  2. 검색 키워드 (최대 150자): 
  - 쉼표로 구분된 연관검색 키워드 목록.
  - 위에 제시한 연관키워드를 활용해주세요.
  - 타사브랜드 명은 제외해 주세요.
  - 쉽표를 포함해서 최대 150자 이내로 최대한 많이 넣어주세요.

  3. 옵션명 번역: 각 옵션에 대한 자연스러운 한글 옵션명을 제공해주세요. option1과 option2 정보를 참고하여 통합된 한글 옵션명을 만드세요.
  - 컬러와 관련된 내용은 아래와 같은 스타일로 작성해주세요.
  'black': '블랙',
  'white': '화이트',
  'red': '레드',
  'blue': '블루',
  'green': '그린',
  'yellow': '옐로우',
  'purple': '퍼플',
  'pink': '핑크',
  'gray': '그레이',
  'brown': '브라운',
  'beige': '베이지',
  'silver': '실버',
  'gold': '골드'
  - 사이즈는 한국사이즈로 변환해주세요. (斤단위는 kg단위로 변경)
   
  특히 수집된 연관 키워드를 활용하여 실제 쿠팡 사용자들이 검색하는 키워드를 반영해주세요.
   
  응답은 반드시 아래 JSON 형식으로 작성해주세요:
  {
    "productName": "최적화된 상품명",
    "keywords": ["키워드1", "키워드2", "키워드3"],
    "options": [
      {
        "optionNumber": "옵션번호를 그대로 유지",
        "korName": "옵션1과 옵션2를 통합한 자연스러운 한글 옵션명"
      }
    ]
  }`;
  },

  /**
   * SEO 점수 계산
   * @param {string} productName - 상품명
   * @param {Array} keywords - 키워드 배열
   * @param {string} metaDescription - 메타 설명
   * @param {Array} features - 특징 배열
   * @returns {Object} 점수 데이터
   */
  calculateSeoScore(productName, keywords, metaDescription, features) {
    // 각 항목별 점수 계산
    let productNameScore = 0;
    let keywordsScore = 0;
    let metaDescriptionScore = 0;
    let featuresScore = 0;

    // 상품명 점수 (길이와 키워드 포함 여부 평가)
    if (productName) {
      // 적정 길이 (30-70자) 체크
      const nameLength = productName.length;
      if (nameLength >= 30 && nameLength <= 70) {
        productNameScore = 100;
      } else if (nameLength >= 20 && nameLength < 30) {
        productNameScore = 80;
      } else if (nameLength > 70 && nameLength <= 90) {
        productNameScore = 70;
      } else {
        productNameScore = 50;
      }

      // 키워드 포함 여부로 점수 추가 조정 가능
      if (keywords && keywords.length > 0) {
        const keywordIncluded = keywords.some(keyword =>
          productName.toLowerCase().includes(keyword.toLowerCase())
        );
        if (keywordIncluded) {
          productNameScore = Math.min(100, productNameScore + 10);
        }
      }
    }

    // 키워드 점수 (개수와 품질 평가)
    if (keywords && keywords.length > 0) {
      // 적정 개수 (5-10개) 체크
      const keywordCount = keywords.length;
      if (keywordCount >= 5 && keywordCount <= 10) {
        keywordsScore = 100;
      } else if (keywordCount >= 3 && keywordCount < 5) {
        keywordsScore = 80;
      } else if (keywordCount > 10 && keywordCount <= 15) {
        keywordsScore = 70;
      } else {
        keywordsScore = 50;
      }

      // 키워드 품질 (길이, 중복성 등) 평가
      // 평균 키워드 길이 계산
      const avgKeywordLength = keywords.reduce((sum, keyword) => sum + keyword.length, 0) / keywordCount;
      // 키워드 길이가 적절한지 체크 (2-20자)
      if (avgKeywordLength >= 2 && avgKeywordLength <= 20) {
        keywordsScore = Math.min(100, keywordsScore + 5);
      } else if (avgKeywordLength < 2 || avgKeywordLength > 30) {
        keywordsScore = Math.max(0, keywordsScore - 10);
      }
    }

    // 메타 설명 점수 (길이와 품질 평가)
    if (metaDescription) {
      // 적정 길이 (100-160자) 체크
      const descLength = metaDescription.length;
      if (descLength >= 100 && descLength <= 160) {
        metaDescriptionScore = 100;
      } else if (descLength >= 80 && descLength < 100) {
        metaDescriptionScore = 80;
      } else if (descLength > 160 && descLength <= 200) {
        metaDescriptionScore = 70;
      } else {
        metaDescriptionScore = 50;
      }

      // 키워드 포함 여부, 문장 구성 등으로 점수 추가 조정
      if (keywords && keywords.length > 0) {
        const keywordIncluded = keywords.some(keyword =>
          metaDescription.toLowerCase().includes(keyword.toLowerCase())
        );
        if (keywordIncluded) {
          metaDescriptionScore = Math.min(100, metaDescriptionScore + 10);
        }
      }

      // 콜투액션(CTA) 포함 여부 체크
      const ctaPatterns = ['지금', '바로', '확인', '구매', '클릭', '쿠팡'];
      const hasCTA = ctaPatterns.some(pattern =>
        metaDescription.includes(pattern)
      );
      if (hasCTA) {
        metaDescriptionScore = Math.min(100, metaDescriptionScore + 5);
      }
    }

    // 특징 점수 (개수와 품질 평가)
    if (features && features.length > 0) {
      // 적정 개수 (3-5개) 체크
      const featureCount = features.length;
      if (featureCount >= 3 && featureCount <= 5) {
        featuresScore = 100;
      } else if (featureCount >= 1 && featureCount < 3) {
        featuresScore = 80;
      } else if (featureCount > 5 && featureCount <= 7) {
        featuresScore = 70;
      } else {
        featuresScore = 50;
      }

      // 특징 품질 (길이, 구체성 등) 평가
      const validFeatures = features.filter(feature => feature.length >= 10 && feature.length <= 100);
      const validRatio = validFeatures.length / features.length;

      if (validRatio >= 0.8) {
        featuresScore = Math.min(100, featuresScore + 10);
      } else if (validRatio < 0.5) {
        featuresScore = Math.max(0, featuresScore - 10);
      }
    }

    // 전체 점수 계산 (가중치 적용)
    const totalScore = Math.round(
      (productNameScore * 0.3) +
      (keywordsScore * 0.3) +
      (metaDescriptionScore * 0.25) +
      (featuresScore * 0.15)
    );

    // 점수 항목 구성
    const breakdown = [
      { name: '상품명 최적화', score: productNameScore },
      { name: '키워드 최적화', score: keywordsScore },
      { name: '메타 설명 품질', score: metaDescriptionScore },
      { name: '상품 특징', score: featuresScore }
    ];

    return {
      totalScore,
      breakdown
    };
  },

  /**
   * 상품명 최적화
   * @param {string} productName - 원본 상품명
   * @returns {string} 최적화된 상품명
   */
  optimizeProductName(productName) {
    if (!productName) return '';

    // 특수문자 제거 및 길이 제한

    // 특수문자 제거 (일부 특수문자 유지)
    let optimized = productName.replace(/[^\w\s가-힣\-_()]/g, ' ');

    // 여러 공백을 하나로 통합
    optimized = optimized.replace(/\s+/g, ' ').trim();

    // 70자로 길이 제한
    if (optimized.length > 70) {
      optimized = optimized.substring(0, 67) + '...';
    }

    return optimized;
  },

  /**
   * 기본 키워드 생성
   * @param {Object} product - 상품 객체
   * @returns {Array} 생성된 키워드 배열
   */
  generateDefaultKeywords(product) {
    const keywords = [];

    // 타겟 키워드 추가
    if (product.targetKeyword) {
      keywords.push(product.targetKeyword);
    }

    // 상품명에서 주요 단어 추출
    const productName = product.productName || product.productNameTrans || '';
    if (productName) {
      const words = productName
        .split(/\s+/)
        .filter(word => word.length >= 2)
        .slice(0, 5);

      keywords.push(...words);
    }

    // 브랜드 추가
    if (product.brand) {
      keywords.push(product.brand);
    }

    // 카테고리 추가
    if (product.category) {
      keywords.push(product.category);
    }

    // 추가 일반 키워드
    const additionalKeywords = ['할인', '특가', '무료배송', '로켓배송'];
    keywords.push(...additionalKeywords);

    // 중복 제거 및 최대 10개로 제한
    return [...new Set(keywords)].slice(0, 10);
  },

  /**
   * 기본 메타 설명 생성
   * @param {Object} product - 상품 객체
   * @returns {string} 생성된 메타 설명
   */
  generateDefaultDescription(product) {
    let description = '';

    // 브랜드와 상품명으로 시작
    if (product.brand) {
      description += `${product.brand} `;
    }

    const productName = product.productName || product.productNameTrans || '';
    if (productName) {
      description += `${productName}. `;
    }

    // 추가 정보
    if (product.targetKeyword) {
      description += `${product.targetKeyword} 관련 최고의 상품입니다. `;
    }

    // 기본 문구 추가
    description += '높은 품질과 합리적인 가격으로 제공됩니다. 쿠팡 로켓배송으로 빠르게 받아보세요!';

    // 160자로 길이 제한
    if (description.length > 160) {
      description = description.substring(0, 157) + '...';
    }

    return description;
  },

  /**
   * 기본 상품 특징 생성
   * @param {Object} product - 상품 객체
   * @returns {Array} 생성된 특징 배열
   */
  generateDefaultFeatures(product) {
    const productName = product.productName || product.productNameTrans || '';
    const brandName = product.brand || '제품';

    const features = [
      `${brandName}의 고품질 소재로 제작되어 내구성이 뛰어납니다.`,
      `${productName}의 세련된 디자인으로 다양한 스타일에 잘 어울립니다.`,
      `합리적인 가격으로 가성비가 우수한 상품입니다.`,
      `편리한 사용감으로 일상생활에 편의를 더합니다.`,
      `쿠팡 로켓배송으로 빠르게 받아보실 수 있습니다.`
    ];

    return features;
  },

  async handleResult(product, result) {
    try {
      if (!product || !result) return false;

      console.log('SEO 결과 처리 시작:', result);

      // 결과 파싱 시도
      let seoData;
      if (typeof result === 'string') {
        try {
          seoData = JSON.parse(result);
        } catch (e) {
          console.error('SEO 데이터 파싱 오류:', e);
          return false;
        }
      } else {
        seoData = result;
      }

      // 필수 필드 확인
      if (!seoData.productName) {
        console.error('SEO 데이터에 상품명이 없습니다.');
        return false;
      }

      console.log('처리할 SEO 데이터:', seoData);

      // 상품 정보 업데이트 (깊은 복사)
      const updatedProduct = JSON.parse(JSON.stringify(product));

      // SEO 데이터 설정
      updatedProduct.seo = {
        productName: seoData.productName,
        keywords: seoData.keywords || [],
        metaDescription: seoData.metaDescription || '',
        features: seoData.features || [],
        score: seoData.score || 0,
        scoreBreakdown: seoData.scoreBreakdown || [],
        options: seoData.options || []
      };

      // 상태 업데이트
      updatedProduct.seoStatus = 'completed';

      // 옵션 정보 업데이트
      if (seoData.options && seoData.options.length > 0 && updatedProduct.options && updatedProduct.options.length > 0) {
        console.log('옵션 정보 업데이트 시작:', seoData.options);

        // 각 옵션들을 optionNumber 기준으로 매핑하여 처리
        seoData.options.forEach(seoOption => {
          if (seoOption.optionNumber && seoOption.korName) {
            // 옵션번호로 매칭 시도
            const option = updatedProduct.options.find(opt => opt.optionNumber === seoOption.optionNumber);

            if (option) {
              console.log(`옵션 매칭 성공 - 옵션번호 ${seoOption.optionNumber}: ${seoOption.korName}`);

              // korName 속성 명시적 설정
              option.korName = seoOption.korName;

              // 옵션별 전체 상품명 생성
              option.fullProductName = `${updatedProduct.seo.productName}, ${seoOption.korName}`;

              option.seoStatus = 'completed'; 

              // 옵션별 coupangDoc 업데이트 (docId가 있는 경우)
              if (option.coupangDoc && option.coupangDoc.docId) {
                // 상품명 업데이트
                option.coupangDoc.productName = option.fullProductName;

                if (option.coupangDoc.jsonDocument &&
                  option.coupangDoc.jsonDocument.length > 0) {

                  // startPage 상품명 업데이트
                  if (option.coupangDoc.jsonDocument[0].startPage) {
                    option.coupangDoc.jsonDocument[0].startPage.productName = option.fullProductName;
                  }

                  // 검색 태그 업데이트
                  if (option.coupangDoc.jsonDocument[0].productPage) {
                    option.coupangDoc.jsonDocument[0].productPage.searchTags = updatedProduct.seo.keywords.join(', ');
                  }
                }
              }
            } else {
              console.warn(`옵션 매칭 실패 - 옵션번호 ${seoOption.optionNumber} 찾을 수 없음`);
            }
          }
        });
      }

      // 점수 계산이 없는 경우 직접 계산
      if (!updatedProduct.seo.score) {
        const scoreData = this.calculateSeoScore(
          updatedProduct.seo.productName,
          updatedProduct.seo.keywords,
          updatedProduct.seo.metaDescription,
          updatedProduct.seo.features
        );

        updatedProduct.seo.score = scoreData.totalScore;
        updatedProduct.seo.scoreBreakdown = scoreData.breakdown;
      }

      // 상품 정보 로깅 
      console.log('SEO 정보 업데이트:', {
        seoProductName: updatedProduct.seo.productName,
        keywords: updatedProduct.seo.keywords.length
      });

      // 서버에 저장 - 여기를 변경 (updateRocketProduct에서 updateProductSeo로)
      try {
        await store.dispatch('updateProductSeo', updatedProduct);
        console.log('SEO 정보 저장 성공');
        return true;
      } catch (error) {
        console.error('SEO 정보 저장 실패:', error);
        return false;
      }
    } catch (error) {
      console.error('SEO 결과 처리 중 오류:', error);
      return false;
    }
  },

  /**
   * SEO 설정 프로세스 시작
   * @param {Object} product - 상품 객체
   * @returns {Promise<Object>} 처리 결과
   */
  async process(product) {
    try {
      // 상품 유효성 확인
      if (!product || !product.registrationId) {
        throw new Error('유효하지 않은 상품 정보입니다.');
      }

      // 로딩 상태 시작
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'SEO 최적화 시작 중...'
      });

      // 상품 정보 기반 SEO 데이터 생성
      const options = {
        targetKeyword: product.targetKeyword || ''
      };

      // AI SEO 설정 처리
      const result = await this.processAiSeo(product, options);

      // 결과 처리
      if (result) {
        // 상품 업데이트
        const updatedProduct = JSON.parse(JSON.stringify(product));
        updatedProduct.seo = result;
        updatedProduct.seoStatus = 'completed';

        // Vuex 액션 호출
        await store.dispatch('updateProductSeo', updatedProduct);

        return {
          status: 'completed',
          seoData: result
        };
      } else {
        throw new Error('SEO 데이터 생성 실패');
      }
    } catch (error) {
      console.error('SEO 처리 중 오류:', error);

      return {
        status: 'error',
        error: error.message || '알 수 없는 오류'
      };
    } finally {
      // 로딩 상태 종료
      store.dispatch('setProcessing', { isProcessing: false });
    }
  }
};

export default seoService;