// import axios from 'axios';
import store from '@/store';
import imageService from '../imageService';
import apiService from '../apiService';
// import workflowService from '../workflowService';
import html2canvas from 'html2canvas';

/**
 * 상세 이미지 처리를 위한 서비스
 */
const detailImageService = {
  /**
 * 상세 이미지 처리 메인 함수
 * @param {Object} product - 상품 객체
 * @param {Object} options - 추가 옵션 (imageMargin 등)
 * @returns {Promise<Object>} 처리 결과
 */
  async processDetailImages(product, options = {}) {
    try {
      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '상세 이미지 처리 중...'
      });

      // 이미지 간격 값 (기본값 20px)
      const imageMargin = options.imageMargin !== undefined ? options.imageMargin : 20;

      // 상세 이미지 배열 가져오기
      const detailImages = product.detailImages || [];
      if (detailImages.length === 0) {
        console.warn("상세 이미지가 없습니다.");
        return null;
      }

      // 처리된 이미지와 HTML 컨텐츠를 저장할 배열
      const processedImages = [];
      const processedHtmlContents = [];

      // 1. 이미지 번역 처리 - S3 업로드 없이 base64 데이터로 처리
      for (const imageUrl of detailImages) {
        const processedImage = await this.processImage(imageUrl, product.registrationId);
        if (processedImage) {
          processedImages.push(processedImage);

          // HTML 컨텐츠 생성 및 저장 - 이미지 간격 전달
          const htmlContent = this.generateHtmlContent(processedImage, product.productName, imageMargin);
          processedHtmlContents.push(htmlContent);
        }
      }

      // 제품 정보 업데이트를 위한 데이터 반환
      return {
        status: 'completed',
        type: 'detailImageStatus',
        processedImages: processedImages, // 처리된 이미지 정보 배열 (base64 데이터 포함)
        htmlContents: processedHtmlContents, // 생성된 HTML 콘텐츠 배열
        detailHtml: this.generateDetailHtml(processedHtmlContents, product.productName, imageMargin) // 전체 상세 페이지 HTML
      };
    } catch (error) {
      console.error('상세 이미지 처리 중 오류:', error);
      return {
        status: 'error',
        type: 'detailImageStatus',
        error: error.message
      };
    } finally {
      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: ''
      });
    }
  },

  /**
   * 단일 이미지 처리 - S3 업로드 제거하고 base64 데이터 반환
   * @param {string} imageUrl - 처리할 이미지 URL
   * @param {string} productId - 상품 등록번호
   * @returns {Promise<Object>} 처리된 이미지 정보
   */
  async processImage(imageUrl, productId) {
    try {
      // 이미지 텍스트 번역 API 호출
      const result = await this.translateImage(imageUrl);

      // 번역 결과 처리
      if (!result || !result.translatedImagePixelData) {
        console.warn("이미지 번역 결과가 없습니다.");
        return null;
      }

      // 번역된 이미지 URL과 텍스트가 제거된 이미지 URL 가져오기
      const translatedImageUrl = result.translatedImageUrl;
      const textRemovedImageUrl = result.translatedImagePixelData.repairedUrl;

      // 텍스트가 제거된 이미지를 사용할지 여부 확인
      const imageToUse = textRemovedImageUrl || translatedImageUrl || imageUrl;

      // 이미지 다운로드 및 base64 변환 (S3 업로드 없이)
      const imageBlob = await imageService.downloadImage(imageToUse);
      const base64Data = await imageService.blobToBase64(imageBlob);

      // 이미지 크기 가져오기
      const imageDimensions = await this.getImageDimensions(imageToUse);
      console.log('이미지 원본 크기:', imageDimensions);

      // 파일명 생성 (나중에 S3 업로드 시 사용)
      // const fileName = `${productId}_detail_${Date.now()}.jpg`;
      const supplierCode = store.state.user.suppliercode || store.state.user.companyid;
      const fileName = `${supplierCode}_${productId}_detail.jpg`;

      // 이미지 정보 반환 (base64 데이터 포함, S3URL은 없음)
      return {
        originalUrl: imageUrl,
        url: base64Data, // base64Data를 직접 URL로 사용
        name: fileName, // 나중에 업로드할 때 사용할 파일명
        dimensions: `${imageDimensions.width}x${imageDimensions.height}`,
        originalWidth: imageDimensions.width,
        originalHeight: imageDimensions.height,
        filesize: this.estimateFileSize(base64Data),
        processType: 'translated',
        translatedData: result, // 번역 데이터 전체 저장
        textAreas: result.translatedImagePixelData ? result.translatedImagePixelData.textAreas : [],
        isBase64: true // base64 데이터임을 표시하는 플래그 추가
      };
    } catch (error) {
      console.error('이미지 처리 중 오류:', error);
      return null;
    }
  },

  /**
   * base64 이미지를 S3에 업로드하여 URL로 변환
   * @param {Array} processedImages - base64 이미지 정보 배열
   * @returns {Promise<Array>} S3 URL로 변환된 이미지 정보 배열
   */
  async uploadProcessedImagesToS3(processedImages) {
    const uploadedImages = [];

    for (const image of processedImages) {
      try {
        if (image.isBase64 && image.url) {

          let fileName = image.name;
          if (!fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
            fileName += '.jpg'; // 확장자가 없으면 .jpg 추가
          }
          // S3에 업로드
          const s3ImageUrl = await imageService.uploadImageToS3(
            image.url, // base64 데이터
            image.name, // 파일명
            'detail' // 업로드 타입
          );

          // 업로드된 이미지 정보 저장
          uploadedImages.push({
            ...image,
            url: s3ImageUrl, // base64를 S3 URL로 교체
            isBase64: false // 더 이상 base64가 아님
          });
        } else {
          // 이미 URL인 경우 그대로 유지
          uploadedImages.push(image);
        }
      } catch (error) {
        console.error(`이미지 ${image.name} 업로드 중 오류:`, error);
        // 오류 발생 시 원본 이미지 유지
        uploadedImages.push(image);
      }
    }

    return uploadedImages;
  },

  /**
   * HTML 내의 base64 이미지를 S3 URL로 교체
   * @param {string} html - 교체할 HTML 문자열
   * @param {Object} imageMap - base64 이미지와 S3 URL 매핑 객체
   * @returns {string} 교체된 HTML 문자열
   */
  replaceBase64WithS3Urls(html, imageMap) {
    let updatedHtml = html;

    // 각 base64 이미지를 S3 URL로 교체
    Object.keys(imageMap).forEach(base64Prefix => {
      // base64 문자열은 길기 때문에 시작 부분만 매칭
      const regex = new RegExp(base64Prefix.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
      updatedHtml = updatedHtml.replace(regex, imageMap[base64Prefix]);
    });

    return updatedHtml;
  },

  /**
   * 이미지 크기 가져오기
   * @param {string} imageUrl - 이미지 URL
   * @returns {Promise<{width: number, height: number}>} - 이미지 크기
   */
  getImageDimensions(imageUrl) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight
        });
      };
      img.onerror = (err) => {
        console.error('이미지 크기 로드 오류:', err);
        // 오류 시 기본 크기 반환
        resolve({
          width: 800,
          height: 600
        });
      };
      img.src = imageUrl;
    });
  },

  /**
   * 파일 크기 추정
   * @param {string} base64Data - Base64 인코딩된 데이터
   * @returns {string} - 포맷팅된 파일 크기
   */
  estimateFileSize(base64Data) {
    if (!base64Data) return '0 KB';

    // Base64 문자열에서 데이터 부분만 추출
    const base64 = base64Data.split(',')[1];
    if (!base64) return '0 KB';

    // Base64 길이를 바이트 크기로 변환 (대략 3/4)
    const sizeInBytes = Math.ceil((base64.length * 3) / 4);

    if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${Math.round(sizeInBytes / 1024)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  },

  /**
   * 이미지 텍스트 번역 API 호출
   * @param {string} imageUrl - 이미지 URL
   * @returns {Promise<Object>} API 응답 결과
   */
  async translateImage(imageUrl) {
    try {
      const requestData = [{ imgUrl: imageUrl }];
      const response = await apiService.post('/api/aiPage/getImageTranslate', requestData);

      if (response.요청결과 === "성공" && response.result && response.result.length > 0) {
        return response.result[0];
      }

      return null;
    } catch (error) {
      console.error('이미지 번역 API 오류:', error);
      throw error;
    }
  },

  /**
 * HTML 컨텐츠 생성
 * @param {Object} image - 처리된 이미지 정보
 * @param {string} productName - 상품명
 * @param {number} imageMargin - 이미지 간격 (픽셀)
 * @returns {string} 생성된 HTML 컨텐츠
 */
  generateHtmlContent(image, productName, imageMargin = 20) {
    // 텍스트 영역 정보가 없으면 기본 이미지만 반환 (마진 적용)
    if (!image.textAreas || image.textAreas.length === 0) {
      return `<div style="margin-bottom: ${imageMargin}px; text-align: center;">
      <img src="${image.url}" alt="${productName}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">
    </div>`;
    }

    // 이미지 원본 크기 정보를 가져오기
    const originalWidth = image.originalWidth || 800;
    const originalHeight = image.originalHeight || 600;

    // 이미지의 상대적 위치에 텍스트를 배치하는 HTML 생성 (마진 적용)
    let htmlContent = `
  <div style="position: relative; width: 100%; margin: 0 auto; margin-bottom: ${imageMargin}px;">
    <img src="${image.url}" alt="${productName}" style="display: block; width: 100%; height: auto; margin: 0 auto;">
  `;

    // 각 텍스트 영역에 대한 HTML 추가
    image.textAreas.forEach((textArea) => {
      if (textArea.texts && textArea.texts.length > 0 && textArea.texts[0].valid) {
        const text = textArea.texts[0];

        // 위치 및 크기 계산 (상대 단위로 변환)
        const topPercent = (text.textRect.top / originalHeight) * 100;
        const leftPercent = (text.textRect.left / originalWidth) * 100;
        const widthPercent = (text.textRect.width / originalWidth) * 100;
        const heightPercent = (text.textRect.height / originalHeight) * 100;

        // 줄 수 확인
        const lineCount = text.lineCount || 1;

        // 수정: 컨테이너 크기에 맞게 폰트 크기 계산
        // 800px 너비 기준으로 조정된 vw 단위 사용
        // 기존 baseFontSize는 전체 뷰포트 기준이었으나, 이제 800px 컨테이너 기준으로 조정
        const fontSizeRatio = text.fontsize / originalWidth;
        const adjustedFontSize = fontSizeRatio * 800; // 800px 기준으로 조정

        // 컨테이너 너비 비율로 조정 (max-width:800px 내부에서의 비율)
        const containerRatio = 1; // 800px / 1000px (평균 화면 너비 가정)
        const baseFontSize = `${adjustedFontSize * containerRatio}px`;

        // 컨테이너 크기 기반 스케일 계산
        let scaleForContainer = 1.0;

        // 한 줄 텍스트의 경우
        if (lineCount === 1) {
          // 텍스트의 예상 길이 (글자 수 * 평균 글자 너비)
          const estimatedTextWidth = text.value.length * text.fontsize;
          // 컨테이너 너비와 예상 텍스트 너비의 비율 계산
          const widthRatio = text.textRect.width / estimatedTextWidth;
          // 높이도 고려
          const heightRatio = text.textRect.height / text.fontsize;
          // 너비와 높이 중 더 제한적인(작은) 비율 사용
          scaleForContainer = Math.min(widthRatio, heightRatio, 1); // 여유 공간 위해 90%로 제한
        }
        // 여러 줄 텍스트의 경우
        else if (lineCount > 1) {
          // 줄당 높이 계산
          const lineHeight = text.textRect.height / lineCount;
          // 폰트 크기에 대한 줄 높이의 비율
          const heightRatio = lineHeight / text.fontsize;
          // 한 줄에 들어갈 수 있는 글자 수 예상
          const charsPerLine = Math.floor(text.textRect.width / text.fontsize);
          // 텍스트 길이가 한 줄에 들어갈 수 있는 글자 수의 lineCount배를 초과하는지 확인
          const totalCharsCapacity = charsPerLine * lineCount;
          const widthRatio = totalCharsCapacity / text.value.length;
          // 두 비율 중 더 제한적인 값 사용
          scaleForContainer = Math.min(heightRatio, widthRatio, 1); // 여유 공간 위해 85%로 제한
        }

        // 최종 폰트 크기 (px 단위로 고정)
        const finalFontSize = parseFloat(baseFontSize) * scaleForContainer;
        const fontSizePx = `${finalFontSize}px`;

        // 텍스트 스타일 설정
        const color = text.color || '#000000';

        // 텍스트 정렬
        let textAlign = 'left';
        if (text.horizontalLayout === 'center') textAlign = 'center';
        else if (text.horizontalLayout === 'right') textAlign = 'right';

        // 줄 수에 따른 텍스트 줄바꿈 처리
        const whiteSpace = lineCount > 1 ? 'normal' : 'nowrap';

        // 텍스트 요소 추가
        htmlContent += `
      <div style="position: absolute; top: ${topPercent}%; left: ${leftPercent}%; width: ${widthPercent}%; height: ${heightPercent}%; 
                  font-size: ${fontSizePx}; color: ${color}; text-align: ${textAlign}; 
                  display: flex; align-items: center; justify-content: ${textAlign === 'center' ? 'center' : 'flex-start'};
                  font-weight: bold; text-shadow: 0px 0px 2px white; line-height: 1.2;
                  overflow: visible; word-break: ${lineCount > 1 ? 'break-word' : 'keep-all'}; 
                  white-space: ${whiteSpace}; z-index: 2;"><span>${text.value}</span></div>
      `;
      }
    });

    htmlContent += `</div>`;
    return htmlContent;
  },
  // // detailImageService.js의 generateHtmlContent 메서드 수정
  // generateHtmlContent(image, productName, imageMargin = 20) {
  //   // 텍스트 영역이 없으면 기본 이미지만 반환
  //   if (!image.textAreas || image.textAreas.length === 0) {
  //     return `<div style="margin-bottom: ${imageMargin}px; text-align: center;">
  //         <img src="${image.url}" alt="${productName}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">
  //     </div>`;
  //   }

  //   // 이미 저장된 HTML 결과가 있는지 확인
  //   const existingResult = store.state.detailHtmlResults &&
  //     store.state.detailHtmlResults[image.url];

  //   if (existingResult) {
  //     console.log('이미 생성된 HTML 결과 사용:', image.url);
  //     return existingResult;
  //   }

  //   // 고유 ID 생성
  //   const uniqueId = `detail-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  //   // workflowService를 통해 HTML 생성 요청
  //   workflowService.requestDetailHtmlGenerate({
  //     imageUrl: image.url,
  //     textAreas: image.textAreas,
  //     productName: productName,
  //     additionalOptions: {
  //       imageMargin: imageMargin,
  //       dimensions: image.dimensions,
  //       originalWidth: image.originalWidth,
  //       originalHeight: image.originalHeight,
  //       uniqueId: uniqueId  // 응답이 왔을 때 어떤 요소를 업데이트할지 식별하기 위함
  //     }
  //   });

  //   // 기본 HTML 생성하여 즉시 반환 (나중에 응답이 오면 DOM 업데이트)
  //   return `<div style="margin-bottom: ${imageMargin}px; text-align: center;" class="detail-image-container" id="${uniqueId}">
  //     <img src="${image.url}" alt="${productName}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">
  //     <div class="detail-html-loading" style="padding: 8px; margin-top: 10px; background: #f8f9fa; border-radius: 4px; 
  //         font-size: 12px; color: #6c757d; text-align: center;">
  //         AI가 상세 내용을 생성 중입니다...
  //     </div>
  // </div>`;
  // },
  // detailImageService.js에 새로운 메서드 추가
  handleHtmlGenerateResult(result) {
    // 결과가 유효한지 확인
    if (!result || !result.htmlContent || !result.additionalOptions || !result.additionalOptions.uniqueId) {
      console.warn('유효하지 않은 HTML 생성 결과:', result);
      return false;
    }

    // Vuex 스토어에 결과 저장
    if (result.imageUrl) {
      store.commit('SET_DETAIL_HTML_RESULT', {
        imageUrl: result.imageUrl,
        htmlContent: result.htmlContent
      });
    }

    // DOM 업데이트 시도 (클라이언트 사이드에 있을 경우)
    if (typeof document !== 'undefined') {
      const containerId = result.additionalOptions.uniqueId;
      const container = document.getElementById(containerId);

      if (container) {
        // 기존 컨테이너를 생성된 HTML로 교체
        container.outerHTML = result.htmlContent;
        console.log(`HTML 컨테이너 업데이트 완료: ${containerId}`);
        return true;
      } else {
        console.warn(`HTML을 업데이트할 컨테이너를 찾을 수 없음: ${containerId}`);
      }
    }

    return false;
  },

  /**
   * 전체 상세 페이지 HTML 생성
   * @param {Array} htmlContents - HTML 콘텐츠 배열
   * @param {string} productName - 상품명
   * @returns {string} 생성된 전체 HTML
   */
  generateDetailHtml(htmlContents) {
    let html = `
    <div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">
    `;

    // 각 HTML 콘텐츠 추가 (이미 콘텐츠 자체에 마진이 포함되어 있음)
    htmlContents.forEach(content => {
      html += content;
    });

    html += '</div>';
    return html;
  },

  /**
   * HTML에서 이미지 URL 추출
   * @param {string} html - HTML 문자열
   * @returns {Array} 추출된 이미지 URL 배열
   */
  extractImagesFromHtml(html) {
    const imgRegex = /<img[^>]+src="([^">]+)"/g;
    const urls = [];
    let match;

    while ((match = imgRegex.exec(html)) !== null) {
      if (match[1] && match[1].length > 10) {
        urls.push(match[1]);
      }
    }

    return urls;
  },
  /**
   * HTML을 이미지로 변환하여 S3에 업로드
   * @param {string} html - 변환할 HTML 문자열
   * @param {string} productId - 상품 ID
   * @returns {Promise<string>} 업로드된 이미지 URL
   */
  async convertHtmlToImage(html, productId) {
    try {
      console.log('[convertHtmlToImage] >>> Start')

      // 1) 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '상세 HTML을 이미지로 변환 중...'
      })

      // 2) 임시 컨테이너 생성
      const container = document.createElement('div')
      container.innerHTML = html
      container.style.position = 'absolute'
      container.style.top = '-9999px'
      container.style.left = '0'
      container.style.width = '800px' // 캡처 용도라 800px로 고정
      container.style.backgroundColor = 'yellow' // 디버깅용 (눈에 잘 띄도록)
      document.body.appendChild(container)

      // 4) html2canvas로 캡처
      //    (useCORS:true이면, 외부 이미지 CORS 헤더 필요)
      const canvas = await html2canvas(container, {
        useCORS: true,
        logging: true,
        scale: 1,
        backgroundColor: null // null로 하면 container의 backgroundColor(=yellow) 사용
      })

      // 5) base64 데이터 얻기
      const imageData = canvas.toDataURL('image/jpeg', 0.9)

      // 5-1) 미리보기 위해 화면에 이미지 태그로 띄워보기
      //      (실제 배포 시에는 필요 없는 디버깅용)
      // this.showPreviewInPanel(imageData)

      if (!imageData || imageData.length < 2000) {
        console.warn('[convertHtmlToImage] ***WARNING*** Image data is too small or missing => possibly blank?')
      }

      // 6) S3 업로드
      const supplierCode = store.state.user.suppliercode || store.state.user.companyid
      const fileName = `${supplierCode}_${productId}_detail_full_${Date.now()}.jpg`

      const imageUrl = await imageService.uploadImageToS3(
        imageData,
        fileName,
        'detail'
      )
      console.log('[convertHtmlToImage] S3 upload done. imageUrl:', imageUrl)

      // 7) 임시 컨테이너 제거
      document.body.removeChild(container)

      return imageUrl
    } catch (error) {
      console.error('[convertHtmlToImage] Error:', error)
      return null
    } finally {
      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: ''
      })
    }
  },

  /**
   * 디버깅/미리보기를 위해, 캡처 결과 이미지를 화면에 표시하기
   * @param {string} base64Image
   */
  // showPreviewInPanel(base64Image) {
  //   try {
  //     // 이미 미리보기 영역이 있는지 찾기
  //     let previewPanel = document.getElementById('capture-preview-panel')
  //     if (!previewPanel) {
  //       // 없으면 새로 생성
  //       previewPanel = document.createElement('div')
  //       previewPanel.id = 'capture-preview-panel'

  //       // 간단한 스타일
  //       previewPanel.style.position = 'fixed'
  //       previewPanel.style.bottom = '20px'
  //       previewPanel.style.right = '20px'
  //       previewPanel.style.width = '200px'
  //       previewPanel.style.height = '200px'
  //       previewPanel.style.overflow = 'auto'
  //       previewPanel.style.border = '2px solid #aaa'
  //       previewPanel.style.background = '#fff'
  //       previewPanel.style.zIndex = '9999'
  //       previewPanel.style.padding = '5px'
  //       previewPanel.innerHTML = `<p style="font-size:12px;">캡처 미리보기 영역</p>`

  //       document.body.appendChild(previewPanel)
  //     }

  //     // 미리보기 이미지 태그 추가
  //     const previewImg = document.createElement('img')
  //     previewImg.src = base64Image
  //     previewImg.style.width = '100%'
  //     previewImg.style.height = 'auto'
  //     previewImg.style.display = 'block'
  //     previewImg.style.marginTop = '8px'

  //     // 현재 시각 표기
  //     const time = new Date().toLocaleTimeString()
  //     const label = document.createElement('div')
  //     label.textContent = `미리보기 (${time})`
  //     label.style.fontSize = '12px'
  //     label.style.color = '#333'
  //     label.style.marginTop = '4px'

  //     previewPanel.appendChild(label)
  //     previewPanel.appendChild(previewImg)
  //     console.log('[showPreviewInPanel] Appended preview image')
  //   } catch (err) {
  //     console.error('[showPreviewInPanel] Error:', err)
  //   }
  // },

  /**
   * 상세 이미지 처리 및 저장 메서드
   * 작업 개시 버튼으로 호출될 때 처리부터 저장까지 자동화
   * @param {Object} product - 상품 객체
   * @returns {Promise<Object>} 처리 결과
   */
  async process(product) {
    try {
      // 1. 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '상세 이미지 처리 중...'
      });

      // 2. 상세 이미지 처리 (base64 데이터로 처리)
      const processResult = await this.processDetailImages(product);

      if (!processResult || processResult.status !== 'completed') {
        console.warn("상세 이미지 처리 실패:", processResult?.error || "알 수 없는 오류");
        return processResult || { status: 'error', type: 'detailImageStatus', error: '이미지 처리 실패' };
      }

      // 3. 상단/하단 이미지 정보 가져오기 (설정에 있을 경우)
      const settings = store.state.settings[0]?.defaultSettings?.imageSettings || {};
      const useTopImage = settings.useTopImage && settings.topImageUrl;
      const useBottomImage = settings.useBottomImage && settings.bottomImageUrl;

      // 이미지 간격 값 (기본값 20px)
      const imageMargin = 20;

      const topImageHtml = useTopImage ?
        `<div style="margin-bottom:${imageMargin}px; text-align:center;">
      <img src="${settings.topImageUrl}" alt="상단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
    </div>` : '';

      const bottomImageHtml = useBottomImage ?
        `<div style="margin-bottom:${imageMargin}px; text-align:center;">
      <img src="${settings.bottomImageUrl}" alt="하단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
    </div>` : '';

      // 4. 처리된 HTML에 상단/하단 이미지 추가
      let finalHtml = '';
      if (processResult.detailHtml && !processResult.detailHtml.includes('max-width:800px')) {
        // 컨테이너 시작 태그
        finalHtml = '<div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">';

        // 상단 이미지 추가 (있는 경우)
        if (useTopImage) {
          finalHtml += topImageHtml;
        }

        // 처리된 컨텐츠 추가
        finalHtml += processResult.detailHtml;

        // 하단 이미지 추가 (있는 경우)
        if (useBottomImage) {
          finalHtml += bottomImageHtml;
        }

        // 컨테이너 종료 태그
        finalHtml += '</div>';
      } else {
        // 이미 컨테이너가 있는 경우, 상단/하단 이미지를 적절한 위치에 삽입
        const parser = new DOMParser();
        const doc = parser.parseFromString(processResult.detailHtml, 'text/html');
        const container = doc.querySelector('div[style*="max-width:800px"]');

        if (container) {
          // 상단 이미지를 컨테이너의 첫 번째 자식으로 추가
          if (useTopImage) {
            const topDiv = parser.parseFromString(topImageHtml, 'text/html').body.firstChild;
            container.insertBefore(topDiv, container.firstChild);
          }

          // 하단 이미지를 컨테이너의 마지막 자식으로 추가
          if (useBottomImage) {
            const bottomDiv = parser.parseFromString(bottomImageHtml, 'text/html').body.firstChild;
            container.appendChild(bottomDiv);
          }

          finalHtml = doc.documentElement.innerHTML;
        } else {
          // 컨테이너를 찾을 수 없는 경우 초기 방식으로 HTML 생성
          finalHtml = '<div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">';
          if (useTopImage) finalHtml += topImageHtml;
          finalHtml += processResult.detailHtml;
          if (useBottomImage) finalHtml += bottomImageHtml;
          finalHtml += '</div>';
        }
      }

      // 5. 처리된 이미지가 있으면 S3에 업로드
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '이미지 업로드 중...'
      });

      // 처리된 이미지 배열
      const processedImages = processResult.processedImages || [];

      // 각 이미지에 공급자 코드와 확장자 추가 확인
      const supplierCode = store.state.user.suppliercode || 'default';
      const uploadReady = processedImages.map((image, index) => {
        // 파일명 형식 통일 (공급자코드_상품ID_detail_인덱스.jpg)
        let fileName = `${supplierCode}_${product.registrationId}_detail_${index}.jpg`;

        return {
          ...image,
          name: fileName
        };
      });

      // 이미지 업로드
      const uploadedImages = await this.uploadProcessedImagesToS3(uploadReady);

      // 6. HTML에서 base64를 S3 URL로 교체
      const imageMap = {};
      uploadedImages.forEach(img => {
        if (!img.isBase64 && img.url) {
          // base64 데이터의 앞부분만 추출
          const base64Prefix = img.originalBase64 ?
            img.originalBase64.substring(0, 100) :
            (img.url.startsWith('data:') ? img.url.substring(0, 100) : '');

          if (base64Prefix) {
            imageMap[base64Prefix] = img.url;
          }
        }
      });

      // HTML 내의 base64 이미지 URL을 S3 URL로 교체
      const updatedHtml = this.replaceBase64WithS3Urls(finalHtml, imageMap);

      // 7. HTML을 이미지로 변환하여 S3에 업로드 (새로 추가된 부분)
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'HTML을 이미지로 변환 중...'
      });

      // HTML 전체를 이미지로 변환
      const fullHtmlImageUrl = await this.convertHtmlToImage(updatedHtml, product.registrationId);

      // 8. S3 이미지 URL 목록 추출
      const detailImageUrls = uploadedImages
        .filter(img => !img.isBase64 && img.url)
        .map(img => img.url);

      // 변환된 전체 HTML 이미지를 리스트에 추가 (맨 앞에)
      if (fullHtmlImageUrl && !detailImageUrls.includes(fullHtmlImageUrl)) {
        detailImageUrls.unshift(fullHtmlImageUrl);
      }

      // 9. 상품 객체 업데이트
      const updatedProduct = {
        ...product,
        detailHtml: updatedHtml,
        detailImages: detailImageUrls,
        // 원본 이미지 필드 유지 (기존에 있으면 그대로 유지, 없으면 현재 이미지 복사)
        originDetailImages: product.originDetailImages || [...updatedProduct.detailImages.map(url => ({
          originalUrl: url,
          img: url
        }))],
        detailImageStatus: 'completed',
        lastModified: new Date()
      };

      // coupangDoc 업데이트 - 중요: HTML 전체 이미지를 detailedImage로 설정
      if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.jsonDocument &&
        updatedProduct.coupangDoc.jsonDocument.length > 0) {
        const docObj = updatedProduct.coupangDoc.jsonDocument[0];
        if (docObj.imagePage && docObj.imagePage.details) {
          // HTML을 이미지로 변환한 결과를 detailedImage에 설정
          if (fullHtmlImageUrl) {
            docObj.imagePage.details.detailedImage = fullHtmlImageUrl;
            // HTML 콘텐츠는 비워두기
            docObj.imagePage.details.htmlProductDetailContent = "";
          }
          // 변환된 이미지가 없는 경우에만 첫 번째 상세 이미지 사용
          else if (detailImageUrls && detailImageUrls.length > 0) {
            docObj.imagePage.details.detailedImage = detailImageUrls[0];
            docObj.imagePage.details.htmlProductDetailContent = "";
          }

          // altText 설정 (상품 설명용 대체 텍스트)
          if (!docObj.imagePage.details.altText) {
            docObj.imagePage.details.altText = product.seo && product.seo.metaDescription
              ? product.seo.metaDescription
              : `${updatedProduct.productName} 상세 설명`;
          }
        }
      }

      // 옵션이 있는 경우 각 옵션의 coupangDoc도 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          if (option.coupangDoc && option.coupangDoc.jsonDocument &&
            option.coupangDoc.jsonDocument.length > 0) {
            const optionDocObj = option.coupangDoc.jsonDocument[0];
            if (optionDocObj.imagePage && optionDocObj.imagePage.details) {
              // HTML을 이미지로 변환한 결과를 detailedImage에 설정
              if (fullHtmlImageUrl) {
                optionDocObj.imagePage.details.detailedImage = fullHtmlImageUrl;
                optionDocObj.imagePage.details.htmlProductDetailContent = "";
              }
              // 변환된 이미지가 없는 경우에만 첫 번째 상세 이미지 사용
              else if (detailImageUrls && detailImageUrls.length > 0) {
                optionDocObj.imagePage.details.detailedImage = detailImageUrls[0];
                optionDocObj.imagePage.details.htmlProductDetailContent = "";
              }

              // altText 설정 (상품 설명용 대체 텍스트)
              let altText = product.seo && product.seo.metaDescription
                ? product.seo.metaDescription
                : `${updatedProduct.productName} 상세 설명`;

              // 옵션명이 있으면 altText에 추가
              if (option.korName) {
                altText = `${altText} - ${option.korName}`;
              }

              optionDocObj.imagePage.details.altText = altText;
            }
          }
          option.detailImageStatus.status = 'completed';
        });
      }

      // 10. Vuex 액션으로 상품 업데이트
      await store.dispatch('updateProductDetailImage', updatedProduct);

      console.log('상세 페이지 처리 및 저장 완료');

      // 11. 처리 완료 상태 및 결과 반환
      return {
        status: 'completed',
        type: 'detailImageStatus',
        detailImages: detailImageUrls,
        detailHtml: updatedHtml,
        fullHtmlImageUrl: fullHtmlImageUrl // 새로 추가: HTML을 이미지로 변환한 URL
      };
    } catch (error) {
      console.error('상세 이미지 처리 및 저장 중 오류:', error);
      return {
        status: 'error',
        type: 'detailImageStatus',
        error: error.message
      };
    } finally {
      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: ''
      });
    }
  }
};

export default detailImageService;