import store from '@/store';

/**
 * 가격 설정을 위한 서비스
 */
const pricingService = {
  /**
   * 가격 설정 처리 메인 함수
   * @param {Object} product - 상품 객체
   * @param {Object} data - 요청 데이터
   * @returns {Promise<Object>} 처리 결과
   */
  async process(product, data = {}) {
    try {
      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: '가격 설정 작업 중...'
      });

      if (!product || !product.options || product.options.length === 0) {
        throw new Error('처리할 상품 또는 옵션이 없습니다.');
      }

      let pricingResults = null;

      // 가격 설정 모드에 따라 처리
      if (data.mode === 'manual' && data.manualPrices) {
        // 수동 가격 설정
        pricingResults = this.processManualPricing(product, data.manualPrices);
      } else {
        // 자동 가격 설정 (기본값)
        const pricingInfo = data.pricingInfo || this.getDefaultPricingInfo();
        pricingResults = this.calculatePricing(product, pricingInfo);
      }

      // 결과를 상품 객체에 저장
      const updatedProduct = { ...product };
      updatedProduct.pricing = pricingResults;
      updatedProduct.pricingStatus = 'completed';

      // 옵션별 coupangDoc 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0 &&
        pricingResults.options && pricingResults.options.length > 0) {
        updatedProduct.options.forEach(option => {
          const matchedPricing = pricingResults.options.find(p => p.optionNumber === option.optionNumber);
          if (matchedPricing && option.coupangDoc && option.coupangDoc.jsonDocument && option.coupangDoc.jsonDocument.length > 0) {
            const docObj = option.coupangDoc.jsonDocument[0];
            if (docObj.productPage && docObj.productPage.commonAttributes) {
              // commonAttributes 가격 정보 업데이트
              docObj.productPage.commonAttributes.purchasePrice = Number(Math.round(matchedPricing.supplyPrice));
              docObj.productPage.commonAttributes.coupangSalePrice = Number(Math.round(matchedPricing.sellingPrice));
              // MSRP는 판매가보다 10% 높게 설정 (내림)
              docObj.productPage.commonAttributes.msrp = Number(Math.round((matchedPricing.sellingPrice * 1.1) / 100) * 100);
            }
          }
          option.pricingStatus = 'completed';
        });
      }

      // 단일 상품의 coupangDoc 업데이트
      if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.jsonDocument &&
        updatedProduct.coupangDoc.jsonDocument.length > 0 && pricingResults.options && pricingResults.options.length > 0) {
        const docObj = updatedProduct.coupangDoc.jsonDocument[0];
        const firstPricing = pricingResults.options[0];
        if (docObj.productPage && docObj.productPage.commonAttributes && firstPricing) {
          // commonAttributes 가격 정보 업데이트
          docObj.productPage.commonAttributes.purchasePrice = Number(Math.round(firstPricing.supplyPrice));
          docObj.productPage.commonAttributes.coupangSalePrice = Number(Math.round(firstPricing.sellingPrice));
          // MSRP는 판매가보다 10% 높게 설정 (내림)
          docObj.productPage.commonAttributes.msrp = Number(Math.floor(firstPricing.sellingPrice * 1.1));
        }
      }

      // Vuex 스토어에 상품 상태 업데이트
      await store.dispatch('updateProductPricing', updatedProduct);

      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: ''
      });

      return {
        status: 'completed',
        type: 'pricingStatus',
        result: pricingResults
      };
    } catch (error) {
      console.error('가격 설정 처리 중 오류:', error);

      // 오류 상태로 상품 상태 업데이트
      if (product) {
        const errorProduct = { ...product };
        errorProduct.pricingStatus = 'error';
        store.dispatch('updateProductPricing', errorProduct).catch(err => {
          console.error('상품 상태 업데이트 실패:', err);
        });
      }

      // 로딩 상태 해제
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: ''
      });

      return {
        status: 'error',
        type: 'pricingStatus',
        error: error.message
      };
    }
  },

  /**
   * 기본 가격 정책 정보 가져오기
   * @returns {Object} 기본 가격 정책 정보
   */
  getDefaultPricingInfo() {
    const settings = store.state.settings[0]?.defaultSettings || {};

    return {
      exchangeRate: settings.exchangeRate || 350,
      marginRate: settings.marginRate || 40,
      coupangMarginRate: settings.coupangMarginRate || 40,
      roundUnit: settings.roundUnit || 100,
      enableBundling: settings.enableBundling || false,
      bundlingType: settings.bundlingType || 'marginAmount',
      minMarginAmount: settings.minMarginAmount || 3000,
      minCoupangMarginAmount: settings.minCoupangMarginAmount || 3000
    };
  },

  /**
   * 수동 가격 설정 처리
   * @param {Object} product - 상품 객체
   * @param {Array} manualPrices - 수동 가격 설정 데이터
   * @returns {Object} 처리 결과
   */
  processManualPricing(product, manualPrices) {
    // 기본 가격 정책 정보 가져오기
    const pricingInfo = this.getDefaultPricingInfo();

    // 가격 결과 객체 구성
    const pricingResults = {
      info: pricingInfo,
      options: []
    };

    // 옵션별로 수동 가격 데이터 처리
    if (product.options && product.options.length > 0) {
      product.options.forEach((option, index) => {
        // 해당 옵션의 수동 가격 데이터 찾기
        const manualPrice = manualPrices[index] || {};

        // 옵션 가격 정보 구성
        const optionPrice = {
          optionNumber: option.optionNumber,
          optionName: this.getOptionName(option),
          price: manualPrice.price || option.price || 0,
          stock: manualPrice.stock || option.stock || 0,
          bundleQuantity: manualPrice.bundleQuantity || 1,
          supplyPrice: manualPrice.supplyPrice || 0,
          sellingPrice: manualPrice.sellingPrice || 0
        };

        // 마진 계산
        const purchasePrice = optionPrice.price * pricingInfo.exchangeRate * optionPrice.bundleQuantity;
        optionPrice.purchasePrice = purchasePrice;
        optionPrice.supplyMargin = optionPrice.supplyPrice - purchasePrice;
        optionPrice.sellingMargin = optionPrice.sellingPrice - optionPrice.supplyPrice;

        pricingResults.options.push(optionPrice);
      });
    }

    return pricingResults;
  },

  /**
   * 자동 가격 계산
   * @param {Object} product - 상품 객체
   * @param {Object} pricingInfo - 가격 정책 정보
   * @returns {Object} 계산 결과
   */
  calculatePricing(product, pricingInfo) {
    // 가격 결과 객체 구성
    const pricingResults = {
      info: pricingInfo,
      options: []
    };

    // 옵션이 없으면 빈 결과 반환
    if (!product.options || product.options.length === 0) {
      return pricingResults;
    }

    // 각 옵션의 가격 계산
    product.options.forEach(option => {
      const cnyPrice = option.price || 0;

      // 번들 수량 계산 (번들링 설정이 활성화된 경우)
      let bundleQuantity = 1;
      if (pricingInfo.enableBundling) {
        bundleQuantity = this.calculateBundleQuantity(
          cnyPrice,
          pricingInfo.exchangeRate,
          pricingInfo.marginRate,
          pricingInfo.coupangMarginRate,
          pricingInfo.bundlingType,
          pricingInfo.minMarginAmount,
          pricingInfo.minCoupangMarginAmount
        );
      }

      // 매입가 계산 (CNY × 환율 × 번들수량)
      const purchasePrice = cnyPrice * pricingInfo.exchangeRate * bundleQuantity;

      // 공급가 계산 (매입가 ÷ (1 - 공급마진율/100))
      const marginRateDecimal = pricingInfo.marginRate / 100;
      const rawSupplyPrice = purchasePrice / (1 - marginRateDecimal);
      const supplyPrice = Math.round(rawSupplyPrice / pricingInfo.roundUnit) * pricingInfo.roundUnit;

      // 판매가 계산 (공급가 ÷ (1 - 쿠팡마진율/100))
      const coupangRateDecimal = pricingInfo.coupangMarginRate / 100;
      const rawSellingPrice = supplyPrice / (1 - coupangRateDecimal);
      const sellingPrice = Math.round(rawSellingPrice / pricingInfo.roundUnit) * pricingInfo.roundUnit;

      // 공급 마진 및 쿠팡 마진 계산
      const supplyMargin = supplyPrice - purchasePrice;
      const sellingMargin = sellingPrice - supplyPrice;

      // 결과 저장
      pricingResults.options.push({
        optionNumber: option.optionNumber,
        optionName: this.getOptionName(option),
        price: cnyPrice,
        stock: option.stock || 0,
        bundleQuantity,
        purchasePrice,
        supplyPrice,
        sellingPrice,
        supplyMargin,
        sellingMargin
      });
    });

    return pricingResults;
  },

  /**
   * 번들 수량 계산
   * @param {number} cnyPrice - 위안화 가격
   * @param {number} exchangeRate - 환율
   * @param {number} marginRate - 마진율
   * @param {number} coupangMarginRate - 쿠팡 마진율
   * @param {string} bundlingType - 번들링 타입
   * @param {number} minMarginAmount - 최소 마진 금액
   * @param {number} minCoupangMarginAmount - 최소 쿠팡 마진 금액
   * @returns {number} 계산된 번들 수량
   */
  calculateBundleQuantity(cnyPrice, exchangeRate, marginRate, coupangMarginRate, bundlingType, minMarginAmount, minCoupangMarginAmount) {
    // 단일 상품으로 계산한 가격
    const purchasePrice = cnyPrice * exchangeRate;
    const marginRateDecimal = marginRate / 100;
    const coupangRateDecimal = coupangMarginRate / 100;

    const rawSupplyPrice = purchasePrice / (1 - marginRateDecimal);
    const supplyPrice = Math.round(rawSupplyPrice / 100) * 100;

    const rawSellingPrice = supplyPrice / (1 - coupangRateDecimal);
    const sellingPrice = Math.round(rawSellingPrice / 100) * 100;

    // 마진 계산
    const supplyMargin = supplyPrice - purchasePrice;
    const sellingMargin = sellingPrice - supplyPrice;

    // 번들링 기준에 따른 조건 확인
    let bundleQuantity = 1;

    if (bundlingType === 'marginAmount') {
      // 공급 마진액 기준
      if (supplyMargin < minMarginAmount) {
        // 2개, 4개, 6개... 단위로 번들링
        while (supplyMargin * bundleQuantity < minMarginAmount && bundleQuantity < 20) {
          bundleQuantity += 2;
        }
      }
    } else { // 'coupangMarginAmount'
      // 쿠팡 마진액 기준
      if (sellingMargin < minCoupangMarginAmount) {
        // 2개, 4개, 6개... 단위로 번들링
        while (sellingMargin * bundleQuantity < minCoupangMarginAmount && bundleQuantity < 20) {
          bundleQuantity += 2;
        }
      }
    }

    return bundleQuantity;
  },

  /**
   * 공급가 계산
   * @param {number} cnyPrice - 위안화 가격
   * @param {Object} pricingInfo - 가격 정책 정보
   * @returns {number} 계산된 공급가
   */
  calculateSupplyPrice(cnyPrice, pricingInfo) {
    if (!cnyPrice) return 0;

    // 매입가 = CNY × 환율
    const purchasePrice = cnyPrice * pricingInfo.exchangeRate;

    // 공급가 = 매입가 ÷ (1 - 공급마진율/100)
    const marginRate = pricingInfo.marginRate / 100;
    const rawPrice = purchasePrice / (1 - marginRate);

    return Math.round(rawPrice / pricingInfo.roundUnit) * pricingInfo.roundUnit;
  },

  /**
   * 판매가 계산
   * @param {number} supplyPrice - 공급가
   * @param {Object} pricingInfo - 가격 정책 정보
   * @returns {number} 계산된 판매가
   */
  calculateSellingPrice(supplyPrice, pricingInfo) {
    if (!supplyPrice) return 0;

    // 판매가 = 공급가 ÷ (1 - 쿠팡마진율/100)
    const coupangRate = pricingInfo.coupangMarginRate / 100;
    const rawPrice = supplyPrice / (1 - coupangRate);

    return Math.round(rawPrice / pricingInfo.roundUnit) * pricingInfo.roundUnit;
  },

  /**
   * 옵션명 가져오기
   * @param {Object} option - 옵션 객체
   * @returns {string} 옵션명
   */
  getOptionName(option) {
    if (!option) return '기본 옵션';

    let name = '';
    if (option.option1) {
      name += option.option1.nameTrans || option.option1.name || '';
    }
    if (option.option2) {
      name += ' / ' + (option.option2.nameTrans || option.option2.name || '');
    }
    return name || '기본 옵션';
  },

  /**
   * 가격 정보를 포매팅하여 표시
   * @param {number} value - 가격 값
   * @returns {string} 포매팅된 가격
   */
  formatPrice(value) {
    return new Intl.NumberFormat('ko-KR').format(value || 0);
  },

  /**
   * 마진율 계산
   * @param {number} cost - 원가
   * @param {number} price - 판매가
   * @returns {number} 마진율 (%)
   */
  calculateMarginRate(cost, price) {
    if (!cost || !price || cost >= price) return 0;
    return Math.round(((price - cost) / price) * 100);
  },

  /**
   * 가격 설정 결과 처리
   * @param {Object} product - 상품 객체
   * @param {Object} result - 가격 설정 결과
   * @returns {Promise<boolean>} 처리 성공 여부
   */
  // pricingService.js의 handleResult 메서드 수정
  async handleResult(product, result) {
    try {
      if (!product || !result) return false;

      // 결과 데이터 확인 및 변환
      const pricingData = result.pricing || result;

      // 상품 데이터 업데이트
      const updatedProduct = { ...product };
      updatedProduct.pricing = pricingData;
      updatedProduct.pricingStatus = 'completed';

      // 옵션 데이터도 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0 &&
        pricingData.options && pricingData.options.length > 0) {

        // 옵션 번호 기반으로 매칭하여 업데이트
        updatedProduct.options.forEach(option => {
          const matchedPricing = pricingData.options.find(p => p.optionNumber === option.optionNumber);
          if (matchedPricing) {
            // 옵션의 가격 정보 업데이트
            option.price = matchedPricing.price;
            option.bundleQuantity = matchedPricing.bundleQuantity;
            option.supplyPrice = matchedPricing.supplyPrice;
            option.sellingPrice = matchedPricing.sellingPrice;
            option.purchasePrice = matchedPricing.purchasePrice;
            option.supplyMargin = matchedPricing.supplyMargin;
            option.sellingMargin = matchedPricing.sellingMargin;

            // coupangDoc 업데이트
            if (option.coupangDoc && option.coupangDoc.jsonDocument && option.coupangDoc.jsonDocument.length > 0) {
              const docObj = option.coupangDoc.jsonDocument[0];
              if (docObj.productPage && docObj.productPage.commonAttributes) {
                // commonAttributes 가격 정보 업데이트
                docObj.productPage.commonAttributes.purchasePrice = Number(Math.round(matchedPricing.supplyPrice));
                docObj.productPage.commonAttributes.coupangSalePrice = Number(Math.round(matchedPricing.sellingPrice));
                // MSRP는 판매가보다 10% 높게 설정 (내림)
                docObj.productPage.commonAttributes.msrp = Number(Math.floor(matchedPricing.sellingPrice * 1.1));
              }
            }
          }
          option.pricingStatus = 'completed';
        });
      }

      // 단일 상품의 coupangDoc 업데이트
      if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.jsonDocument &&
        updatedProduct.coupangDoc.jsonDocument.length > 0 && pricingData.options && pricingData.options.length > 0) {
        const docObj = updatedProduct.coupangDoc.jsonDocument[0];
        // 첫 번째 옵션의 가격 정보 사용
        const firstPricing = pricingData.options[0];
        if (docObj.productPage && docObj.productPage.commonAttributes && firstPricing) {
          // commonAttributes 가격 정보 업데이트
          docObj.productPage.commonAttributes.purchasePrice = Number(Math.round(firstPricing.supplyPrice));
          docObj.productPage.commonAttributes.coupangSalePrice = Number(Math.round(firstPricing.sellingPrice));
          // MSRP는 판매가보다 10% 높게 설정 (내림)
          docObj.productPage.commonAttributes.msrp = Number(Math.floor(firstPricing.sellingPrice * 1.1));
        }
      }

      // Vuex 스토어 업데이트 - 여기를 변경 (updateRocketProduct에서 updateProductPricing으로)
      await store.dispatch('updateProductPricing', updatedProduct);

      return true;
    } catch (error) {
      console.error('가격 설정 결과 처리 중 오류:', error);
      return false;
    }
  }
};

export default pricingService;