import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
// import createPersistedState from 'vuex-persistedstate';

// 서비스 레이어 import 추가
import imageService from '../services/imageService';
import mainImageService from '../services/works/mainImageService';
import additionalImageService from '../services/works/additionalImageService';
import detailImageService from '../services/works/detailImageService';
import koreanLabelService from '../services/works/koreanLabelService';
import pricingService from '../services/works/pricingService';
import seoService from '../services/works/seoService';
import quotationService from '../services/works/quotationService';
import workflowService from '../services/workflowService';
import sizeChartService from '../services/works/sizeChartService';

Vue.use(Vuex)

function updateOptionImageState(option, imageData) {
  console.log('update할 option :', option, imageData);
  if (!option.mainImageStatus) {
    // 이미지 상태가 없으면 새로 생성
    return {
      ...option,
      mainImageStatus: {
        status: 'completed',
        image: imageData.originalUrl || '',
        processedImage: imageData.processedUrl || '',
        dimensions: imageData.dimensions || '800x800',
        filesize: imageData.filesize || '100KB',
        textRemoved: imageData.textRemoved || false
      }
    };
  } else {
    // 기존 이미지 상태 업데이트
    return {
      ...option,
      mainImageStatus: {
        ...option.mainImageStatus,
        status: 'completed',
        image: imageData.originalUrl || option.mainImageStatus.image,
        processedImage: imageData.processedUrl || option.mainImageStatus.processedImage,
        dimensions: imageData.dimensions || option.mainImageStatus.dimensions,
        filesize: imageData.filesize || option.mainImageStatus.filesize,
        textRemoved: imageData.textRemoved !== undefined ? imageData.textRemoved : option.mainImageStatus.textRemoved
      }
    };
  }
}



export default new Vuex.Store({
  state: {
    appName: 'COUPLUS',
    logo: require('../assets/images/logo.png'),
    darklogo: require('../assets/images/logo.png'),
    dark: false,
    namespaced: true,
    user: {
      regdate: '',
      name: '',
      _id: '',
      companyid: '',
      email: '',
      phone: '',
      suppliercode: '',
      accesshistory: '',
      auth: {},
      grade: '',
      expireDate: '',
      paydate: '',
      nextpayment: '',
      addAccount: '',
      version: '',
    },
    userinit: {
      name: '',
      _id: '',
      companyid: '',
      email: '',
      phone: '',
      suppliercode: '',
      accesshistory: '',
      auth: {},
      regdate: '',
      grade: '',
      expireDate: '',
      paydate: '',
      nextpayment: '',
      addAccount: '',
      version: '',
    },
    polist: [],
    exportlist: [],
    productdb: [],
    purchase: [],
    matchpo: [],
    returns: [],
    sales: [],
    supply: [],
    stock: [],
    settings: [],
    invoice: [],
    reqpurchase: [],
    growthdb: [],
    growthSales: [],
    TS: [],
    TF: [],
    TF2: [],
    saveitem: [],
    saveItemStatus: false,
    keywordList: [],
    trafficList: [],
    adSourcingList: [],
    rocketProducts: [], // 로켓배송 상품 목록
    selectedRocketProduct: null, // 선택된 로켓배송 상품
    selectedOption: null, // 선택된 옵션
    selectedWorkType: "", // 선택된 작업 유형
    workingImageData: null, // 작업 중인 이미지 데이터
    processing: false, // 처리 중 상태
    progressMessage: '', // 진행 메시지
    workflowStatus: {
      currentTask: null, // 현재 작업
      completedTasks: [], // 완료된 작업
      progress: 0, // 진행률 (0-100)
    },
    waitingDetailHtml: false,
    detailHtmlResults: {}
  },
  mutations: {
    resetState(state) {
      // 기본 state 객체의 깊은 복사본을 만들어서 현재 state를 덮어씁니다
      const defaultState = {
        appName: 'COUPLUS',
        logo: require('../assets/images/logo.png'),
        darklogo: require('../assets/images/logo.png'),
        dark: false,
        namespaced: true,
        user: {
          regdate: '',
          name: '',
          _id: '',
          companyid: '',
          email: '',
          phone: '',
          suppliercode: '',
          accesshistory: '',
          auth: {},
          grade: '',
          expireDate: '',
          paydate: '',
          nextpayment: '',
          addAccount: '',
          version: '',
        },
        userinit: {
          name: '',
          _id: '',
          companyid: '',
          email: '',
          phone: '',
          suppliercode: '',
          accesshistory: '',
          auth: {},
          regdate: '',
          grade: '',
          expireDate: '',
          paydate: '',
          nextpayment: '',
          addAccount: '',
          version: '',
        },
        polist: [],
        exportlist: [],
        productdb: [],
        purchase: [],
        matchpo: [],
        returns: [],
        sales: [],
        supply: [],
        stock: [],
        settings: [],
        invoice: [],
        reqpurchase: [],
        growthdb: [],
        growthSales: [],
        TS: [],
        TF: [],
        TF2: [],
        saveitem: [],
        saveItemStatus: false,
        keywordList: [],
        trafficList: [],
        adSourcingList: [],
      };

      Object.keys(defaultState).forEach(key => {
        state[key] = JSON.parse(JSON.stringify(defaultState[key]));
      });
    },
    userupdate(state, payload) {
      function deepClone(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj
        }

        const result = Array.isArray(obj) ? [] : {}

        for (let key of Object.keys(obj)) {
          result[key] = deepClone(obj[key])
        }

        return result
      }
      state.user.regdate = payload.regdate;
      state.user.name = payload.name;
      state.user.accesshistory = payload.accesshistory;
      state.user._id = payload._id;
      state.user.companyid = payload.companyid;
      state.user.email = payload.email;
      state.user.phone = payload.phone;
      state.user.suppliercode = payload.suppliercode;
      state.user.grade = payload.grade;
      state.user.auth = deepClone(payload.auth);
      state.user.addAccount = payload.addAccount;
      state.user.expireDate = payload.expireDate;
      state.user.paydate = payload.paydate;
      state.user.nextpayment = payload.nextpayment;
      state.user.expireDate = payload.expireDate;
      state.user.version = payload.version;
    },
    userpaymentstatusupdate(state, payload) {
      state.user.nextpayment = payload.nextpayment;
      // state.user.paydate = payload.paydate;
    },
    userauthupdate(state, payload) {
      function deepClone(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj
        }

        const result = Array.isArray(obj) ? [] : {}

        for (let key of Object.keys(obj)) {
          result[key] = deepClone(obj[key])
        }

        return result
      }
      state.user.auth = deepClone(payload.auth);
    },
    dbupdate(state, payload) {
      console.log('store update')
      // if (payload.polist.length != 0){
      //   var temp = payload.polist;
      //   var arrangecnt = 0;
      //   var notarrangecnt = 0;
      //   var pstockcnt = 0;
      //   var rstockcnt = 0;
      //   var tempstock = payload.stock;

      //   temp.forEach(e => {
      //     e.발주세부내역.forEach(ele => {
      //       arrangecnt = 0;
      //       notarrangecnt = 0;
      //       pstockcnt = 0;
      //       rstockcnt = 0;
      //       if (payload.stock.length != 0){
      //         tempstock.forEach(element => {
      //           if(element.바코드 == ele.바코드){
      //             if(element.배치발주번호 == e.발주번호){
      //               arrangecnt++
      //             } else {
      //               if(element.배치발주번호 == ''){
      //                 if(element.Stockinfo == 'stock') {
      //                   notarrangecnt++
      //                 } else {
      //                   if(element.Stockinfo == 'Pstock') {
      //                     pstockcnt++
      //                   } else {
      //                     if(element.Stockinfo == 'Rstock') {
      //                       rstockcnt++
      //                     }
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         })
      //       }
      //       payload.productdb.some(el => {
      //         if(el.바코드 == ele.바코드) {
      //           if(el.구매링크 == '사입'){
      //             ele.사입재고수량 = ele.발주수량;
      //           } else {
      //             ele.사입재고수량 = 0;
      //           }
      //           return (el.바코드 == ele.바코드)
      //         }
      //       })


      //       ele.발주수량 = parseInt(ele.발주수량);
      //       ele.입고수량 = parseInt(ele.입고수량);
      //       ele.확정수량 = parseInt(ele.확정수량);
      //       ele.배치수량 = arrangecnt;
      //       ele.필요수량 = parseInt(ele.확정수량) - arrangecnt;
      //       ele.미배치재고수량 = notarrangecnt;
      //       ele.구매완료수량 = pstockcnt;
      //       ele.회송예정수량 = rstockcnt;

      //     })
      //   });

      //   state.polist = temp;
      // } else {
      //   state.polist = payload.polist;
      // }
      state.polist = payload.polist;
      state.productdb = payload.productdb;
      state.purchase = payload.purchase;
      state.returns = payload.returns;
      state.exportlist = payload.exportlist;
      state.stock = payload.stock;
      state.settings = payload.settings;
      state.invoice = payload.invoice;
      state.reqpurchase = payload.reqpurchase;
      console.log('success DbUpdate');
    },
    initDb(state) {
      state.polist = [];
      state.productdb = [];
      state.purchase = [];
      state.returns = [];
      state.exportlist = [];
      state.stock = [];
      state.settings = [];
      state.invoice = [];
      state.reqpurchase = [];
      console.log('@@@init Db success', state.polist, state.productdb, state.purchase, state.returns, state.exportlist, state.stock, state.settings, state.invoice, state.reqpurchase);
    },
    growthSalesUpdate(state, payload) {
      state.growthSales = payload
    },
    growthdbupdate(state, payload) {
      // payload로 받은 데이터 가공
      const processedData = payload.map(item => {
        // 구매정보가 없는 경우 기본 구매정보 생성
        if (!item.구매정보 || item.구매정보.length == 0) {
          item.구매정보 = [{
            checked: false,
            showspinner: false,
            바코드: item.barcode || item.바코드,
            상품명: item.productName || item.상품명,
            상품명init: item.productName || item.상품명init,
            구매링크: undefined,
            구매링크init: undefined,
            옵션1_중국어: undefined,
            옵션2_중국어: undefined,
            옵션이미지: undefined,
            매칭상태: '매칭필요',
            상품매칭: {
              옵션1_중국어: [],
              옵션2_중국어: [],
            },
            구매재고: '',
            구매요청수량: '',
            판매구성수량: 1,
            묶음구성여부: false,
            묶음구성수량: '',
            묶음대상바코드: '',
            신고영문명: '',
            신고가격: '',
            HSCODE: '',
            진행현황: '구매전',
            재고동봉: true,
            등록이미지: item.optionImageUrl || item.등록이미지,
            요청사항: '요청등록',
            reqtype: '제트배송',
            sizeX: item.sizeInfo ? item.sizeInfo.width : 0,
            sizeY: item.sizeInfo ? item.sizeInfo.length : 0,
            sizeZ: item.sizeInfo ? item.sizeInfo.height : 0,
            weight: item.sizeInfo ? item.sizeInfo.weight : 0,
            요청내역: {
              default: true,
              poarrange: false,
              한글표시사항: true,
              barcode: true,
              sticker: false,
              sewing: false,
              stamp: false,
              tag: false,
              reqcontent: '',
              opp: false,
              LDPE: false,
              airpacking: false,
              exportType: 'box',
              destination: 'coupang',
              masking: false,
              auto: true,
            },
            한글표시사항: {
              제품명: item.productName || item.상품명,
              '수입원/판매원': '',
              제조원: '',
              제조국: '',
              내용량: '',
              '원료명및성분명(재질)': '',
              상품유형: '',
              사용시주의사항: '',
              사용기준: '',
              custom: '',
            },
            polists: [],
            selectpo: '',
          }];
          item.구매정보여부 = false;
        }

        // 상품명 설정
        item.상품명 = item.skuName;

        // 매입가 계산
        if (item.매입정보 && item.매입정보.length > 0) {
          item.매입가 = parseInt(item.매입정보[item.매입정보.length - 1].매입가);
          if (item.returnProducts && item.returnProducts.length > 0) {
            item.returnProducts.forEach(a => {
              a.매입가 = parseInt(item.매입정보[item.매입정보.length - 1].매입가);
            });
          }
        } else {
          item.매입가 = "";
          if (item.returnProducts && item.returnProducts.length > 0) {
            item.returnProducts.forEach(a => {
              a.매입가 = "";
            });
          }
        }

        // 마진 계산
        if (item.매입가) {
          item.마진 = Math.round((item.salePrice - item.coupon - item.commission * 1.1 - item.매입가) * 10 / 11);
          item.마진율 = Math.round(item.마진 / (item.salePrice - item.coupon) * 100) / 100;
          item.ROI = Math.round(item.마진 / (item.매입가 * 10 / 11) * 100) / 100;
          item.ROAS = Math.round((item.salePrice - item.coupon) / item.마진 * 110) / 100;
        }

        if (item.returnProducts && item.returnProducts.length > 0) {
          item.returnProducts.forEach(a => {
            if (item.매입가) {
              a.마진 = Math.round((a.salePrice - a.coupon - a.commission * 1.1 - a.매입가) * 10 / 11);
              a.마진율 = Math.round(a.마진 / (a.salePrice - a.coupon) * 100) / 100;
              a.ROI = Math.round(a.마진 / (a.매입가 * 10 / 11) * 100) / 100;
              a.ROAS = Math.round((a.salePrice - a.coupon) / a.마진 * 110) / 100;
            }
          });
        }

        return item;
      });

      // 정렬
      processedData.sort((a, b) => b.gmvForLast30Days - a.gmvForLast30Days);

      // 처리된 데이터를 state에 저장
      state.growthdb = processedData;
    },
    growthdbupdateOne(state, payload) {
      for (let i = 0; i < state.growthdb.length; i++) {
        if (state.growthdb[i].barcode === payload.barcode) {
          // 방법 1: Object.assign 사용
          Object.assign(state.growthdb[i], payload);

          // 방법 2: Vue.set()을 사용 (Vue3 이전에서는 Vue.set을 사용해야 반응성 유지 가능)
          // Vue.set(state.growthdb, i, payload);

          console.log(state.growthdb[i]);
          console.log(payload);
          return;
        }
      }
    },
    getAdSourcingListUpdate(state, payload) {
      state.adSourcingList = payload
    },
    reqpurchaseupdate(state, payload) {
      state.reqpurchase = payload;
    },
    productdbupdate(state, payload) {
      state.productdb = payload;
    },
    invoiceupdate(state, payload) {
      state.invoice = payload;
    },
    settingsupdate(state, payload) {
      state.settings = payload;
    },
    settingsupdateparameter(state, payload) {
      state.settings[payload.key] = payload.value;
    },
    stockinsert(state, payload) {
      state.stock.push(payload)
    },
    stockdelete(state, payload) {
      //payload : arr, remove target objects in array, including "_id"

      payload.forEach(e => {
        state.stock = state.stock.filter(ele => ele._id != e._id)
      })

    },
    exportlistupdate(state, payload) {
      state.exportlist = payload
    },
    purchaseupdate(state, payload) {
      state.purchase = payload
    },
    updatepurchaseitem(state, payload) {
      state.purchase.find(e => e._id == payload._id).주문수량 = payload.주문수량;
      state.purchase.find(e => e._id == payload._id).운송장번호 = payload.운송장번호;
    },
    updateboxqty(state, payload) {
      state.productdb.find(e => e.바코드 == payload.바코드)['1박스인입수량'] = payload['1박스인입수량'];
    },
    updateleadtime(state, payload) {
      state.productdb.find(e => e.바코드 == payload.바코드)['입고리드타임'] = payload['입고리드타임'];
    },
    deletepurchase(state, payload) {
      payload.forEach(e => {
        state.purchase = deleteindexfromarray(state.purchase, state.purchase.findIndex(ele => equal(ele, e)));
      })

      function deleteindexfromarray(array, index) {
        return array.slice(0, index).concat(array.slice(index + 1))
      }

      function equal(k1, k2) {
        JSON.stringify(k1) === JSON.stringify(k2)
      }

    },
    stockupdate(state, payload) {
      state.stock = payload
    },
    salesupdate(state, payload) {
      state.sales = payload
    },
    supplyupdate(state, payload) {
      state.supply = payload
    },
    layoutModeCommit(state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
      } else {
        state.logo = require('../assets/images/logo.png')
      }
    },
    updateTS(state, payload) {
      state.TS = payload;
    },
    updateTF(state, payload) {
      state.TF = payload;
    },
    updateTF2(state, payload) {
      state.TF2 = payload;
    },
    updateSaveItem(state, payload) {
      state.saveitem = payload;
    },
    updateSaveItemStatus(state, payload) {
      state.saveItemStatus = payload;
    },
    setKeywordList(state, payload) {
      state.keywordList = payload;
    },
    updateKeywordList(state, payload) {
      if (payload.arr.length == 0) {
        return
      }
      if (payload.keyword == 'Default') {
        state.keywordList = state.keywordList.filter(e => e.keywordGroup)
        state.keywordList = state.keywordList.concat(payload.arr)
      } else {
        state.keywordList = state.keywordList.filter(e => e.keywordGroup !== payload.keyword)
        state.keywordList = state.keywordList.concat(payload.arr)
        console.log(state.keywordList)
      }
    },
    updateAllKeywordList(state, payload) {
      state.keywordList = payload;
    },
    updateTrafficList(state, payload) {
      state.trafficList = payload;
    },
    // 로켓배송 관련 mutations
    SET_ROCKET_PRODUCTS(state, products) {
      state.rocketProducts = products;
    },

    SET_SELECTED_ROCKET_PRODUCT(state, product) {
      state.selectedRocketProduct = product;
    },

    SET_SELECTED_OPTION(state, option) {
      state.selectedOption = option;
    },

    SET_SELECTED_WORK_TYPE(state, workType) {
      state.selectedWorkType = workType;
    },

    UPDATE_ROCKET_PRODUCT(state, updatedProduct) {
      const index = state.rocketProducts.findIndex(p => p.registrationId === updatedProduct.registrationId);
      if (index !== -1) {
        // 원본 이미지 필드 보존 (추가나 업데이트되지 않았을 경우)
        if (!updatedProduct.originDetailImages && state.rocketProducts[index].originDetailImages) {
          updatedProduct.originDetailImages = state.rocketProducts[index].originDetailImages;
        }

        // Vue의 반응성을 위해 배열 변이 메소드 사용
        Vue.set(state.rocketProducts, index, updatedProduct);

        // 선택된 상품이 업데이트된 상품이면 선택된 상품도 업데이트
        if (state.selectedRocketProduct && state.selectedRocketProduct.registrationId === updatedProduct.registrationId) {
          state.selectedRocketProduct = updatedProduct;
        }
      }
    },
    // index.js - UPDATE_OPTION_IMAGE 뮤테이션 수정

    UPDATE_OPTION_IMAGE(state, { optionNumber, imageData }) {
      console.log(`UPDATE_OPTION_IMAGE - 옵션 ${optionNumber} - 이미지 데이터:`, imageData.processedUrl);
      // 모든 상품의 옵션을 순회하면서 해당 옵션 찾기
      for (let i = 0; i < state.rocketProducts.length; i++) {
        const product = state.rocketProducts[i];
        if (!product.options) continue;

        const optionIndex = product.options.findIndex(opt => opt.optionNumber === optionNumber);
        if (optionIndex !== -1) {
          // updateOptionImageState 함수를 사용하여 옵션 업데이트 - 여기가 수정된 부분
          const updatedOption = updateOptionImageState(product.options[optionIndex], imageData);
          console.log('옵션 이미지 업데이트:', updatedOption);
          // Vue의 반응성을 위해 Vue.set 사용
          Vue.set(product.options, optionIndex, updatedOption);

          // 상품 상태도 업데이트
          if (product.mainImageStatus === 'pending') {
            product.mainImageStatus = 'partially_completed';
          } else if (product.options.every(opt =>
            opt.mainImageStatus && opt.mainImageStatus.status === 'completed')) {
            product.mainImageStatus = 'completed';
          }

          console.log('옵션 이미지 업데이트 완료:', optionNumber, updatedOption.mainImageStatus);

          // 선택된 옵션이 업데이트된 옵션이면 선택된 옵션도 업데이트
          if (state.selectedOption && state.selectedOption.optionNumber === optionNumber) {
            state.selectedOption = updatedOption;
          }

          break;
        }
      }
    },

    // 작업 상태 관련 mutations
    SET_PROCESSING(state, isProcessing) {
      state.processing = isProcessing;
    },

    SET_PROGRESS_MESSAGE(state, message) {
      state.progressMessage = message;
    },

    SET_WORKING_IMAGE_DATA(state, imageData) {
      state.workingImageData = imageData;
    },

    CLEAR_WORKING_IMAGE_DATA(state) {
      state.workingImageData = null;
    },

    // 작업 흐름 관련 mutations
    SET_CURRENT_TASK(state, task) {
      state.workflowStatus.currentTask = task;
    },

    ADD_COMPLETED_TASK(state, task) {
      if (!state.workflowStatus.completedTasks.includes(task)) {
        state.workflowStatus.completedTasks.push(task);
      }
    },

    SET_WORKFLOW_PROGRESS(state, progress) {
      state.workflowStatus.progress = Math.min(100, Math.max(0, progress));
    },

    // 작업 결과 업데이트 mutations
    UPDATE_MAIN_IMAGE_RESULT(state, { productId, optionNumber, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product || !product.options) return;

      const option = product.options.find(o => o.optionNumber === optionNumber);
      if (!option) return;

      // mainImageStatus 업데이트
      option.mainImageStatus = {
        ...option.mainImageStatus,
        status: 'completed',
        processedImage: result.processedUrl,
        textRemoved: result.textRemoved,
        dimensions: result.dimensions,
        filesize: result.filesize
      };

      // 상품 상태 업데이트 트리거
      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_ADDITIONAL_IMAGE_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.additionalImages = result.images;
      product.additionalImageStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_DETAIL_IMAGE_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.detailImages = result.images;
      product.detailHtmlContents = result.htmlContents;
      product.detailImageStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_KOREAN_LABEL_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.koreanLabels = result.labels;
      product.koreanLabelStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_PRICING_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.pricing = result;
      product.pricingStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_SEO_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.seo = result;
      product.seoStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },

    UPDATE_QUOTATION_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.quotation = result;
      product.quotationStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },
    SET_WAITING_DETAIL_HTML(state, isWaiting) {
      state.waitingDetailHtml = isWaiting;
    },

    ADD_DETAIL_HTML_RESULT(state, { imageUrl, htmlContent }) {
      Vue.set(state.detailHtmlResults, imageUrl, htmlContent);
    },
    SET_KOREAN_LABEL_DATA(state, { productId, aiLabels, koreanLabelImage }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (product) {
        product.koreanLabels = { ...product.koreanLabels, ...aiLabels };
        product.koreanLabelImage = koreanLabelImage;
      }
    },
    UPDATE_SIZE_CHART_RESULT(state, { productId, result }) {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return;

      product.sizeChart = result.sizeChart;
      product.sizeChartImage = result.sizeChartImage;
      product.sizeChartStatus = 'completed';

      this.commit('UPDATE_ROCKET_PRODUCT', product);
    },
    // 기능별 상품 정보 업데이트 뮤테이션
    UPDATE_PRODUCT_FEATURE(state, { feature, productData }) {
      // 상품 찾기
      const index = state.rocketProducts.findIndex(p => p.registrationId === productData.registrationId);
      if (index === -1) {
        console.warn('업데이트할 상품을 찾을 수 없음:', productData.registrationId);
        return;
      }

      // 현재 상품 가져오기 (깊은 복사)
      const product = JSON.parse(JSON.stringify(state.rocketProducts[index]));

      // 상태 필드 이름 (feature + 'Status')
      const statusField = `${feature}Status`;

      // 상태 필드 업데이트 - 객체인 경우와 문자열인 경우를 구분하여 처리
      if (typeof product[statusField] === 'object') {
        // 객체인 경우 (mainImageStatus 등), status 속성 업데이트
        if (!product[statusField]) product[statusField] = {};
        product[statusField].status = productData[statusField]?.status || 'completed';
      } else {
        // 문자열인 경우 (seoStatus 등)
        product[statusField] = productData[statusField] || 'completed';
      }

      // 기능별 데이터 업데이트
      switch (feature) {
        case 'seo':
          console.log('SEO 데이터 업데이트 전:', {
            상태: product.seoStatus,
            데이터: product.seo ? Object.keys(product.seo) : null
          });

          product.seo = productData.seo;

          console.log('SEO 데이터 업데이트 후:', {
            상태: product.seoStatus,
            데이터: product.seo ? Object.keys(product.seo) : null
          });
          break;

        case 'pricing':
          console.log('가격 데이터 업데이트 전:', {
            상태: product.pricingStatus,
            데이터: product.pricing ? '데이터 있음' : '데이터 없음'
          });

          product.pricing = productData.pricing;

          console.log('가격 데이터 업데이트 후:', {
            상태: product.pricingStatus,
            데이터: product.pricing ? '데이터 있음' : '데이터 없음'
          });
          break;

        case 'mainImage':
          console.log('메인 이미지 데이터 업데이트 전:', {
            상태: product.mainImageStatus ? product.mainImageStatus.status : '없음',
            이미지: product.mainImageStatus ? (product.mainImageStatus.processedImage ? '있음' : '없음') : '없음'
          });

          product.mainImageStatus = productData.mainImageStatus;

          console.log('메인 이미지 데이터 업데이트 후:', {
            상태: product.mainImageStatus ? product.mainImageStatus.status : '없음',
            이미지: product.mainImageStatus ? (product.mainImageStatus.processedImage ? '있음' : '없음') : '없음'
          });
          break;

        case 'additionalImage':
          console.log('추가 이미지 데이터 업데이트 전:', {
            상태: product.additionalImageStatus,
            이미지: product.additionalImages ? product.additionalImages.length : 0
          });

          product.additionalImageStatus = productData.additionalImageStatus;
          product.additionalImages = productData.additionalImages;

          console.log('추가 이미지 데이터 업데이트 후:', {
            상태: product.additionalImageStatus,
            이미지: product.additionalImages ? product.additionalImages.length : 0
          });
          break;

        case 'detailImage':
          console.log('상세 이미지 데이터 업데이트 전:', {
            상태: product.detailImageStatus,
            이미지: product.detailImages ? product.detailImages.length : 0,
            HTML: product.detailHtml ? '있음' : '없음'
          });

          product.detailImageStatus = productData.detailImageStatus;
          product.detailImages = productData.detailImages;
          product.detailHtml = productData.detailHtml;

          console.log('상세 이미지 데이터 업데이트 후:', {
            상태: product.detailImageStatus,
            이미지: product.detailImages ? product.detailImages.length : 0,
            HTML: product.detailHtml ? '있음' : '없음'
          });
          break;
          
        case 'sizeChart':
          console.log('사이즈표 데이터 업데이트 전:', {
            상태: product.sizeChartStatus,
            데이터: product.sizeChart ? '데이터 있음' : '데이터 없음'
          });

          product.sizeChartStatus = productData.sizeChartStatus;
          product.sizeChart = productData.sizeChart;
          product.sizeChartImage = productData.sizeChartImage;

          console.log('사이즈표 데이터 업데이트 후:', {
            상태: product.sizeChartStatus,
            데이터: product.sizeChart ? '데이터 있음' : '데이터 없음'
          });
          break;

        case 'koreanLabel':
          console.log('UPDATE_PRODUCT_FEATURE - koreanLabel 기존 데이터:', {
            기존상태: product.koreanLabelStatus,
            기존라벨: product.koreanLabels,
            기존이미지: product.koreanLabelImage
          });

          product.koreanLabelStatus = productData.koreanLabelStatus;
          product.koreanLabels = productData.koreanLabels;
          product.koreanLabelImage = productData.koreanLabelImage;

          console.log('UPDATE_PRODUCT_FEATURE - koreanLabel 업데이트 후:', {
            상태: product.koreanLabelStatus,
            라벨: product.koreanLabels,
            이미지: product.koreanLabelImage
          });
          break;

        case 'quotation':
          console.log('견적서 데이터 업데이트 전:', {
            상태: product.quotationStatus,
            견적서: product.quotation ? '데이터 있음' : '데이터 없음'
          });

          product.quotationStatus = productData.quotationStatus;
          product.quotation = productData.quotation;

          console.log('견적서 데이터 업데이트 후:', {
            상태: product.quotationStatus,
            견적서: product.quotation ? '데이터 있음' : '데이터 없음'
          });
          break;
      }

      // 옵션 업데이트 (기능과 관련된 옵션 필드만)
      if (productData.options && productData.options.length > 0 && product.options) {
        productData.options.forEach(newOption => {
          const optionIndex = product.options.findIndex(o => o.optionNumber === newOption.optionNumber);
          if (optionIndex !== -1) {
            // 옵션 상태 필드 이름
            const optionStatusField = `${feature}Status`;

            // 상태 업데이트 - 객체와 문자열 구분
            if (typeof product.options[optionIndex][optionStatusField] === 'object') {
              // 객체인 경우 (mainImageStatus 등), status 속성 업데이트
              if (!product.options[optionIndex][optionStatusField]) {
                product.options[optionIndex][optionStatusField] = {};
              }

              if (newOption[optionStatusField]) {
                if (typeof newOption[optionStatusField] === 'object') {
                  product.options[optionIndex][optionStatusField].status =
                    newOption[optionStatusField].status || 'completed';
                } else {
                  product.options[optionIndex][optionStatusField].status =
                    newOption[optionStatusField] || 'completed';
                }
              } else {
                product.options[optionIndex][optionStatusField].status = 'completed';
              }
            } else {
              // 문자열인 경우 (seoStatus 등)
              product.options[optionIndex][optionStatusField] =
                newOption[optionStatusField] || 'completed';
            }

            // 기능별 필드 업데이트
            switch (feature) {
              case 'seo':
                if (newOption.korName) product.options[optionIndex].korName = newOption.korName;
                if (newOption.fullProductName) product.options[optionIndex].fullProductName = newOption.fullProductName;
                break;
              case 'pricing':
                if (newOption.supplyPrice !== undefined) product.options[optionIndex].supplyPrice = newOption.supplyPrice;
                if (newOption.sellingPrice !== undefined) product.options[optionIndex].sellingPrice = newOption.sellingPrice;
                if (newOption.purchasePrice !== undefined) product.options[optionIndex].purchasePrice = newOption.purchasePrice;
                if (newOption.supplyMargin !== undefined) product.options[optionIndex].supplyMargin = newOption.supplyMargin;
                if (newOption.sellingMargin !== undefined) product.options[optionIndex].sellingMargin = newOption.sellingMargin;
                break;
              case 'mainImage':
                if (newOption.mainImageStatus) product.options[optionIndex].mainImageStatus = newOption.mainImageStatus;
                break;
              case 'koreanLabel':
                // 한글 표시사항 관련 옵션 필드 업데이트
                if (newOption.koreanLabelStatus) product.options[optionIndex].koreanLabelStatus = newOption.koreanLabelStatus;
                // 옵션별 한글 표시사항 필드가 있다면 업데이트
                if (newOption.koreanLabels) product.options[optionIndex].koreanLabels = newOption.koreanLabels;
                break;
              case 'additionalImage':
                // 추가 이미지 관련 옵션 필드 업데이트
                if (newOption.additionalImageStatus) product.options[optionIndex].additionalImageStatus = newOption.additionalImageStatus;
                break;
              case 'detailImage':
                // 상세 이미지 관련 옵션 필드 업데이트
                if (newOption.detailImageStatus) product.options[optionIndex].detailImageStatus = newOption.detailImageStatus;
                break;
              case 'quotation':
                // 견적서 관련 옵션 필드 업데이트
                if (newOption.quotationStatus) product.options[optionIndex].quotationStatus = newOption.quotationStatus;
                break;
            }

            // coupangDoc 관련 필드 업데이트 (기능별로)
            if (newOption.coupangDoc && product.options[optionIndex].coupangDoc) {
              // 실제 구현은 기능별 필드 정의 필요
              switch (feature) {
                case 'seo':
                  // SEO 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0) {
                    // 상품명 업데이트
                    product.options[optionIndex].coupangDoc.productName = newOption.fullProductName || product.options[optionIndex].coupangDoc.productName;

                    // startPage 상품명 업데이트
                    if (product.options[optionIndex].coupangDoc.jsonDocument[0].startPage) {
                      product.options[optionIndex].coupangDoc.jsonDocument[0].startPage.productName =
                        newOption.fullProductName || product.options[optionIndex].coupangDoc.productName;
                    }

                    // 검색 태그 업데이트
                    if (product.options[optionIndex].coupangDoc.jsonDocument[0].productPage && product.seo && product.seo.keywords) {
                      product.options[optionIndex].coupangDoc.jsonDocument[0].productPage.searchTags =
                        product.seo.keywords.join(', ');
                    }
                  }
                  break;
                case 'pricing':
                  // 가격 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].productPage &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].productPage.commonAttributes) {

                    const commonAttributes = product.options[optionIndex].coupangDoc.jsonDocument[0].productPage.commonAttributes;

                    // 공급가, 판매가, MSRP 업데이트
                    if (newOption.supplyPrice !== undefined)
                      commonAttributes.purchasePrice = Number(Math.round(newOption.supplyPrice));

                    if (newOption.sellingPrice !== undefined)
                      commonAttributes.coupangSalePrice = Number(Math.round(newOption.sellingPrice));

                    if (newOption.sellingPrice !== undefined)
                      commonAttributes.msrp = Number(Math.floor(newOption.sellingPrice * 1.1));
                  }
                  break;
                case 'mainImage':
                  // 대표 이미지 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images) {

                    if (newOption.mainImageStatus && newOption.mainImageStatus.processedImage) {
                      product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.mainImage =
                        newOption.mainImageStatus.processedImage;
                    }
                  }
                  break;
                case 'additionalImage':
                  // 추가 이미지 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images) {

                    if (product.additionalImages && product.additionalImages.length > 0) {
                      const imageUrls = product.additionalImages.map(img => img.url).filter(Boolean);
                      product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.additionalImage =
                        imageUrls.join(',');
                    }
                  }
                  break;
                case 'detailImage':
                  // 상세 이미지 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.details) {

                    const details = product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.details;

                    // 상세 이미지 URL 설정
                    if (product.detailImages && product.detailImages.length > 0) {
                      details.detailedImage = product.detailImages[0];
                      details.htmlProductDetailContent = ""; // HTML 콘텐츠는 비움
                    }

                    // altText 설정 - SEO 상품명 사용
                    let altText = "";

                    // 1. SEO 상품명이 있으면 우선 사용
                    if (product.seo && product.seo.productName) {
                      altText = product.seo.productName;
                    }
                    // 2. 없으면 원본 상품명 사용
                    else if (product.productNameTrans) {
                      altText = product.productNameTrans;
                    }
                    // 3. 둘 다 없으면 기본 텍스트
                    else {
                      altText = "상품 상세 설명";
                    }

                    // 옵션명이 있으면 altText에 추가
                    if (product.options[optionIndex].korName) {
                      altText = `${altText} - ${product.options[optionIndex].korName}`;
                    }

                    details.altText = altText;
                  }
                  break;
                case 'koreanLabel':
                  // 한글 표시사항 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage &&
                    product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images) {

                    console.log('한글 표시사항 coupangDoc 업데이트 - 옵션:', optionIndex, '이미지:', product.koreanLabelImage);

                    // labelImages 배열이 없으면 생성
                    if (!product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.labelImages) {
                      product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.labelImages = [];
                    }

                    // 한글 표시사항 라벨 이미지 업데이트
                    const labelImageIndex = product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.labelImages.findIndex(
                      img => img.labelImageType === '제품 한글 표시사항 라벨 또는 도안 이미지'
                    );

                    if (product.koreanLabelImage) {
                      if (labelImageIndex !== -1) {
                        product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.labelImages[labelImageIndex].labelImageFiles =
                          product.koreanLabelImage;

                        console.log('한글 표시사항 이미지 업데이트 완료:', product.koreanLabelImage);
                      } else {
                        // 없으면 새로 추가
                        const newLabelImage = {
                          labelImageType: '제품 한글 표시사항 라벨 또는 도안 이미지',
                          labelImageFiles: product.koreanLabelImage
                        };

                        product.options[optionIndex].coupangDoc.jsonDocument[0].imagePage.images.labelImages.push(newLabelImage);
                        console.log('한글 표시사항 이미지 새로 추가:', newLabelImage);
                      }
                    } else {
                      console.warn('한글 표시사항 이미지가 없습니다. product.koreanLabelImage:', product.koreanLabelImage);
                    }
                  } else {
                    console.warn('coupangDoc 구조에 imagePage 또는 images가 없습니다.');
                  }
                  break;
                case 'quotation':
                  // 견적서 관련 coupangDoc 필드 업데이트
                  if (product.options[optionIndex].coupangDoc.jsonDocument &&
                    product.options[optionIndex].coupangDoc.jsonDocument.length > 0 &&
                    product.quotation) {

                    const docObj = product.options[optionIndex].coupangDoc.jsonDocument[0];
                    quotationService.updateCoupangDoc(docObj, product.quotation);
                  }
                  break;
              }
            }
          }
        });
      }

      // 전체 상품 업데이트
      Vue.set(state.rocketProducts, index, product);

      // 선택된 상품이 업데이트된 상품이면 선택된 상품도 업데이트
      if (state.selectedRocketProduct && state.selectedRocketProduct.registrationId === product.registrationId) {
        state.selectedRocketProduct = product;
      }

      console.log(`[UPDATE_PRODUCT_FEATURE] ${feature} 업데이트 완료`);
    }
  },
  actions: {
    async updateProductSizeChart({ dispatch }, product) {
      if (!product || !product.registrationId) {
        throw new Error('올바른 상품 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'sizeChart',
        productData: product
      });
    },

    async processSizeChart({ dispatch, commit }, product) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '사이즈표 처리 중...'
        });

        const result = await sizeChartService.process(product);

        if (result) {
          commit('UPDATE_SIZE_CHART_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        console.error('사이즈표 처리 중 오류:', error);
        dispatch('setProcessing', { isProcessing: false });
        return null;
      }
    },
    // 기능별 상태만 업데이트하는 액션 추가
    async updateFeatureStatus({ dispatch }, { product, feature, status }) {
      try {
        // 깊은 복사로 제품 복사
        const updatedProduct = JSON.parse(JSON.stringify(product));

        // 상태 필드 이름 결정
        const statusField = `${feature}Status`;

        // 상품 상태 업데이트
        if (typeof updatedProduct[statusField] === 'object') {
          updatedProduct[statusField].status = status;
        } else {
          updatedProduct[statusField] = status;
        }

        // 옵션 상태 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            if (option[statusField] && typeof option[statusField] === 'object') {
              option[statusField].status = status;
            } else {
              option[statusField] = status;
            }
          });
        }

        // 기능별 업데이트 액션 호출
        return await dispatch('updateProductFeature', {
          feature,
          productData: updatedProduct
        });
      } catch (error) {
        console.error(`상태 업데이트 중 오류 (${feature}, ${status}):`, error);
      }
    },
    // 기능별 상품 업데이트 액션들
    async updateProductFeature({ commit }, { feature, productData }) {
      try {
        console.log(`${feature} 정보 업데이트 시작`);

        if (feature === 'koreanLabel') {
          console.log('koreanLabel 업데이트 시작 - 데이터:', {
            koreanLabels: productData.koreanLabels,
            koreanLabelImage: productData.koreanLabelImage,
            koreanLabelStatus: productData.koreanLabelStatus
          });

          // 서버 요청 직전에도 로깅
          console.log('koreanLabel 서버 요청 데이터:', {
            feature,
            koreanLabels: productData.koreanLabels,
            koreanLabelImage: productData.koreanLabelImage
          });
        }
        // 1. Vuex 상태 먼저 업데이트 (UI 반응성)
        commit('UPDATE_PRODUCT_FEATURE', { feature, productData });

        // 2. 서버에 기능별 업데이트 요청
        const response = await axios.post('/api/rocketReg/updateFeature', {
          feature,
          product: productData
        });

        // 응답 검증
        if (response.data.res !== 'success') {
          console.error(`${feature} 서버 저장 실패:`, response.data.alert || '업데이트 실패');
          throw new Error(response.data.alert || `${feature} 업데이트 실패`);
        }

        // 3. 서버에서 반환된 데이터가 있으면 상태 확인
        if (response.data.updatedProduct) {
          console.log(`${feature} 서버 응답으로 확인 완료`);
        }

        return true;
      } catch (error) {
        console.error(`${feature} 업데이트 중 오류:`, error);
        throw error;
      }
    },

    // SEO 정보 업데이트 전용 액션
    async updateProductSeo({ dispatch }, product) {
      if (!product || !product.registrationId || !product.seo) {
        throw new Error('올바른 SEO 정보가 없습니다.');
      }

      // productFeature 액션 재사용
      return dispatch('updateProductFeature', {
        feature: 'seo',
        productData: product
      });
    },

    // 가격 정보 업데이트 전용 액션
    async updateProductPricing({ dispatch }, product) {
      if (!product || !product.registrationId || !product.pricing) {
        throw new Error('올바른 가격 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'pricing',
        productData: product
      });
    },

    // 대표 이미지 업데이트 전용 액션
    async updateProductMainImage({ dispatch }, product) {
      if (!product || !product.registrationId) {
        throw new Error('올바른 상품 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'mainImage',
        productData: product
      });
    },

    // 추가 이미지 업데이트 전용 액션
    async updateProductAdditionalImage({ dispatch }, product) {
      if (!product || !product.registrationId || !product.additionalImages) {
        throw new Error('올바른 추가 이미지 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'additionalImage',
        productData: product
      });
    },

    // 상세 이미지 업데이트 전용 액션
    async updateProductDetailImage({ dispatch }, product) {
      if (!product || !product.registrationId || !product.detailImages) {
        throw new Error('올바른 상세 이미지 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'detailImage',
        productData: product
      });
    },

    // 한글 표시사항 업데이트 전용 액션
    async updateProductKoreanLabel({ dispatch }, product) {
      if (!product || !product.registrationId || !product.koreanLabels) {
        throw new Error('올바른 한글 표시사항 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'koreanLabel',
        productData: product
      });
    },

    // 견적서 업데이트 전용 액션
    async updateProductQuotation({ dispatch }, product) {
      if (!product || !product.registrationId || !product.quotation) {
        throw new Error('올바른 견적서 정보가 없습니다.');
      }

      return dispatch('updateProductFeature', {
        feature: 'quotation',
        productData: product
      });
    },
    layoutModeAction(context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    },
    // 로켓배송 상품 검색
    async searchRocketProducts({ commit }, searchParams) {
      try {
        commit('SET_PROCESSING', true);
        commit('SET_PROGRESS_MESSAGE', '상품 목록을 가져오는 중...');

        const response = await axios.post('/api/aiRocketReg/getlist', searchParams);

        commit('SET_PROCESSING', false);
        commit('SET_PROGRESS_MESSAGE', '');

        if (response.data && response.data.products) {
          // 상품 데이터 가공
          const processedProducts = response.data.products.map(product => {
            // detailImages가 있으면 originDetailImages 생성
            if (product.detailImages && product.detailImages.length > 0) {
              product.originDetailImages = product.detailImages.map(img => {
                return {
                  originalUrl: typeof img === 'string' ? img : (img.img || img.url || ''),
                  img: typeof img === 'string' ? img : (img.img || img.url || '')
                };
              });
            } else {
              product.originDetailImages = [];
            }

            return product;
          });

          commit('SET_ROCKET_PRODUCTS', processedProducts);
          return processedProducts;
        }
        return [];
      } catch (error) {
        commit('SET_PROCESSING', false);
        commit('SET_PROGRESS_MESSAGE', '');
        console.error('로켓배송 상품 검색 중 오류:', error);
        return [];
      }
    },

    async requestKoreanLabelData({ commit }, { product }) {
      try {
        // AI 요청
        await workflowService.requestExtension({
          type: 'KOREAN_LABELS',
          productId: product.registrationId,
          prompt: koreanLabelService.promptForKoreanLabels(product) // koreanLabelService에서 프롬프트 생성
        });

        // 초기 라벨 정보 설정
        const initialLabels = {
          productName: product.productName,
          manufacturer: product.manufacturer || '제조사 정보 없음',
          origin: '중국',
          madein: '중국',
          caution: '용도 외에 사용금지. 파손및화기주의',
          standard: '14세이상',
        };
        // store에 commit
        commit('SET_KOREAN_LABEL_DATA', { productId: product.registrationId, labels: initialLabels });

      } catch (error) {
        console.error('AI 요청 중 오류:', error);
      }
    },

    async updateRocketProduct({ commit }, product) {
      try {
        // skipCoupangUpdate 파라미터 확인 (명시적으로 추출)
        const shouldSkipCoupangUpdate = product.skipCoupangUpdate === true;

        // skipCoupangUpdate 속성 제거 (서버로 전달하지 않기 위함)
        const productToSave = { ...product };
        delete productToSave.skipCoupangUpdate;

        // 1. 우선 Vuex 상태 업데이트 (UI 즉시 반응)
        commit('UPDATE_ROCKET_PRODUCT', productToSave);

        // 2. 서버에 저장
        const response = await axios.post('/api/rocketReg/saveItems', [productToSave]);

        // 응답 확인 및 오류 처리
        if (response.data.res !== 'success') {
          console.error('서버 저장 실패:', response.data.message || '상품 정보 업데이트 실패');
          throw new Error(response.data.message || '상품 정보 업데이트 실패');
        }

        // 3. 서버에서 반환된 저장 데이터가 있으면 상태 재업데이트
        if (response.data.savedItems && response.data.savedItems.length > 0) {
          // const savedProduct = response.data.savedItems[0];

          // // 중요: 서버 응답에 있는 필드만 업데이트 (다른 필드는 건드리지 않음)
          // commit('UPDATE_ROCKET_PRODUCT', savedProduct);
          console.log('서버 응답으로 상태 업데이트 완료');
        }

        // 4. coupangDoc 업데이트는 스킵 (regRocketApi에서 처리)
        if (shouldSkipCoupangUpdate) {
          console.log('쿠팡 문서 업데이트 스킵');
        }

        return true;
      } catch (error) {
        console.error('상품 정보 업데이트 중 오류:', error);
        throw error;
      }
    },
    async updateCoupangDocument(context, payload) {
      try {
        console.log('===== updateCoupangDocument 시작 =====');

        // payload에서 product와 option 확인
        if (!payload) {
          console.error('payload가 제공되지 않았습니다.');
          return false;
        }

        const { product, option } = payload;

        // 기본 로깅 
        console.log(`상품 ID: ${product?.registrationId}`);
        console.log(`옵션 번호: ${option?.optionNumber}`);

        // coupangDoc 정보 가져오기
        const coupangDoc = option?.coupangDoc || product?.coupangDoc;

        if (!coupangDoc || !coupangDoc.docId) {
          console.error('coupangDoc 정보가 없습니다. 업데이트할 수 없습니다.');
          return false;
        }

        console.log('coupangDoc:', coupangDoc)

        console.log(`coupangDoc ID: ${coupangDoc.docId}`);

        // 확장 프로그램에 메시지 전송 - 이미 구성된 coupangDoc을 그대로 전송
        window.postMessage({
          type: 'UPDATE_ROCKET_REG_DOC',
          id: `update_${product.registrationId}_${option ? option.optionNumber : ''}_${Date.now()}`,
          data: {
            docId: coupangDoc.docId,
            body: coupangDoc,
            suppliercode: context.state.user.suppliercode
          }
        }, '*');

        console.log('쿠팡 문서 업데이트 요청 완료');
        console.log('===== updateCoupangDocument 끝 =====');

        return true;
      } catch (error) {
        console.error('쿠팡 문서 업데이트 요청 중 오류:', error);
        return false;
      }
    },
    // index.js - updateOptionImage 액션 수정
    async updateOptionImage({ commit, state }, { optionNumber, imageData }) {
      try {
        console.log(`옵션 ${optionNumber} 이미지 업데이트 시작:`, imageData);

        // Vuex 상태 먼저 업데이트
        commit('UPDATE_OPTION_IMAGE', { optionNumber, imageData });

        // 2. 해당 옵션의 coupangDoc 업데이트
        const option = state.rocketProducts.flatMap(p => p.options || [])
          .find(o => o.optionNumber === optionNumber);

        if (option && option.coupangDoc && option.coupangDoc.jsonDocument &&
          option.coupangDoc.jsonDocument.length > 0) {
          const docObj = option.coupangDoc.jsonDocument[0];
          if (docObj.imagePage && docObj.imagePage.images) {
            // 메인 이미지 URL 업데이트 확인
            docObj.imagePage.images.mainImage = imageData.processedUrl;
            console.log(`옵션 ${optionNumber}의 coupangDoc 메인 이미지 설정: ${imageData.processedUrl}`);
          }
        }

        // 해당 옵션이 속한 상품 찾기
        let targetProduct = null;
        for (const product of state.rocketProducts) {
          if (!product.options) continue;

          if (product.options.some(opt => opt.optionNumber === optionNumber)) {
            targetProduct = { ...product }; // 깊은 복사
            break;
          }
        }

        console.log('저장할 targetProduct:', targetProduct);

        // 상품이 있으면 서버에 저장
        if (targetProduct) {
          // 서버 저장 전 상태 확인
          console.log('서버 저장 전 옵션 상태:');
          if (targetProduct.options) {
            const targetOption = targetProduct.options.find(opt => opt.optionNumber === optionNumber);
            if (targetOption && targetOption.mainImageStatus) {
              console.log('저장할 processedImage:', targetOption.mainImageStatus.processedImage);
            }
          }

          // 서버에 저장 및 응답 처리
          const response = await axios.post('/api/rocketReg/saveItems', [targetProduct]);

          console.log('서버 응답:', response.data);

          // 응답 검증
          if (response.data.res !== 'success') {
            throw new Error(response.data.message || '서버 저장 실패');
          }

          // 서버에서 반환된 저장된 데이터가 있으면 상태 업데이트
          if (response.data.savedItems && response.data.savedItems.length > 0) {
            const savedProduct = response.data.savedItems[0];

            // 저장된 상품 정보로 상태 업데이트
            commit('UPDATE_ROCKET_PRODUCT', savedProduct);

            console.log('서버에서 반환된 저장 데이터로 상태 업데이트 완료');

            // 저장된 옵션 정보 확인
            const savedOption = savedProduct.options.find(opt => opt.optionNumber === optionNumber);
            if (savedOption && savedOption.mainImageStatus) {
              console.log('서버 저장 후 processedImage:', savedOption.mainImageStatus.processedImage);
            }
          }
        }

        return true;
      } catch (error) {
        console.error('옵션 이미지 업데이트 중 오류:', error);
        throw error;
      }
    },

    // 작업 상태 설정
    setProcessing({ commit }, { isProcessing, message = '' }) {
      commit('SET_PROCESSING', isProcessing);
      commit('SET_PROGRESS_MESSAGE', message);
    },

    // 이미지에서 텍스트 제거 액션
    async removeTextFromImage({ dispatch }, imageUrl) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '이미지에서 텍스트 제거 중...'
        });

        const processedImageUrl = await imageService.removeTextFromImage(imageUrl);

        dispatch('setProcessing', { isProcessing: false });
        return processedImageUrl;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('텍스트 제거 오류:', error);
        return imageUrl; // 오류 시 원본 URL 반환
      }
    },

    // 이미지 다운로드
    async downloadImage(_, url) {
      return await imageService.downloadImage(url);
    },

    // Blob을 Base64로 변환
    async blobToBase64(_, blob) {
      return await imageService.blobToBase64(blob);
    },

    // 이미지를 S3에 업로드
    async uploadImageToS3({ state }, payload) {
      return await imageService.uploadImageToS3({
        ...payload,
        companyId: state.user.companyid || 'default'
      });
    },

    // 대표 이미지 처리
    async processMainImage({ dispatch, commit }, { product, option }) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '대표 이미지 처리 중...'
        });

        const result = await mainImageService.processMainImage(product, option);

        if (result) {
          commit('UPDATE_MAIN_IMAGE_RESULT', {
            productId: product.registrationId,
            optionNumber: option.optionNumber,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('대표 이미지 처리 중 오류:', error);
        return null;
      }
    },

    // 추가 이미지 처리
    async processAdditionalImages({ dispatch, commit }, product) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '추가 이미지 처리 중...'
        });

        const result = await additionalImageService.processAdditionalImages(product);

        if (result) {
          commit('UPDATE_ADDITIONAL_IMAGE_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('추가 이미지 처리 중 오류:', error);
        return null;
      }
    },

    // 상세 이미지 처리
    async processDetailImages({ dispatch, commit }, product) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '상세 이미지 처리 중...'
        });

        const result = await detailImageService.processDetailImages(product);

        if (result) {
          commit('UPDATE_DETAIL_IMAGE_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('상세 이미지 처리 중 오류:', error);
        return null;
      }
    },

    // 한글 표시사항 처리
    async processKoreanLabels({ dispatch, commit }, product) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '한글 표시사항 처리 중...'
        });

        const result = await koreanLabelService.processKoreanLabels(product);

        if (result) {
          commit('UPDATE_KOREAN_LABEL_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('한글 표시사항 처리 중 오류:', error);
        return null;
      }
    },

    // 가격 설정 처리
    async processPricing({ dispatch, commit }, { product, options }) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '가격 설정 처리 중...'
        });

        const result = await pricingService.processPricing(product, options);

        if (result) {
          commit('UPDATE_PRICING_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('가격 설정 처리 중 오류:', error);
        return null;
      }
    },

    // SEO 설정 처리
    async processSeo({ dispatch, commit }, { product, options }) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: 'SEO 설정 처리 중...'
        });

        const result = await seoService.processSeo(product, options);

        if (result) {
          commit('UPDATE_SEO_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('SEO 설정 처리 중 오류:', error);
        return null;
      }
    },

    // 견적서 처리
    async processQuotation({ dispatch, commit }, { product, options }) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '견적서 작업 처리 중...'
        });

        const result = await quotationService.processQuotation(product, options);

        if (result) {
          commit('UPDATE_QUOTATION_RESULT', {
            productId: product.registrationId,
            result
          });
        }

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('견적서 작업 처리 중 오류:', error);
        return null;
      }
    },

    // 모든 옵션의 메인 이미지 처리
    async processMainImageForAllOptions({ dispatch, commit }, product) {
      if (!product || !product.options || product.options.length === 0) {
        throw new Error('처리할 옵션이 없습니다.');
      }

      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: '모든 옵션의 대표 이미지 처리 중...'
        });

        // 워크플로우 서비스 호출
        const result = await workflowService.processMainImageForAllOptions(product);

        // 결과에 따라 상품 상태 업데이트
        const updatedProduct = { ...product };
        updatedProduct.mainImageStatus = result.status;

        // 상품 정보 업데이트
        commit('UPDATE_ROCKET_PRODUCT', updatedProduct);

        dispatch('setProcessing', { isProcessing: false });
        return result;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('모든 옵션 메인 이미지 처리 중 오류:', error);
        throw error;
      }
    },

    // 단일 작업 실행 (일괄 처리용)
    async executeTask({ dispatch }, { product, taskType, options = {} }) {
      try {
        let result = null;

        switch (taskType) {
          case 'mainImage':
            result = await dispatch('processMainImageForAllOptions', product);
            break;
          case 'additionalImage':
            result = await dispatch('processAdditionalImages', product);
            break;
          case 'detailImage':
            result = await dispatch('processDetailImages', product);
            break;
          case 'koreanLabel':
            result = await dispatch('processKoreanLabels', product);
            break;
          case 'pricing':
            result = await dispatch('processPricing', { product, options });
            break;
          case 'seo':
            result = await dispatch('processSeo', { product, options });
            break;
          case 'quotation':
            result = await dispatch('processQuotation', { product, options });
            break;
          default:
            throw new Error(`알 수 없는 작업 유형: ${taskType}`);
        }

        return result;
      } catch (error) {
        console.error(`${taskType} 작업 실행 중 오류:`, error);
        throw error;
      }
    },

    // 테스트 요청 처리
    async testRequestExtension({ dispatch }, product) {
      try {
        dispatch('setProcessing', {
          isProcessing: true,
          message: 'AI 테스트 시작 중...'
        });

        // 테스트할 작업 목록
        const tasks = [
          'mainImage',
          'additionalImage',
          'detailImage',
          'koreanLabel',
          'seo',
          'pricing',
          'quotation'
        ];

        // 워크플로우 서비스로 처리
        const results = await workflowService.executeWorkflow(product, tasks);

        dispatch('setProcessing', { isProcessing: false });
        return results;
      } catch (error) {
        dispatch('setProcessing', { isProcessing: false });
        console.error('AI 테스트 중 오류:', error);
        throw error;
      }
    }
  },
  getters: {
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    darklogo: state => { return state.darklogo },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    accesshistory: state => { return state.user.accesshistory },
    email: state => { return state.user.email },
    companyid: state => { return state.user.companyid },
    expireDate: state => { return state.user.expireDate },
    suppliercode: state => { return state.user.suppliercode },
    image2: state => { return state.user.image2 },
    image3: state => { return state.user.image3 },
    dark: state => { return state.dark },
    // 로켓배송 관련 getters
    getRocketProductById: (state) => (id) => {
      return state.rocketProducts.find(product => product.registrationId === id) || null;
    },

    // 옵션 번호로 옵션 가져오기
    getOptionByNumber: (state) => (optionNumber) => {
      for (const product of state.rocketProducts) {
        if (!product.options) continue;

        const option = product.options.find(opt => opt.optionNumber === optionNumber);
        if (option) return option;
      }
      return null;
    },

    // 현재 선택된 옵션의 이미지 데이터 가져오기
    getCurrentOptionImageData: (state) => {
      if (!state.selectedOption) return null;
      return state.selectedOption.mainImageStatus || null;
    },

    // 작업 중인 이미지 데이터 가져오기
    getWorkingImageData: (state) => {
      return state.workingImageData;
    },

    // 특정 상품의 작업 진행 상태 계산
    getProductWorkStatus: (state) => (productId) => {
      const product = state.rocketProducts.find(p => p.registrationId === productId);
      if (!product) return null;

      // 작업 상태 수집
      const statuses = {
        mainImage: product.mainImageStatus || 'pending',
        additionalImage: product.additionalImageStatus || 'pending',
        detailImage: product.detailImageStatus || 'pending',
        koreanLabel: product.koreanLabelStatus || 'pending',
        pricing: product.pricingStatus || 'pending',
        seo: product.seoStatus || 'pending',
        quotation: product.quotationStatus || 'pending'
      };

      // 완료된 작업 수 계산
      const completedTasks = Object.values(statuses).filter(
        status => status === 'completed'
      ).length;

      // 작업 총 개수
      const totalTasks = Object.keys(statuses).length;

      // 진행률 계산
      const progress = Math.round((completedTasks / totalTasks) * 100);

      return {
        statuses,
        completedTasks,
        totalTasks,
        progress
      };
    }
  },
  modules: {
  },
  // plugins: [createPersistedState({ paths: ["userStore"] })]
})
