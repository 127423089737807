// src/services/works/sizeChartService.js

import store from '@/store';
import imageService from '../imageService';
import html2canvas from 'html2canvas';

/**
 * 사이즈표 처리를 위한 서비스
 */
const sizeChartService = {
  _sizeChartProcessed: false,
  _allImagesScanned: false,
  _processedImageGroups: 0,  // 처리된 이미지 그룹 수를 추적
  _totalImageGroups: 0,      // 전체 이미지 그룹 수

  resetProcessedState() {
    this._sizeChartProcessed = false;
    this._allImagesScanned = false;
    this._processedImageGroups = 0;
    this._totalImageGroups = 0;
  },
  /**
 * 사이즈표 작업 처리 진입점
 * @param {Object} product - 상품 객체
 * @returns {Promise<Boolean>} - 처리 성공 여부
 */
  async process(product) {
    try {
      // 상품 유효성 확인
      if (!product || !product.registrationId) {
        throw new Error('유효하지 않은 상품 정보입니다.');
      }

      // 스키마 확인하여 사이즈표가 필요 없는 경우 처리
      if (!this.isSizeChartRequired(product)) {
        console.log('사이즈표가 필요하지 않은 카테고리입니다.');

        // 상태 업데이트: disabled
        const updatedProduct = JSON.parse(JSON.stringify(product));
        updatedProduct.sizeChartStatus = 'disabled';

        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            option.sizeChartStatus = 'disabled';
          });
        }

        await store.dispatch('updateRocketProduct', updatedProduct);
        return true;
      }

      console.log('사이즈표 작업 시작:', product.registrationId);

      // 상태 초기화
      this.resetProcessedState();

      // 상태 업데이트: processing으로 유지
      const updatedProduct = JSON.parse(JSON.stringify(product));
      updatedProduct.sizeChartStatus = 'processing';

      // 옵션 상태 업데이트
      if (updatedProduct.options && updatedProduct.options.length > 0) {
        updatedProduct.options.forEach(option => {
          option.sizeChartStatus = 'processing';
        });
      }

      // processing 상태로 저장하고 실제 완료는 handleResult에서만 처리하도록 함
      await store.dispatch('updateRocketProduct', updatedProduct);

      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI로 사이즈표 작업 시작 중...'
      });

      // 확장 프로그램에 사이즈표 데이터 요청
      const requestResult = await this.requestSizeChartData(product);

      if (!requestResult) {
        console.error('사이즈표 데이터 요청 실패');
        store.dispatch('setProcessing', { isProcessing: false });
        return false;
      }

      // 작업이 요청되었음을 알리고 로딩 상태 유지 (processing 상태로 보여주기 위함)
      store.dispatch('setProcessing', {
        isProcessing: false,
        message: '사이즈표 분석 작업이 진행 중입니다...'
      });

      return true;
    } catch (error) {
      console.error('사이즈표 작업 중 오류:', error);
      store.dispatch('setProcessing', { isProcessing: false });
      return false;
    }
  },
  isSizeChartRequired(product) {
    try {
      if (product.rocketRegInfo && product.rocketRegInfo.schemaString) {
        const schema = JSON.parse(product.rocketRegInfo.schemaString);

        if (schema.properties &&
          schema.properties.imagePage &&
          schema.properties.imagePage.properties &&
          schema.properties.imagePage.properties.images &&
          schema.properties.imagePage.properties.images.properties &&
          schema.properties.imagePage.properties.images.required) {

          return schema.properties.imagePage.properties.images.required.includes('sizeChartImage');
        }
      }
    } catch (error) {
      console.error('스키마 파싱 오류:', error);
    }
    return false;
  },
  /**
 * 실용적인 기본 사이즈표 생성
 * @param {Object} product - 상품 객체
 * @returns {Object} 실용적인 기본 사이즈표
 */
  createPracticalSizeChart(product) {
    // 상품 정보에서 카테고리와 옵션 정보 추출
    const categoryPath = product.categoryPath || '';
    const options = product.options || [];

    // 옵션에서 사이즈 관련 정보 추출
    const sizeOptions = options
      .filter(option => option.option2 && option.option2.name)
      .map(option => option.option2.nameTrans || option.option2.name);

    // 중복 제거
    const uniqueSizes = [...new Set(sizeOptions)].filter(Boolean);

    // 카테고리에 따른 측정 항목 결정
    let measurements = [];
    let sizeType = '일반 사이즈';

    if (categoryPath.includes('의류') || categoryPath.includes('패션') ||
      categoryPath.includes('상의') || categoryPath.includes('하의') ||
      categoryPath.includes('티셔츠') || categoryPath.includes('니트') ||
      categoryPath.includes('자켓') || categoryPath.includes('코트')) {
      sizeType = '의류 사이즈';
      measurements = [
        {
          name: '사이즈',
          values: uniqueSizes.length > 0
            ? uniqueSizes
            : ['Free Size (옵션명 또는 상세페이지 참조)']
        },
        {
          name: '총장/길이',
          values: ['상세페이지 참조']
        },
        {
          name: '어깨너비',
          values: ['상세페이지 참조']
        },
        {
          name: '가슴단면',
          values: ['상세페이지 참조']
        }
      ];
    } else if (categoryPath.includes('신발') || categoryPath.includes('슈즈')) {
      sizeType = '신발 사이즈';
      measurements = [
        {
          name: '사이즈',
          values: uniqueSizes.length > 0
            ? uniqueSizes
            : ['Free Size (옵션명 또는 상세페이지 참조)']
        },
        {
          name: '발길이',
          values: ['상세페이지 참조']
        },
        {
          name: '발볼넓이',
          values: ['상세페이지 참조']
        }
      ];
    } else if (categoryPath.includes('가구') || categoryPath.includes('인테리어')) {
      sizeType = '가구/인테리어 사이즈';
      measurements = [
        {
          name: '사이즈',
          values: uniqueSizes.length > 0
            ? uniqueSizes
            : ['Free Size (옵션명 또는 상세페이지 참조)']
        },
        {
          name: '가로',
          values: ['상세페이지 참조']
        },
        {
          name: '세로',
          values: ['상세페이지 참조']
        },
        {
          name: '높이',
          values: ['상세페이지 참조']
        }
      ];
    } else {
      // 기타 카테고리는 일반 사이즈로 처리
      measurements = [
        {
          name: '사이즈',
          values: uniqueSizes.length > 0
            ? uniqueSizes
            : ['Free Size (옵션명 또는 상세페이지 참조)']
        },
        {
          name: '상세 규격',
          values: ['상세페이지 참조']
        }
      ];
    }

    // 사이즈 배열 생성
    const sizes = uniqueSizes.length > 0 ? uniqueSizes : ['Free Size'];

    // 측정 항목의 values 배열 길이를 sizes 배열 길이에 맞게 조정
    measurements.forEach(measurement => {
      if (measurement.values.length === 1 && sizes.length > 1) {
        // 동일한 값으로 배열 확장
        measurement.values = Array(sizes.length).fill(measurement.values[0]);
      }
    });

    return {
      sizeType: sizeType,
      sizeUnits: 'cm',
      sizes: sizes,
      measurements: measurements
    };
  },

  /**
 * 확장 프로그램에서 받은 사이즈표 결과 처리
 * @param {Object} product - 상품 객체
 * @param {Object} result - 확장 프로그램에서 받은 결과
 * @returns {Promise<Boolean>} - 처리 성공 여부
 */
  async handleResult(product, result) {
    try {
      if (!product || !result) {
        console.error('handleResult: 유효하지 않은 입력 - product 또는 result가 없음');
        return false;
      }

      console.log('----- 사이즈표 결과 처리 시작 -----');
      console.log('제품 ID:', product.registrationId);
      // console.log('결과 데이터:', JSON.stringify(result, null, 2));
      console.log('hasSizeChart 값:', result.hasSizeChart);

      if (result.sizeChart) {
        console.log('사이즈 유형:', result.sizeChart.sizeType);
        console.log('사이즈 단위:', result.sizeChart.sizeUnits);
        console.log('사이즈 목록:', result.sizeChart.sizes);
        console.log('측정 항목 수:', result.sizeChart.measurements ? result.sizeChart.measurements.length : 0);

        if (result.sizeChart.measurements && result.sizeChart.measurements.length > 0) {
          console.log('측정 항목 상세:');
          result.sizeChart.measurements.forEach((item, index) => {
            console.log(`  ${index + 1}. ${item.name}: ${item.values.join(', ')}`);
          });
        }
      }

      // 이미지 관련 정보 로깅
      if (result.sourceImage) {
        console.log('소스 이미지 URL:', result.sourceImage);
      }
      if (result.detectedArea) {
        console.log('감지된 사이즈표 영역:', result.detectedArea);
      }

      console.log('사이즈표 결과 처리 시작:', result);

      // 처리된 이미지 그룹 수 증가
      this._processedImageGroups++;
      console.log(`처리된 이미지 그룹: ${this._processedImageGroups}/${this._totalImageGroups}`);

      // 사이즈표 데이터 확인
      if (result.hasSizeChart) {
        // 사이즈표를 찾은 경우 (성공 케이스)
        console.log('AI 검출 사이즈표:', result.sizeChart);

        // 처리 완료 플래그 설정 - 추가 이미지 스캔 중단용
        this._sizeChartProcessed = true;

        // 상품 정보 업데이트 (깊은 복사)
        const updatedProduct = JSON.parse(JSON.stringify(product));

        // 기존 사이즈 데이터 유지하면서 AI 결과 병합
        updatedProduct.sizeChart = {
          ...(updatedProduct.sizeChart || {}),
          ...result.sizeChart
        };

        // 상태 업데이트: 이제 completed로 변경 (사이즈표 찾음)
        console.log('사이즈표 이미지 찾음')
        updatedProduct.sizeChartStatus = 'completed';

        // 새로운 사이즈표 이미지 생성 및 업로드
        const imageBase64 = await this.generateSizeChartImage(updatedProduct.sizeChart);
        const filename = `size_chart_${updatedProduct.registrationId}_${Date.now()}.png`;
        const imageUrl = await this.uploadSizeChartImage(updatedProduct, imageBase64, filename);
        updatedProduct.sizeChartImage = imageUrl;

        // 옵션 상태 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            option.sizeChartStatus = 'completed';
            
            // 다음 코드 추가 - coupangDoc 업데이트
            if (option.coupangDoc && option.coupangDoc.jsonDocument &&
                option.coupangDoc.jsonDocument.length > 0 &&
                option.coupangDoc.jsonDocument[0].imagePage &&
                option.coupangDoc.jsonDocument[0].imagePage.images) {
              // 사이즈표 이미지 URL 설정
              option.coupangDoc.jsonDocument[0].imagePage.images.sizeChartImage = updatedProduct.sizeChartImage;
            }
          });
        }

        // 로딩 상태 해제
        store.dispatch('setProcessing', { isProcessing: false });

        // updateProductFeature 사용
        await store.dispatch('updateProductFeature', {
          feature: 'sizeChart',
          productData: updatedProduct
        });

        console.log('사이즈표 업데이트 완료:', updatedProduct.sizeChart);
      } else {
        // 사이즈표를 찾지 못한 경우
        console.log('이미지에서 사이즈표를 찾지 못했습니다. 계속 검색 중...');

        // 모든 이미지 처리 완료 여부 확인
        const isLastImageGroup = this._processedImageGroups >= this._totalImageGroups;

        if (isLastImageGroup) {
          // 모든 이미지 스캔 완료 플래그 설정
          this._allImagesScanned = true;
          console.log('모든 이미지 그룹 처리 완료. 사이즈표 검색 완료.');
        }

        // 현재 상품 정보 가져오기
        const currentProduct = store.state.rocketProducts.find(p => p.registrationId === product.registrationId);

        // 모든 이미지를 스캔했고 사이즈표를 찾지 못한 경우에만 기본 사이즈표로 완료 처리
        if (this._allImagesScanned) {
          console.log('모든 이미지를 스캔했지만 사이즈표를 찾지 못했습니다. 기본 사이즈표로 완료 처리합니다.');

          // 실용적인 기본 사이즈표 생성
          const practicalSizeChart = this.createPracticalSizeChart(currentProduct || product);

          const updatedProduct = JSON.parse(JSON.stringify(currentProduct || product));
          updatedProduct.sizeChart = practicalSizeChart;
          updatedProduct.sizeChartStatus = 'completed';

          // 새로운 사이즈표 이미지 생성 및 업로드
          const imageBase64 = await this.generateSizeChartImage(practicalSizeChart);
          const filename = `size_chart_${updatedProduct.registrationId}_${Date.now()}.png`;
          const imageUrl = await this.uploadSizeChartImage(updatedProduct, imageBase64, filename);
          updatedProduct.sizeChartImage = imageUrl;

          // 옵션 상태 업데이트
          if (updatedProduct.options && updatedProduct.options.length > 0) {
            updatedProduct.options.forEach(option => {
              option.sizeChartStatus = 'completed';

              // coupangDoc 업데이트
              if (option.coupangDoc && option.coupangDoc.jsonDocument &&
                option.coupangDoc.jsonDocument.length > 0 &&
                option.coupangDoc.jsonDocument[0].imagePage &&
                option.coupangDoc.jsonDocument[0].imagePage.images) {
                option.coupangDoc.jsonDocument[0].imagePage.images.sizeChartImage = updatedProduct.sizeChartImage;
              }
            });
          }

          // 로딩 상태 해제
          store.dispatch('setProcessing', { isProcessing: false });

          // updateProductFeature 사용
          await store.dispatch('updateProductFeature', {
            feature: 'sizeChart',
            productData: updatedProduct
          });
        } else {
          // 아직 모든 이미지를 스캔하지 않았으므로 상태 유지
          console.log('계속해서 다른 이미지를 스캔합니다...');
        }
      }

      return true;
    } catch (error) {
      console.error('사이즈표 결과 처리 중 오류:', error);
      store.dispatch('setProcessing', { isProcessing: false });
      return false;
    }
  },

  /**
   * 초기 사이즈 데이터 추출
   * @param {Object} product - 상품 객체
   * @returns {Object} 초기 사이즈 데이터
   */
  extractInitialSizeData(product) {
    // 기본 사이즈 정보 (주로 어패럴/의류 카테고리에 적용)
    const defaultSizeData = {
      sizeType: '일반 사이즈',
      sizeUnits: 'cm',
      sizes: ['S', 'M', 'L', 'XL'],
      measurements: [
        { name: '총장', values: ['60', '65', '70', '75'] },
        { name: '어깨너비', values: ['40', '42', '44', '46'] },
        { name: '가슴단면', values: ['48', '50', '52', '54'] }
      ]
    };

    // 상품 속성에서 사이즈 정보 추출 시도
    if (product.attributes) {
      try {
        // 사이즈 정보가 있는지 확인
        const sizeInfo = product.attributes.find(attr =>
          attr.attributeName === 'size' ||
          attr.attributeName === '사이즈' ||
          attr.attributeName === '규격'
        );

        if (sizeInfo) {
          // 사이즈 값이 있으면 기본 사이즈 배열에 추가
          defaultSizeData.sizes = sizeInfo.attributeValue.split(',').map(s => s.trim());
        }

        // 신발, 액세서리 등 다른 카테고리 처리
        // 여기에 카테고리별 특수 처리 로직 추가 가능
      } catch (error) {
        console.warn('사이즈 정보 추출 중 오류:', error);
      }
    }

    return defaultSizeData;
  },

  /**
   * HTML로 사이즈표 이미지 생성
   * @param {Object} sizeData - 사이즈 데이터
   * @returns {Promise<string>} Base64 형식의 이미지 데이터
   */
  async generateSizeChartImage(sizeData) {
    try {
      console.log('----- 사이즈표 이미지 생성 시작 -----');
      // console.log('사용할 사이즈 데이터:', JSON.stringify(sizeData, null, 2));
      // 사이즈표 HTML 생성
      const sizeChartHtml = this.generateSizeChartHtml(sizeData);
      // console.log('생성된 HTML 구조 (일부):', sizeChartHtml.substring(0, 300) + '...');

      // HTML을 이미지로 변환
      const containerDiv = document.createElement('div');
      containerDiv.style.position = 'absolute';
      containerDiv.style.left = '-9999px';
      containerDiv.style.top = '-9999px';
      containerDiv.innerHTML = sizeChartHtml;
      document.body.appendChild(containerDiv);

      // html2canvas를 사용하여 이미지 생성
      const canvas = await html2canvas(containerDiv, {
        backgroundColor: '#ffffff',
        scale: 2,
        logging: false
      });

      document.body.removeChild(containerDiv);

      // 이미지를 base64 문자열로 변환
      console.log('사이즈표 이미지 생성 완료 (Base64 형식)');
      return canvas.toDataURL('image/png');
    } catch (error) {
      console.error('사이즈표 이미지 생성 중 오류:', error);
      console.log('오류로 인해 기본 사이즈표 이미지 생성');

      // 오류 시 기본 사이즈표 이미지 대신 생성
      return this.generateFallbackSizeChartImage();
    }
  },

  /**
 * 사이즈표 HTML 생성
 * @param {Object} sizeData - 사이즈표 데이터
 * @returns {string} 사이즈표 HTML
 */
  generateSizeChartHtml(sizeData) {
    // 기본 스타일과 표 생성
    let html = `
    <div style="width: 600px; font-family: Arial, sans-serif; padding: 20px; background-color: white;">
      <h2 style="text-align: center; color: #333; margin-bottom: 15px;">사이즈 정보</h2>
      <table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
        <thead>
          <tr style="background-color: #f0f0f0;">
            <th style="padding: 10px; border: 1px solid #ddd;">측정 부위</th>
  `;

    // 사이즈 헤더 추가
    const sizes = sizeData.sizes || ['Free Size'];
    sizes.forEach(size => {
      html += `<th style="padding: 10px; border: 1px solid #ddd;">${size}</th>`;
    });

    html += `
          </tr>
        </thead>
        <tbody>
  `;

    // 측정 부위 및 값 추가
    const measurements = sizeData.measurements || [
      { name: '상세 정보', unit: 'cm', values: ['상세페이지 참조'] }
    ];

    measurements.forEach(measurement => {
      // 측정 부위 이름을 표시하고 단위를 옆에 표시
      const unitDisplay = measurement.unit ? ` (${measurement.unit})` : '';

      html += `
      <tr>
        <td style="padding: 10px; border: 1px solid #ddd; font-weight: bold;">${measurement.name}${unitDisplay}</td>
    `;

      // 각 사이즈별 값 추가
      const values = measurement.values || Array(sizes.length).fill('상세페이지 참조');
      for (let i = 0; i < sizes.length; i++) {
        const value = i < values.length ? values[i] : '상세페이지 참조';
        html += `<td style="padding: 10px; border: 1px solid #ddd; text-align: center;">${value}</td>`;
      }

      html += `</tr>`;
    });

    html += `
        </tbody>
      </table>
      <p style="margin-top: 15px; color: #666; font-size: 12px;">※ 상품 측정 방법에 따라 약간의 오차가 있을 수 있습니다.</p>
    </div>
  `;

    return html;
  },

  /**
   * 오류 시 대체 사이즈표 이미지 생성
   * @returns {string} 기본 사이즈표 이미지의 base64 데이터
   */
  generateFallbackSizeChartImage() {
    // 캔버스 직접 생성
    const canvas = document.createElement('canvas');
    canvas.width = 600;
    canvas.height = 300;
    const ctx = canvas.getContext('2d');

    // 배경
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 텍스트
    ctx.fillStyle = '#000000';
    ctx.font = '20px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('사이즈 정보', canvas.width / 2, 40);

    ctx.font = '14px Arial';
    ctx.fillText('단위: cm', canvas.width / 2, 70);
    ctx.fillText('사이즈표를 생성할 수 없습니다. 직접 입력하세요.', canvas.width / 2, 150);

    return canvas.toDataURL('image/png');
  },

  /**
   * 사이즈표 이미지 업로드
   * @param {Object} product - 상품 객체
   * @param {string} imageBase64 - Base64 형식의 이미지 데이터
   * @param {string} filename - 파일명 (선택적)
   * @returns {Promise<string>} 업로드된 이미지 URL
   */
  async uploadSizeChartImage(product, imageBase64, filename = null) {
    if (!imageBase64) {
      throw new Error('이미지 데이터가 없습니다.');
    }

    // filename이 제공되지 않으면 기본값 생성
    const chartFilename = filename || `size_chart_${product.registrationId}_${Date.now()}.png`;
    return await imageService.uploadImageToS3(imageBase64, chartFilename, 'sizeChart');
  },

  /**
   * 사이즈표 생성을 위한 프롬프트
   * @param {Object} product - 상품 객체
   * @returns {string} 생성된 프롬프트
   */
  promptForSizeChart(product) {
    // SEO된 상품명이 있다면 사용, 없다면 기본 상품명 사용
    const productName = product.seo?.productName || product.productName;
    const attributesInfo = product.attributes ? JSON.stringify(product.attributes) : '상품 상세 정보 참조';
    const categoryInfo = product.categoryPath || '카테고리 정보 없음';

    return `다음은 "${productName}" 상품의 정보입니다. 아래 상품 정보를 참고해서, 사이즈표 정보를 JSON 형식으로 생성해주세요.

    상품명: ${productName}
    카테고리: ${categoryInfo}
    상품 속성: ${attributesInfo}

    카테고리와 상품 정보를 기반으로 적절한 사이즈표를 생성해 주세요. 의류인 경우 총장, 어깨너비, 가슴단면, 소매길이 등의 정보가 필요하며, 신발인 경우 발길이, 발폭 등이 필요합니다. 가구인 경우 가로, 세로, 높이 등의 치수가 필요합니다.

    응답은 반드시 아래 JSON 형식으로 작성해주세요:
    {
        "sizeType": "일반 사이즈 또는 상품에 적합한 사이즈 유형 (의류/신발/가구 등)",
        "sizeUnits": "cm",
        "sizes": ["S", "M", "L", "XL"],
        "measurements": [
            {
                "name": "총장",
                "values": ["60", "65", "70", "75"]
            },
            {
                "name": "어깨너비",
                "values": ["40", "42", "44", "46"]
            },
            {
                "name": "가슴단면",
                "values": ["48", "50", "52", "54"]
            }
        ]
    }

    카테고리와 상품 특성에 맞게 사이즈 항목과 값을 조정해주세요. 사이즈 정보가 충분하지 않은 경우, 해당 상품 카테고리에 일반적인 사이즈 정보를 제공해주세요.`;
  },

  /**
 * 확장 프로그램에 사이즈표 데이터 요청
 * @param {Object} product - 상품 객체
 * @returns {Promise<Boolean>} - 요청 성공 여부
 */
  async requestSizeChartData(product) {
    try {
      // 상품 유효성 확인
      if (!product || !product.registrationId) {
        throw new Error('유효하지 않은 상품 정보입니다.');
      }

      // API 키 확인
      const apiKey = store.state.settings[0]?.geminiAPIKey;
      if (!apiKey) {
        console.warn('Gemini API Key가 설정되지 않았습니다.');
        return false;
      }

      // 로딩 상태 설정
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI로 사이즈표 정보 분석 중...'
      });

      // 이미지 URL 목록 수집
      const imageUrls = [];
      // 원본 상세 이미지 사용
      if (product.originDetailImages && product.originDetailImages.length > 0) {
        console.log('원본 상세 이미지를 사용하여 사이즈표 분석');
        const imagesToCheck = product.originDetailImages.slice(0, Math.min(product.originDetailImages.length, 30));
        imageUrls.push(...imagesToCheck.map(img => img.originalUrl || img.img || ''));
      }
      // 원본 이미지가 없으면 기존 이미지 사용
      else if (product.detailImages && product.detailImages.length > 0) {
        console.log('기존 상세 이미지를 사용하여 사이즈표 분석 (원본 이미지 없음)');
        const imagesToCheck = product.detailImages.slice(0, Math.min(product.detailImages.length, 30));
        imageUrls.push(...imagesToCheck.map(img => typeof img === 'string' ? img : (img.img || img.url || '')));
      }

      console.log('분석할 이미지 URL:', imageUrls);

      // 이미지가 없으면 기본 사이즈표 생성 및 즉시 완료 처리
      if (imageUrls.length === 0) {
        console.log('분석할 이미지가 없습니다. 기본 사이즈표를 사용합니다.');

        // 실용적인 기본 사이즈표 생성
        const practicalSizeChart = this.createPracticalSizeChart(product);
        console.log('기본 사이즈표 데이터:', JSON.stringify(practicalSizeChart, null, 2));

        // 완료 상태로 업데이트할 상품 객체
        const finalProduct = JSON.parse(JSON.stringify(product));
        finalProduct.sizeChart = practicalSizeChart;
        finalProduct.sizeChartStatus = 'completed';

        // 기본 사이즈표 이미지 생성 및 업로드
        const imageBase64 = await this.generateSizeChartImage(practicalSizeChart);
        const filename = `size_chart_${finalProduct.registrationId}_${Date.now()}.png`;
        const imageUrl = await this.uploadSizeChartImage(finalProduct, imageBase64, filename);
        finalProduct.sizeChartImage = imageUrl;

        // 옵션 상태 업데이트
        if (finalProduct.options && finalProduct.options.length > 0) {
          finalProduct.options.forEach(option => {
            option.sizeChartStatus = 'completed';

            // coupangDoc 업데이트 (필요한 경우)
            if (option.coupangDoc && option.coupangDoc.jsonDocument &&
              option.coupangDoc.jsonDocument.length > 0 &&
              option.coupangDoc.jsonDocument[0].imagePage &&
              option.coupangDoc.jsonDocument[0].imagePage.images) {
              option.coupangDoc.jsonDocument[0].imagePage.images.sizeChartImage = imageUrl;
            }
          });
        }

        // 상태 업데이트
        await store.dispatch('updateProductFeature', {
          feature: 'sizeChart',
          productData: finalProduct
        });

        // 로딩 상태 해제
        store.dispatch('setProcessing', { isProcessing: false });

        return true;
      }

      // // 이미지를 2~3개씩 그룹화
      // const groupSize = 2;
      // const imageGroups = [];
      // for (let i = 0; i < imageUrls.length; i += groupSize) {
      //   imageGroups.push(imageUrls.slice(i, i + groupSize));
      // }

      const imageGroups = [imageUrls];

      // 총 이미지 그룹 수 저장 및 상태 초기화
      this._totalImageGroups = imageGroups.length;
      this.resetProcessedState();

      // 확장 프로그램에 요청
      for (let i = 0; i < imageGroups.length; i++) {
        if (this._sizeChartProcessed) {
          console.log('사이즈표가 이미 처리되었습니다. 추가 이미지 검사를 중단합니다.');
          break;
        }
        const group = imageGroups[i];

        console.log(`분석 그룹 ${i + 1}/${imageGroups.length} 처리 중...`);
        console.log(`분석할 이미지 URL:`, group);

        // 로딩 상태 업데이트
        store.dispatch('setProcessing', {
          isProcessing: true,
          message: `사이즈표 분석 중... (${Math.min(100, Math.round(((i + 1) / imageGroups.length) * 100))}%)`
        });

        // 확장 프로그램에 요청 보내기
        const requestParams = {
          type: 'SIZE_CHART',
          productId: product.registrationId,
          images: group,
          productInfo: {
            productName: product.productName || product.productNameTrans || '',
            categoryPath: product.categoryPath || '',
            options: product.options || []
          }
        };

        // 요청 전송
        const requestId = await this.requestExtension(requestParams);
        console.log(`요청 ID: ${requestId} - 확장 프로그램에 분석 요청 전송 완료`);

        // 요청 간 지연 추가
        await new Promise(resolve => setTimeout(resolve, 3000));
      }

      // 로딩 상태 업데이트
      store.dispatch('setProcessing', {
        isProcessing: true,
        message: 'AI 응답 대기 중...'
      });

      return true;
    } catch (error) {
      console.error('사이즈표 데이터 요청 중 오류:', error);
      store.dispatch('setProcessing', { isProcessing: false });
      return false;
    }
  },

  /**
   * 확장 프로그램에 요청 전송
   * @param {Object} requestData - 요청 데이터
   * @returns {Promise<string>} 요청 ID
   */
  async requestExtension(requestData) {
    return new Promise((resolve) => {
      // API 키 확인
      const apiKey = store.state.settings[0]?.geminiAPIKey;
      if (!apiKey) {
        console.warn('Gemini API Key가 설정되지 않았습니다.');
        resolve(null);
        return;
      }

      const requestId = Date.now().toString();

      // 요청 메시지 생성
      const message = {
        greeting: "AiRocketSizeChartRequest",
        id: requestId,
        apiKey: apiKey,
        productId: requestData.productId,
        workType: requestData.type,
        requestArr: [{
          images: requestData.images || []
        }],
        productInfo: requestData.productInfo
      };

      // 메시지 전송
      window.postMessage(message, "*");
      resolve(requestId);
    });
  }
};

export default sizeChartService;